const productsPrices = {
  flowsMessages: {
    success: {
      PRODUCT_CREATE_PRICE: "Product price created successfully!",
      PRODUCT_REMOVE_PRICE: "Product price removed successfully!",
      PRODUCT_IMPORT_PRICE: "Prices import completed! {{totalChanges}} changes detected.",
      PRODUCT_PENDING_PRICE_ACTION: "Prices validation submited!",
      PRODUCT_SET_EXTRA_PRICE: "Product extra price created successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
      NO_PRICES_TO_EXPORT: "There is no prices to export with the current filter",
      PRICE_ALREADY_EXISTS: "Duplicated price, you have create a price that already exists"
    },
  },
  PricesList: {
    header: {
      title: "Product Prices",
      titleValidation: "Product Prices Validation",
      btnSubmit: "Submit",
      changesToSubmit: "You have pending prices to submit; they will be available for review only after submission.",
      changesToSubmitValidation: "You have pending prices validations to submit; they will be validated only after submission.",
      clearChanges: "Clear changes",
      tooltipImport: "Import",
      tooltipExport: "Export",
      tooltipHelper: "Help",
      pricesLink: "Prices",
      validationLink: "Validation",
      rowsInfo: "products"
    },
    columns: {
      description: "Product",
      unitDescriptor: "Unit",
      ownerName: "Owner",
      salePrice: "Price",
      retailPrice: "Retail Price",
      promotions: "Promotions",
      campaigns: "Campaigns",
      pendingRemove: "Price removal pending validation",
      removeDate: "Price to be removed at:"
    },
    actions: {
      acceptPrice: "Accept",
      rejectPrice: "Reject",
      proposePrice: "Propose change",
      history: "Remove",
      remove: "Remove",
      cancel: "Cancel",
    },
    modal: {
      title: "Add price variant",
    },
    modalComment: {
      titleAccept: "Accept price",
      titleRefuse: "Refuse price",
      cancelBtn: "Cancel",
      acceptBtn: "Accept",
      refuseBtn: "Refuse",
      commentLabel: "Comment",
    },
    modalPromotion: {
      title: "Add promotion"
    },
    promotionsInfo: {
      currentPrice: "Current Price",
      priceWithPromotions: "Price with promotions",
      priceWithDiscounts: "Price with discounts",
      bonus: "Offer {{offerQuantity}} if buying {{buyQuantity}}",
      dproms: "Promotional discounts"
    },
    filter: {
      owner: "Owner",
      contactPoint: "Contact Point",
      allContactPointsOption: "All contacts",
      productFamily: "Family",
      status: "Status",
    },
    modalImportHelper: {
      title: "Import Helper",
      createPrice_title: "Create new price",
      createPrice_description: "To create a new price the best approach is to copy an existing line in the csv file, for the same product",
      createPrice_product: "the product is required, needs to be copied from an existing product, the description needs to be exactly the same so the product can be identified",
      createPrice_promoterInternalCode: "Product promoter internal code",
      createPrice_supplierInternalCode: "Product supplier internal code",
      createPrice_owner: "the owner is required, if the user importing is promoter it will used to distinguish products from different supplier",
      createPrice_locations: "the price can have multiple locations, locations table below",
      createPrice_priceType: "the priceType should be one of the options on the price type table below",
      createPrice_salePrice: "the salePrice is required",
      createPrice_retailPrice: "the retailPrice is required",
      promotions_title: "Promotions",
      promotions_description: "Each price can only have 3 promotions",
      promotions_promotion1_name: "the name to identify the promotion",
      promotions_promotion1_percentage: "the promotion value in %, max is 100",
      priceTypes_title: "Price types options",
      locations_title: "Available locations"
    },
    exportHeader: {
      product: "Product",
      owner: "Owner",
      locations: "Locations",
      priceType: "Type",
      salePrice: "Sale Price",
      retailPrice: "Retail Price",
      promoterInternalCode: "Promoter Internal Code",
      supplierInternalCode: "Supplier Internal Code",
      effectiveDate: "Start date",
      expireDate: "End date",
      promotion1_name: "Promo 1",
      promotion1_percentage: "Promo 1 Value",
      promotion1_startDate: "Promo 1 Start date",
      promotion1_endDate: "Promo 1 End date",
      promotion2_name: "Promo 2",
      promotion2_percentage: "Promo 2 Value",
      promotion2_startDate: "Promo 2 Start date",
      promotion2_endDate: "Promo 2 End date",
      promotion3_name: "Promo 3",
      promotion3_percentage: "Promo 3 Value",
      promotion3_startDate: "Promo 3Start date",
      promotion3_endDate: "Promo 3End date",
    }
  },
  PriceVariantForm: {
    form: {
      priceType: "Type",
      priceTypeRequired: "Type is required",
      groupId: "Group",
      groupIdRequired: "Group is required",
      locations: "Locations",
      requiredLocations: "You need to select at least one location",
      startDate: "Start Date",
      startDateMin: "Start Date should be today or after today",
      startDateRequired: "Start date is required",
      endDate: "End Date",
      endDateMin: "End date should be after start date",
      newSalePrice: "Price",
      newRetailPrice: "Retail Price",
      requiredNewPrice: "The Price is required",
      addBtn: "Add",
    },
  },
  PromotionForm: {
    form: {
      name: "Designation",
      type: "Type",
      startDate: "Start Date",
      endDate: "End Date",
      percentage: "Promotion",
      buyQuantity: "Buy",
      offerQuantity: "Offer",
      nameRequired: "Name is required",
      typeRequired: "Type is required",
      startDateMin: "Start Date should be today or after today",
      startDateRequired: "Start date is required",
      endDateMin: "End date should be after start date",
      percentageRequired: "Promotion is required",
      maxPercentage: "Max promotion is 100%",
      buyQuantityRequired: "Buy quantity is required",
      offerQuantityRequired: "Offer quantity is required",
      btnDelete: "Delete",
      btnSubmit: "Add",
      btnUpdate: "Update",
    },
    promotionType: {
      PD: "Promotion",
      BU: "Offers"
    }
  },
  PriceComparerModal: {
    modal: {
      title: "Price Comparer",
      similarProductsTab: "Similiar products",
      subFamilyProductsTab: "Same family products",
      similarProductsEmpty: "No similiar products found",
      subFamilyProductsEmpty: "No same family products found"
    }
  },
  ExtraPriceList: {
    header: {
      title: "Extra Prices",
      btnSubmit: "Submit",
      changesToSubmit: "You have pending prices to submit; they will be effective only after submission.",
      clearChanges: "Clear changes",
      rowsInfo: "products"
    },
    columns: {
      description: "Product",
      unitDescriptor: "Unit",
      ownerName: "Owner",
      promotions: "Promotions",
      salePrice: "Sale Price",
      extraPrice: "Extra Price",
      extraPriceTypeClear: "Clear"
    }
  },
  PriceOscilation: {
    base: {
      compareBtn: "Price Comparer",
      removePending: "To be removed at: {{date}}",
    }
  },
  PriceRemoveModal: {
    modal: {
      title: "Remove price variant",
      removeDate: "Date to remove",
      confirmBtn: "Confirm Remove"
    }
  },
  PriceValidationCommentModal: {
    form: {
      comment: "Comment",
      commentRequired: "Comment is required",
      btnCancel: "Cancel",
      btnAccept: "Accept",
      btnRefuse: "Refuse",
    },
    modal: {
      title: "Accept price",
      titleRefuse: "Refuse price",
    }
  },
  RemovePricesConfirmModal: {
    modal: {
      title: "Are you sure you want to remove the following prices?",
      confirmBtn: "Confirm remove"
    }
  },
  ExtraPriceHistory: {
    modal: {
      title: "Price Extra History",
      priceType: "Type",
      removed: "Price removed on",
      toBeRemoved: "Price to be removed on"
    },
    columns:{
      extraPrice: "Extra Price",
      date: "Date",
      updatedBy: "Updated by"
    }
  },
  ExtraPriceRemoveModal: {
    modal: {
      title: "Remove price variant",
      removeDate: "Date to remove",
      confirmBtn: "Confirm Remove"
    }
  },
  PriceHistoryModal: {
    modal: {
      title: "Price History"
    },
    columns: {
      effectiveDate: "Effective Date",
      removeDate: "Remove Date",
      status: "Status",
      active: "Active",
      active_true: "True",
      active_false: "False",
      priceType: "Price Type",
      subType: "SubType",
      salePrice: "Sale Price",
      retailPrice: "Retail Price",
      promotions: "Promotions",
      actionUser: "User",
      actionDate: "Date",

    }

  }
};

export default productsPrices;
