const resources = {
  flowsMessages: {
    success: {
      RESOURCE_CREATE: "Resource created successfully!",
      RESOURCE_UPDATE: "Resource updated successfully!",
      RESOURCE_DELETE: "Resource deleted successfully!",
      RESOURCE_DISABLE: "Resource disabled successfully!",
      RESOURCE_ENABLE: "Resource enabled successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
      RESOURCE_IS_PARENT_ERROR: "Unable to delete resource, this resource parent of another resource"
    },
  },
  ResourceList: {
    header: {
      title: "Resources",
    },
    filter: {
      type: "Types",
      status: "Status",
      allStatusOption: "Clear",
      status_true: "Active",
      status_false: "Inactive",
      placeId: "Places"
    },
    columns: {
      title: "Title",
      type: "Type",
      placeName: "Place",
      parentName: "Parent",
      state: "State",
      status: "Status",
      statusActive: "Active",
      statusInactive: "Inactive",
    },
    actions: {
      edit: "Edit",
      copy: "Copy",
      enable: "Enable",
      disable: "Disable",
      remove: "Remove",
    }
  },
  ResourceForm: {
    header: {
      title: "Create new Resource",
      titleEdit: "Update Resource:"
    },
    form: {
      title: "Title",
      titleRequired: "Title is required",
      type: "Type",
      typeRequired: "Type is required",
      placeId: "Assigned place",
      parentId: "Parent Resource",
      state: "State",
      btnSubmit: "Submit",
      height: "height",
      width: "width",
      depth: "depth",
      weight: "weight",
      capacityHeight: "capacityHeight",
      capacityWidth: "capacityWidth",
      capacityDepth: "capacityDepth",
      capacityWeight: "capacityWeight",
      lifecycle: "lifecycle",
      temperature: "temperature",
      placeClear: "Clear",
      parentClear: "Clear",
      mesh: "Mesh (μm)",
      slots: "Slots",
      stateRequired: "State is required"
    }
  }
};

export default resources;