import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import { styles } from "./ProductImagesField.styles";
import { Field } from "react-final-form";
import MetaError from "../../../../common/forms/MetaError";
import { FileUploadInput } from "../../../../common/inputs";
import { usePromoter } from "../../../../common/hooks";

function ProductImagesField({ classes, id, label, disabled, required, multiple, placeholder, image }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const onChangeHandler = (file, input) => {
    const { base64, name, mediaType } = file;
    let nValue = [
      ...(input.value || []),
      {
        base64,
        name,
        mediaType,
      },
    ];
    input.onChange && input.onChange(nValue);
  };

  const deleteHandler = (index, input) => {
    let nValue = [...(input.value || [])];
    nValue.splice(index, 1);
    input.onChange && input.onChange(nValue);
  };

  const makeDefaultHandler = (index, input) => {
    if (index === 0) {
      return;
    }
    let nValue = [...(input.value || [])];
    let def = nValue.splice(index, 1);
    input.onChange && input.onChange([...def, ...nValue]);
  };

  return (
    <div className={classes.root}>
      <Field name={id}>
        {({ input, meta }) => {
          return (
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Typography
                  component="span"
                  variant="caption"
                  htmlFor={id}
                  color="textPrimary"
                  className={disabled ? classes.disabledLabel : ""}
                >
                  {label}
                  {required && <span> *</span>}
                </Typography>
              </Grid>
              <Grid item>
                <div className={classes.imagesWrapper}>
                  <Grid container spacing={1} alignItems="center">
                    {(input.value || []).map((item, index) => (
                      <Grid item xs={2} key={`${id}_${index}`}>
                        <div className={classes.image}>
                          <div
                            className={classes.img}
                            style={{ backgroundImage: `url('${item.thumbnail || item.base64}')` }}
                          ></div>
                          {/* <img src={item.thumbnail || item.base64} alt={`${id}_${index}`} /> */}
                          <Tooltip title={t("products.ProductCreate.imagesField.delete")}>
                            <IconButton
                              size="small"
                              className={classes.delete}
                              onClick={() => deleteHandler(index, input)}
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              index === 0
                                ? t("products.ProductCreate.imagesField.default")
                                : t("products.ProductCreate.imagesField.setDefault")
                            }
                          >
                            <IconButton
                              size="small"
                              className={classes.default}
                              onClick={() => makeDefaultHandler(index, input)}
                            >
                              {index === 0 ? <StarIcon fontSize="inherit" /> : <StarBorderIcon fontSize="inherit" />}
                            </IconButton>
                          </Tooltip>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
              <Grid item>
                <FileUploadInput
                  id="uploadImage"
                  placeholder={placeholder}
                  required={required}
                  disabled={disabled}
                  multiple={multiple}
                  file={input.value}
                  onChange={(v) => onChangeHandler(v, input)}
                  variant="outlined"
                  image={image}
                  maxFileSize={promoter?.getConfig("document")?.maxFileSize}
                  accept={"image/*"}
                />
              </Grid>
              <Grid item>
                <div className={classes.error}>
                  <MetaError meta={meta} />
                </div>
              </Grid>
            </Grid>
          );
        }}
      </Field>
    </div>
  );
}

export default withStyles(ProductImagesField, styles);
