import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Chip, Grid, Typography} from "@mui/material";

import {styles} from "./ExtraPriceHistoryModal.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import moment from "moment/moment";
import {dateFormat} from "../../../../common/utils";
import {usePromoter, useUser} from "../../../../common/hooks";
import ExtraPriceHistoryDataGrid from "./ExtraPriceHistoryDataGrid/ExtraPriceHistoryDataGrid";
import {useSelector} from "react-redux";
import {selectEntityGroups, selectLocations} from "../../productsPricesSlice";
import LabelValue from "../../../../common/displays/LabelValue/LabelValue";

function ExtraPriceHistoryModal({ classes, open, setOpen, product, row }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.entity?.type === "P" && product?.promoterDescription);
  const entityGroups = useSelector(selectEntityGroups);
  const locations = useSelector(selectLocations);

  let extraPrice = row?.extraPrice;
  let group = (entityGroups || []).find(item => row?.groupEntity === item.groupId || row?.groupId === item.groupId);
  let locationsObjs = (locations || []).filter(item => (row?.locations || []).includes(item.id));
  return (
    <CustomModal open={open} setOpen={setOpen} size="extralarge" height={500} title={t("productsPrices.ExtraPriceHistory.modal.title")}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <ProductSnip
              image={product?.thumbnail}
              description={showPromoterDescription ? product?.promoterDescription : product?.description}
              gtin={product?.gtin}
              promoterInternalCode={product?.promoterInternalCode}
              ownerName={product?.ownerName}
          />
        </Grid>
        <Grid item xs={6}>
          <LabelValue
              label={t("productsPrices.ExtraPriceHistory.modal.priceType")}
              value={
                <Typography variant="body1" align="left" component="span">
                  {row?.priceType ? t("common.Enums.priceType." + row?.priceType)  : ""}
                </Typography>
              }
          />
        </Grid>
        <Grid item xs={6}>
          {uiPriceRemoved(t, extraPrice?.removeDate)}
        </Grid>
        {row?.priceType === "G" &&
            <Grid item >
             <Chip label={group?.groupName || row?.groupName} size="small" />
          </Grid>
        }
        {(row?.priceType === "D" || row?.priceType === "W") &&
            <Grid item >
              <Grid container direction="row" spacing={1} flexWrap="wrap">
              {locationsObjs.map(loc => <Grid item key={loc.id} ><Chip label={loc.name} size="small" /></Grid>)}
              </Grid>
            </Grid>
        }

        <Grid item xs={12}>
            <ExtraPriceHistoryDataGrid data={extraPrice?.prevPrice || []}  />
        </Grid>
      </Grid>
    </CustomModal>
  );
}

export default withStyles(ExtraPriceHistoryModal, styles);

const uiPriceRemoved = (t, removeDate) => {
  if (!removeDate) {
    return "";
  }
  if (moment(removeDate).isSameOrBefore(moment())) {
    return <LabelValue
        label={t("productsPrices.ExtraPriceHistory.modal.removed")}
        value={
          <Typography variant="body1" align="left" component="span">
            {dateFormat(removeDate)}
          </Typography>
        }
    />
  }
  return <LabelValue
      label={t("productsPrices.ExtraPriceHistory.modal.toBeRemoved")}
      value={
        <Typography variant="body1" align="left" component="span">
          {dateFormat(removeDate)}
        </Typography>
      }
  />
}

