export const styles = (theme) => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  header: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: "1px solid #ddd",
  },
  title: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  content: {
    padding: theme.spacing(2),
    height: "100%"
  },
  small: {
    width: 300,
    minHeight: 300,
    "& .CustomModalHeader span": {
      width: 240,
    },
    [theme.breakpoints.down('sm')]: {
      width: "98vw"
    }
  },
  medium: {
    width: 440,
    [theme.breakpoints.down('sm')]: {
      width: "98vw"
    }
  },
  large: {
    width: 560,
    [theme.breakpoints.down('sm')]: {
      width: "98vw"
    }
  },
  extralarge: {
    width: 800,
    [theme.breakpoints.down('sm')]: {
      width: "98vw"
    }
  },
  extraextralarge: {
    width: 1000,
    [theme.breakpoints.down('sm')]: {
      width: "98vw"
    }
  }
});
