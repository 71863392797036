export const styles = (theme) => ({
  root: {
    border: "1px solid #DDD",
    margin: "1px 0",
    padding: "2px"
  },
  empty: {
    width: "100%",
    textAlign: "center"
  },
  tank: {
    marginBottom: 2,
    "&:last-of-type": {
      marginBottom: 0
    }
  },
  tankContent: {
    border: "1px solid #A9A9A9",
    display: "flex",
    padding: "0 4px",
  },
  cylinder: {
    margin: 2,
    width: 20,
    height: 20,
    borderRadius: 10,
    border: "1px solid #A9A9A9",
    "&:first-of-type": {
      marginLeft: 0
    }
  },
  cylinderUsed: {
    backgroundColor: "#A9A9A9"
  },
  cylinderHighLighted: {
    backgroundColor: theme.palette.primary.main
  }
});