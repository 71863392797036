import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const productListHistoryCall = async (
  { clearProductHistoryList, setProductHistoryListFilter, appendToProductHistoryList, setProductHistoryListTotalRows },
  dispatch,
  { filter, clear }
) => {
  let rh = requestHelper(dispatch, "LIST_HISTORY_PRODUCT");
  if (clear) {
    dispatch(clearProductHistoryList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setProductHistoryListFilter(filter));
  try {
    let result = await call({
      type: "PRODUCT",
      subType: "LIST_HISTORY",
      request: {
        ...filter,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToProductHistoryList(result.items || []));
    dispatch(setProductHistoryListTotalRows(result.rows));

  } catch (ex) {
    rh.error("products", ex);
  }
  rh.close();
};

export default productListHistoryCall;