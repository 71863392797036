export const styles = (theme) => ({
  root: {
  },
  taskRow: {
    padding: theme.spacing(0.5) + " " + theme.spacing(0),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.primary.back
    }
  },
  completed: {
    backgroundColor: theme.palette.success.back
  }
});