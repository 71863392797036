import * as Yup from "yup";
import { NumberField, SelectField, TextField } from "../../../common/forms";
import {
  getParentOptions,
  getPlaceOptions,
  getResourceTypeOptions,
  getStateOptions,
  getTemperatureOptions
} from "./ResourceForm.options";
import { MeasureField } from "../../../common/components/formFields";

export function formSchema({ t }) {
  return Yup.object().shape({
    title: Yup.string().required(t("resources.ResourceForm.form.titleRequired")),
    type: Yup.string().required(t("resources.ResourceForm.form.typeRequired")),
    state: Yup.string().required(t("resources.ResourceForm.form.stateRequired")),
  });
}

export function formFields({ t, values, places, resources, promoter, form }) {
  const placeChangeHandler = (item) => {
    if (item) {
      form.change('parentId', undefined);
    }
  }

  const parentChangeHandler = (item) => {
    if (item) {
      form.change('placeId', undefined);
    }
  }

  return [
    {
      size: 6,
      field: <TextField
        id="title"
        required
        label={t("resources.ResourceForm.form.title")}
      />,
    },
    {
      size: 3,
      field: <SelectField
        id="type"
        required
        options={getResourceTypeOptions(t, promoter)}
        label={t("resources.ResourceForm.form.type")}
      />,
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: <SelectField
        id="placeId"
        options={getPlaceOptions(places, values?.type)}
        label={t("resources.ResourceForm.form.placeId")}
        onChange={placeChangeHandler}
        allowEmpty
        clearLabel={t("resources.ResourceForm.form.placeClear")}
      />,
    },
    {
      size: 4,
      field: <SelectField
        id="parentId"
        options={getParentOptions(resources, values?.type)}
        label={t("resources.ResourceForm.form.parentId")}
        onChange={parentChangeHandler}
        allowEmpty
        clearLabel={t("resources.ResourceForm.form.parentClear")}
      />,
      hidden: values?.type !== "C"
    },
    {
      size: 4,
      field: <SelectField
        id="state"
        options={getStateOptions(t)}
        required
        label={t("resources.ResourceForm.form.state")}
      />,
    },
    {
      size: 12,
    },
    {
      size: 3,
      field: <MeasureField
        id="height"
        label={t("resources.ResourceForm.form.height")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 3,
      field: <MeasureField
        id="width"
        label={t("resources.ResourceForm.form.width")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 3,
      field: <MeasureField
        id="depth"
        label={t("resources.ResourceForm.form.depth")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 3,
      field: <MeasureField
        id="weight"
        label={t("resources.ResourceForm.form.weight")}
        fixedCategory="MASS"
      />,
    },
    {
      size: 12,
    },
    {
      size: 3,
      field: <MeasureField
        id="attributes.capacityHeight"
        label={t("resources.ResourceForm.form.capacityHeight")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 3,
      field: <MeasureField
        id="attributes.capacityWidth"
        label={t("resources.ResourceForm.form.capacityWidth")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 3,
      field: <MeasureField
        id="attributes.capacityDepth"
        label={t("resources.ResourceForm.form.capacityDepth")}
        fixedCategory="COMP"
      />,
    },
    {
      size: 3,
      field: <MeasureField
        id="attributes.capacityWeight"
        label={t("resources.ResourceForm.form.capacityWeight")}
        fixedCategory="MASS"
      />,
    },
    {
      size: 3,
      field: <NumberField
        id="attributes.lifecycle"
        decimalScale={0}
        label={t("resources.ResourceForm.form.lifecycle")}
      />,
    },
    {
      size: 3,
      field: <SelectField
        id="attributes.temperature"
        label={t("resources.ResourceForm.form.temperature")}
        options={getTemperatureOptions(t, promoter)}
      />,
    },
    {
      size: 3,
      field: <NumberField
        id="attributes.mesh"
        decimalScale={0}
        label={t("resources.ResourceForm.form.mesh")}
      />,
      hidden: values?.type !== "C"
    },
    {
      size: 3,
      field: <NumberField
        id="attributes.slots"
        decimalScale={0}
        label={t("resources.ResourceForm.form.slots")}
      />,
      hidden: values?.type !== "T"
    }
  ];
}
