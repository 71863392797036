import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, IconButton, Stack } from "@mui/material";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { styles } from "./WeekSwitcher.styles";

function WeekSwitcher({ classes, startDate, onChange }) {
  const previousWeek = () => {
    onChange && onChange(startDate.clone().subtract(1, 'week'));
  };

  const nextWeek = () => {
    onChange && onChange(startDate.clone().add(1, 'week'));
  };

  return (
    <div className={classes.root}>
      <Stack direction="row" spacing={1} alignItems="center">
        <IconButton onClick={previousWeek}>
          <NavigateBeforeIcon />
        </IconButton>
        <Typography color="textSecondary">{startDate.clone().startOf('isoWeek').format('DD MMM')} - {startDate.clone().endOf('isoWeek').format('DD MMM')}</Typography>
        <IconButton onClick={nextWeek}>
          <NavigateNextIcon />
        </IconButton>
      </Stack>
    </div>
  );
}

export default withStyles(WeekSwitcher, styles);