import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Grid, Stack} from "@mui/material";

import {styles} from "./ProductHistoryModal.styles";
import CustomModal from "../../../../../common/displays/CustomModal/CustomModal";
import ProductSnip from "../../../../../common/displays/ProductSnip/ProductSnip";
import ProductHistoryDataGrid from "../ProductHistoryDataGrid/ProductHistoryDataGrid";
import {usePromoter, useUser} from "../../../../../common/hooks";
import {useSelector} from "react-redux";
import {
    selectProductHistoryList,
    selectProductHistoryListFilter,
    selectProductHistoryListTotalRows
} from "../../../productsSlice";

function ProductHistoryModal({ classes, open, setOpen, product, onFilterChange }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const data = useSelector(selectProductHistoryList);
  const totalRows = useSelector(selectProductHistoryListTotalRows);
  const enabledProductFields = promoter?.getConfig("product", "listProductFields") || [];
  const showPromoterDescription = Boolean(enabledProductFields.includes("promoterDescription") && user?.entity?.type === "P" && product?.promoterDescription);
  const filterHistory = useSelector(selectProductHistoryListFilter);

  return (
    <CustomModal open={open} setOpen={setOpen} size="extraextralarge" height={500} title={t("products.ProductDetails.history.modalTitle")}>
        <Stack spacing={1}>
            <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                <Grid item>
                    <ProductSnip
                        image={product?.media[0]?.thumbnail}
                        description={showPromoterDescription ? product?.baseInfo?.promoterDescription : product?.baseInfo?.description}
                        gtin={product?.baseInfo?.gtin}
                        promoterInternalCode={product?.baseInfo?.promoterInternalCode}
                        ownerName={product?.baseInfo?.ownerName}
                    />
                </Grid>
            </Grid>
            <Stack>
                <ProductHistoryDataGrid data={data} totalRows={totalRows} filter={filterHistory} onFilterChange={onFilterChange} productId={product?.id}/>
            </Stack>
        </Stack>
    </CustomModal>
  );
}

export default withStyles(ProductHistoryModal, styles);
