import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack, Tooltip } from "@mui/material";

import { styles } from "./TaskItem.styles";
import { multiLanguagePropToString } from "../../../../../../common/utils";
import Assignee from "../../../../../../common/displays/Assignee/Assignee";
import CheckIcon from '@mui/icons-material/Check';

function TaskItem({ classes, task, onClick }) {
  const { t, i18n } = useTranslation();

  return (
    <div className={classes.root} onClick={() => onClick && onClick(task)}>
      <Stack direction="row" spacing={0.5} alignItems="center" justifyContent="space-between">
        {
          task.status === "CMP" &&
          <Tooltip title={t("common.Enums.taskStatus." + task.status)}>
            <CheckIcon color="success" style={{ display: "block" }} />
          </Tooltip>
        }
        <Typography color={task.status === "CMP" ? "success" : undefined} style={{ margin: 0 }}>{getTaskName(i18n, task)}</Typography>
        <Assignee sectors={task.sectors} groups={task.groups} users={task.users} />
      </Stack>
    </div>
  );
}

export default withStyles(TaskItem, styles);

const getTaskName = (i18n, task) => {
  let name = multiLanguagePropToString(task.name, i18n.language);
  if (task.stepName) {
    name = task.stepName + " - " + name;
  }
  return name;
}