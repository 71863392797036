import { Chip, Stack, Tooltip, Typography } from "@mui/material";
import { multiLanguagePropToString } from "../../../../common/utils";
import Assignee from "../../../../common/displays/Assignee/Assignee";
import TaskIcon from "./TaskIcon/TaskIcon";
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import DateTime from "../../../../common/displays/DateTime/DateTime";
import moment from "moment";

export function listColumns(t, i18n) {
  let columns = [];

  columns.push({
    field: "name",
    headerName: t("tasks.TasksList.columns.title"),
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return <Stack direction="row" spacing={1} alignItems="center">
        <TaskIcon task={v} />
        <Stack direction="row" spacing={0.5} alignItems="center">
          {v.stepName && <div><Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{v.stepName}</Typography></div>}
          {v.stepName && <div><Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{"-"}</Typography></div>}
          <div><Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{multiLanguagePropToString(v.name, i18n.language)}</Typography></div>
        </Stack>
        {v.placeId && <Chip label={v.placeName} size="small" />}
        {v.batchId && <Chip label={v.batchNumber} size="small" color="primary" />}
      </Stack>
    }
  });

  columns.push({
    field: "assignees",
    headerName: t("tasks.TasksList.columns.assignees"),
    align: "left",
    headerAlign: "left",
    disableSort: true,
    customValue: (v) => {
      if (v.users?.length > 0 || v.groups?.length > 0 || v.sectors?.length > 0) {
        return <Assignee sectors={v.sectors} groups={v.groups} users={v.users} />
      }
      return <Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{t("roles.enum.associationType.NONE")}</Typography>
    }
  });

  columns.push({
    field: "dueDate",
    headerName: t("tasks.TasksList.columns.duedate"),
    align: "center",
    headerAlign: "center",
    customValue: (v) => {

      return (v.dueDate ? <DateTime date={v.dueDate} textColor={v.status !== "CMP" && moment(v.dueDate).isBefore(moment()) ? "error" : ""} inline /> : "-");
    }
  });

  columns.push({
    field: "status",
    headerName: t("tasks.TasksList.columns.status"),
    align: "center",
    headerAlign: "center",
    customValue: (v) => {
      return v.status === "CMP" ?
        <Tooltip title={t("common.Enums.taskStatus." + v.status)}>
          <CheckIcon color="success" style={{ display: "block" }} />
        </Tooltip> :
        <Tooltip title={t("common.Enums.taskStatus." + v.status)}>
          <HourglassEmptyIcon style={{ display: "block" }} />
        </Tooltip>
    }
  });

  return columns;
}
