import * as Yup from "yup";
import { isNifValid, isGtinValid, isValidPhoneNumber, isValidEmail } from "../utils";

Yup.addMethod(Yup.string, "nif", function (message) {
  return this.test("nif-test", message, function (value) {
    const { path, createError } = this;
    let isValid = isNifValid(value);
    return isValid || createError({ path, message });
  });
});

Yup.addMethod(Yup.string, "gtin", function (message) {
  return this.test("gtin-test", message, function (value) {
    const { path, createError } = this;
    let isValid = !value || isGtinValid(value);
    return isValid || createError({ path, message });
  });
});

Yup.addMethod(Yup.array, "requiredUserWithFullAccess", function (message = "requiredUserWithFullAccess") {
  return this.test("requiredUserWithFullAccess", message, function (value) {
    const { path, createError } = this;
    let isValid = Boolean(value && value.length > 0 && value.find((item) => item?.roleType === "F" && !item?.businessUnitIndexs));
    return isValid || createError({ path: path + "[0]._base", message });
  });
});

Yup.addMethod(Yup.array, "requiredArrayWithCheck", function (message) {
  return this.test("requiredArrayWithCheck-test", message, function (value) {
    const { path, createError } = this;
    let isValid = Boolean(value && value.length > 0 && value.find((item) => item.checked));
    return isValid || createError({ path, message });
  });
});

Yup.addMethod(Yup.string, "phone", function (message) {
  return this.test("phone-test", message, function (value) {
    const { path, createError } = this;
    let isValid = isValidPhoneNumber(value);
    return isValid || createError({ path, message });
  });
});


Yup.addMethod(Yup.string, "emailValidator", function (message) {
  return this.test("email-test", message, function (value) {
    const { path, createError } = this;
    let isValid = isValidEmail(value);
    return isValid || createError({ path, message });
  });
});

Yup.addMethod(Yup.array, "requiredDocuments", function (message, mandatoryDocs) {
  return this.test("requiredDocuments-test", message, function (value) {
    let isValid = true;
    mandatoryDocs.forEach(mf => {
      if (!(value || []).some(item => item.type === mf)) {
        isValid = false;
      }
    })
    const { path, createError } = this;
    return isValid || createError({ path: path + "[0]._base", message });
  });
});

Yup.addMethod(Yup.object, "oneLanguageRequired", function (message) {
  return this.test("oneLanguageRequired-test", message, function (value) {
    const { path, createError } = this;
    let isValid = value && Object.values(value).some(item => item.trim() !== "");
    return isValid || createError({ path, message });
  });
});

Yup.addMethod(Yup.array, "productHierarchyRequired", function (message) {
  return this.test("productHierarchyRequired-test", message, function (value, context) {
    const { path, createError } = this;
    const { transportUnit, consumerUnit, unitDescriptor } = context?.parent?.units || {};
    const unitsNeeded = [...new Set([transportUnit, consumerUnit, unitDescriptor].filter(item => item))];
    let isValid = value?.length > 0 && unitsNeeded.every(str =>
      value.some(obj => obj.unitDescriptor === str)
    );
    // console.log('productHierarchyRequired - %o', { unitsNeeded, value, isValid })
    return isValid || createError({ path: path + "[0]._base", message });
  });
});

// NOTE WHEN VALIDATING ARRAYS USE createError({ path: path + "[0]._base", message }) or the error will not be triggered as intended