import { createSlice } from "@reduxjs/toolkit";
import { batchActivityListDefaultFilter, batchListDefaultFilter, batchOrdersListDefaultFilter } from "./_common/batchesFilters";
import { appendItemsToList } from "../../common/utils";
import batchListCall from "./_sliceCalls/batchListCall";
import batchListInternalCall from "./_sliceCalls/batchListInternalCall";
import batchCreateCall from "./_sliceCalls/batchCreateCall";
import batchCreateInternalOrderCall from "./_sliceCalls/batchCreateInternalOrderCall";
import batchOutputProductsCall from "./_sliceCalls/batchOutputProductsCall";
import batchListOrdersCall from "./_sliceCalls/batchListOrdersCall";
import batchGetCall from "./_sliceCalls/batchGetCall";
import batchInternalChangeStatusCall from "./_sliceCalls/batchInternalChangeStatusCall";
import batchInternalTransferCall from "./_sliceCalls/batchInternalTransferCall";
import batchListPlacesCall from "./_sliceCalls/batchListPlacesCall";
import batchListOrdersOpen_FullCall from "./_sliceCalls/batchListOrdersOpen_FullCall";
import batchUpdateDataCall from "./_sliceCalls/batchUpdateDataCall";
import batchActivityCall from "./_sliceCalls/batchActivityCall";
import batchMetricsCall from "./_sliceCalls/batchMetricsCall";
import batchListTasksCall from "./_sliceCalls/batchListTasksCall";
import taskGetCall from "./_sliceCalls/taskGetCall";

export const batchesSlice = createSlice({
  name: "batches",
  initialState: {
    batchList: [],
    batchListTotalRows: 0,
    batchListFilter: batchListDefaultFilter,
    batchDetails: null,
    batchOrdersList: [],
    batchOrdersListTotalRows: 0,
    batchOrdersListFilter: batchOrdersListDefaultFilter,
    batchOrderDetails: null,
    internalBatchList: [],
    outputProductsList: [],
    placesList: [],
    openOrders: [],
    batchActivityList: [],
    batchActivityListTotalRows: 0,
    batchActivityListFilter: batchActivityListDefaultFilter,
    batchMetrics: null,
    batchTasksList: [],
    taskDetails: null,
  },
  reducers: {
    clearBatchList: (state) => {
      state.batchList = [];
    },

    appendToBatchList: (state, action) => {
      appendItemsToList(state.batchList, action.payload, true, "batchId");
    },

    setBatchListTotalRows: (state, action) => {
      state.batchListTotalRows = action.payload;
    },

    setBatchListFilter: (state, action) => {
      state.batchListFilter = action.payload;
    },

    clearBatchOrdersList: (state) => {
      state.batchOrdersList = [];
    },

    appendToBatchOrdersList: (state, action) => {
      appendItemsToList(state.batchOrdersList, action.payload, true, "orderId");
    },

    setBatchOrdersListTotalRows: (state, action) => {
      state.batchOrdersListTotalRows = action.payload;
    },

    setBatchOrdersListFilter: (state, action) => {
      state.batchOrdersListFilter = action.payload;
    },

    setInternalBatchList: (state, action) => {
      state.internalBatchList = action.payload;
    },

    setOutputProductsList: (state, action) => {
      state.outputProductsList = action.payload;
    },

    setBatchDetails: (state, action) => {
      state.batchDetails = action.payload;
    },

    setPlacesList: (state, action) => {
      state.placesList = action.payload;
    },

    setOpenOrders: (state, action) => {
      state.openOrders = action.payload;
    },

    setBatchOrderDetails: (state, action) => {
      state.batchOrderDetails = action.payload;
    },

    clearBatchActivityList: (state) => {
      state.batchActivityList = [];
    },

    appendToBatchActivityList: (state, action) => {
      appendItemsToList(state.batchActivityList, action.payload, true, "id");
    },

    setBatchActivityListTotalRows: (state, action) => {
      state.batchActivityListTotalRows = action.payload;
    },

    setBatchActivityListFilter: (state, action) => {
      state.batchActivityListFilter = action.payload;
    },

    setBatchMetrics: (state, action) => {
      state.batchMetrics = action.payload;
    },

    setBatchTasksList: (state, action) => {
      state.batchTasksList = action.payload;
    },

    setTaskDetails: (state, action) => {
      state.taskDetails = action.payload;
    },
  },
});

export default batchesSlice.reducer;

export const {
  setBatchDetails,
  setBatchOrderDetails,
} = batchesSlice.actions;

// SELECTS
export const selectBatchList = (state) => state.batches.batchList;
export const selectBatchListTotalRows = (state) => state.batches.batchListTotalRows;
export const selectBatchListFilter = (state) => state.batches.batchListFilter;
export const selectBatchOrdersList = (state) => state.batches.batchOrdersList;
export const selectBatchOrdersListTotalRows = (state) => state.batches.batchOrdersListTotalRows;
export const selectBatchOrdersListFilter = (state) => state.batches.batchOrdersListFilter;
export const selectInternalBatchList = (state) => state.batches.internalBatchList;
export const selectOutputProductsList = (state) => state.batches.outputProductsList;
export const selectBatchDetails = (state) => state.batches.batchDetails;
export const selectBatchOrderDetails = (state) => state.batches.batchOrderDetails;
export const selectPlacesList = (state) => state.batches.placesList;
export const selectOpenOrders = (state) => state.batches.openOrders;
export const selectBatchActivityList = (state) => state.batches.batchActivityList;
export const selectBatchActivityListTotalRows = (state) => state.batches.batchActivityListTotalRows;
export const selectBatchActivityListFilter = (state) => state.batches.batchActivityListFilter;
export const selectBatchMetrics = (state) => state.batches.batchMetrics;
export const selectBatchTasksList = (state) => state.batches.batchTasksList;
export const selectTaskDetails = (state) => state.batches.taskDetails;
export const selectResources = (state) => state.batches.resources;

// CALLS
export const batchList = (businessUnitId, filter, clear = false) => (dispatch, getState) => {
  batchListCall(batchesSlice.actions, dispatch, getState(), { businessUnitId, filter, clear });
};

export const batchListOrders = (businessUnitId, filter, clear = false) => (dispatch, getState) => {
  batchListOrdersCall(batchesSlice.actions, dispatch, getState(), { businessUnitId, filter, clear });
};

export const batchListOrdersOpen_Full = ({ businessUnitId }) => (dispatch) => {
  batchListOrdersOpen_FullCall(batchesSlice.actions, dispatch, { businessUnitId });
};

export const batchListInternal = ({ businessUnitId }) => (dispatch) => {
  batchListInternalCall(batchesSlice.actions, dispatch, { businessUnitId });
};

export const batchCreate = ({
  batchNumber,
  productId,
  businessUnitId,
  type,
  startDate,
  description,
  batchOrders,
  taskInitializationOffset,
}) => (dispatch) => {
  batchCreateCall(dispatch, {
    batchNumber,
    productId,
    businessUnitId,
    type,
    startDate,
    description,
    batchOrders,
    taskInitializationOffset,
  });
};

export const batchCreateInternalOrder = ({
  productId,
  date,
  quantity,
  businessUnitId
}) => (dispatch, getState) => {
  batchCreateInternalOrderCall({ batchListOrdersOpen_Full }, dispatch, getState(), {
    productId,
    date,
    quantity,
    businessUnitId
  });
};

export const batchOutputProducts = ({ businessUnitId }) => (dispatch, getState) => {
  batchOutputProductsCall(batchesSlice.actions, dispatch, getState(), { businessUnitId });
};

export const batchGet = ({ batchId }) => (dispatch, getState) => {
  batchGetCall(batchesSlice.actions, dispatch, getState(), { batchId });
};

export const batchInternalChangeStatus = ({ batchId, newStatus, locations }) => (dispatch) => {
  batchInternalChangeStatusCall(dispatch, { batchId, newStatus, locations });
};

export const batchInternalTransfer = ({ batchId, destination, locations }) => (dispatch) => {
  batchInternalTransferCall(dispatch, { batchId, destination, locations });
};

export const batchListPlaces = ({ businessUnitId }) => (dispatch) => {
  batchListPlacesCall(batchesSlice.actions, dispatch, { businessUnitId });
};

export const batchUpdateData = ({ param1, param2 }) => (dispatch, getState) => {
  batchUpdateDataCall(batchesSlice.actions, dispatch, getState(), { param1, param2 });
};

export const batchActivity = (batchId, filter, clear = false) => (dispatch) => {
  batchActivityCall(batchesSlice.actions, dispatch, { batchId, filter, clear });
};

export const batchMetrics = ({ batchId, startDate, endDate }) => (dispatch, getState) => {
  batchMetricsCall(batchesSlice.actions, dispatch, getState(), { batchId, startDate, endDate });
};

export const batchListTasks = ({ batchId, stepId }) => (dispatch) => {
  batchListTasksCall(batchesSlice.actions, dispatch, { batchId, stepId });
};

export const taskGet = ({ taskId }) => (dispatch) => {
  taskGetCall(batchesSlice.actions, dispatch, { taskId });
};
