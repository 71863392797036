const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
  },
  drawer: {
    "& .MuiDrawer-paperAnchorDockedLeft": {
      borderRight: "none",
    },
  },
  drawerContainer: {
    width: theme.custom.drawerWidth,
    overflow: "hidden",
    position: "relative",
    height: "100%",
    transition: "width .30s ease-in-out",
    [theme.breakpoints.down('md')]: {
      width: "80vw",
    },
    "& .userMenu": {
      display: "none",
      width: theme.custom.drawerWidthOpen,
      [theme.breakpoints.down('md')]: {
        display: "flex",
      },
    },
    "& .MuiCollapse-root": {
      display: "none",
      [theme.breakpoints.down('md')]: {
        display: "block",
      },
    },
    "& .MuiCollapse-root li": {
      backgroundColor: "#ffffff",
    },
    "& .MuiListItemText-root": {
      transition: "opacity .30s ease-in-out",
      opacity: 0,
      [theme.breakpoints.down('md')]: {
        opacity: 1,
      },
    },
    "& .MuiListItemButton-root > .MuiSvgIcon-root": {
      transition: "opacity .30s ease-in-out",
      opacity: 0,
      [theme.breakpoints.down('md')]: {
        opacity: 1,
      },
    },
    "&:hover": {
      width: theme.custom.drawerWidthOpen,
      "& .userMenu": {
        display: "flex",
      },
      "& .MuiCollapse-root": {
        display: "block",
      },
      "& .MuiListItemText-root": {
        opacity: 1,
      },
      "& .MuiListItemButton-root > .MuiSvgIcon-root": {
        opacity: 1,
      },
      /*Duplicated? "& .MuiListItemButton-root > .MuiSvgIcon-root": {
        opacity: 1,
      },*/
      "& .logo img": {
        opacity: 1,
      },
      "& .logo svg": {
        opacity: 0,
      },
    },
  },
  logo: {
    margin: "0 auto",
    marginTop: 0,
    position: "relative",
    height: 58,
    "& .imgBox": {
      position: "absolute",
      margin: "4px auto",
      height: 50,
      width: "100%",
    },
    "& img": {
      opacity: 0,
      transition: "opacity .30s ease-in-out",
      height: "auto",
      maxHeight: "100%",
      maxWidth: "100%",
      margin: "0 auto",
      width: "auto",
      display: "block",
      [theme.breakpoints.down('md')]: {
        opacity: 1,
      },
    },
  },
  menu: {
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    "& li": {
      color: "#BFC5D2",
      '& a:visited': {
        color: 'inherit',
      },
      '& a:-webkit-any-link': {
        color: 'inherit',
      },
    },
    "& .MuiListItemButton-root": {
      borderRadius: "inherit",
      paddingLeft: 29,
      paddingTop: 4,
      paddingBottom: 4,
    },
    "& .MuiListItemIcon-root": {
      color: "#BFC5D2",
      minWidth: 38,
    },
    "& .MuiListItemText-root": {
      margin: 0,
    },
    "& .MuiCollapse-root li a": {
      paddingLeft: 75,
      paddingTop: 2,
      paddingBottom: 2,
    },
  },
  activeLink: {
    backgroundColor: theme.palette.background.menuActive,
    boxShadow: "inset 3px 0px 0px " + theme.palette.primary.main,
    color: theme.palette.primary.main + " !important",
    '& > .MuiListItemButton-root .MuiListItemText-root': {
      color: theme.palette.primary.main + " !important",
    },
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main + " !important",
    },
  },
  activeInnerLink: {
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main + " !important",
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
  content: {
    marginLeft: theme.custom.drawerWidth,
    width: "calc(100% - " + theme.custom.drawerWidth + "px)",
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      width: "100%",
    },
  },
  loader: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1201,
  },
  menuIcon: {
    stroke: theme.palette.text.secondary,
    width: "100%",
    marginTop: 16,
    opacity: 1,
    transition: "opacity .30s ease-in-out",
  },
  menuIconMobile: {
    stroke: theme.palette.text.secondary,
    opacity: 1,
    top: 19,
    left: 12,
    position: "fixed"
  },
  drawerContainerContent: {
    height: "calc(100vh - 58px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  drawerContainerMenu: {
    overflowY: "auto",
    overflowX: "hidden",
  },
  libutton: {
    paddingLeft: 29,
    color: theme.palette.text.secondary,
  }
});
export default styles;
