import React, { useEffect, useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { styles } from "./ResourceTransferField.styles";
import { TextInput, SelectInput } from "../../../../../common/inputs";
import { useForm } from "react-final-form";


function ResourceTransferField({ classes, id, label, note, disabled, input, resources, places, task, resourceId }) {
  const { t } = useTranslation();
  const resource = (resources || []).find(item => item.id === resourceId);
  const form = useForm();
  const [targetValue, setTargetValue] = useState(resource?.parentId);

  useEffect(() => {
    const target = task?.payload?.resources?.[resourceId]?.target;
    if (target?.placeId) {
      setTargetValue(target?.placeId)
    }
    else if (target?.parentId) {
      setTargetValue(target?.parentId)
    }
    // eslint-disable-next-line
  }, [task])


  const onTargetChangeHandler = (value) => {
    setTargetValue(value);

    const formId = resources.find(item => item.id === value) ? `${id}.parentId` : `${id}.placeId`
    form.change(formId, value);
  }

  return (
    <div className={classes.root}>
      <Grid container wrap="nowrap" spacing={0.5} alignItems="flex-end">
        <Grid item xs={6}>
          <TextInput label={t("tasks.ResourceTransferField.label.location")} value={resource?.parentName} disabled />
        </Grid>
        <Grid item>
          <ArrowForwardIcon className={classes.icon} />
        </Grid>
        <Grid item xs={6}>
          <SelectInput label={label} value={targetValue || ""} note={note} options={getTargetOptions(input, places, resources)} disabled={disabled} onChange={onTargetChangeHandler} />
        </Grid>
      </Grid>
    </div>
  );

}

export default withStyles(ResourceTransferField, styles);

const getTargetOptions = (input, places, resources) => {
  let options = [];

  options.push({ text: "PLACES", disabled: true, value: "places" });

  (places || []).forEach(place => {
    if ((input.transferAllowedPlaceType || []).some(item => item === place.type)) {
      options.push({
        value: place.id,
        text: place.title
      })
    }
  });

  options.push({ text: "RESOURCES", disabled: true, value: "resources" });

  (resources || []).forEach(resource => {
    if ((input.transferAllowedResourceType || []).some(item => item === resource.type)) {
      options.push({
        value: resource.id,
        text: resource.title
      })
    }
  });

  return options;
}