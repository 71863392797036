import {dateTimeFormatWithSeconds} from "../../../../../../common/utils/formats";
import ActionSnip from "../../../../../../common/displays/ActionSnip/ActionSnip";


export function listColumns({ t, smallScreen }) {
  let columns = [];
  columns.push({
    field: "dateIn",
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return dateTimeFormatWithSeconds(v.dateIn);
    }
  });columns.push({
    field: "dateOut",
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return <ActionSnip username={v.user} date={v.dateOut}/>;
    },
  });
  columns.push({
    field: "status",
    align: "left",
    headerAlign: "left",
  });


  return columns;
}


