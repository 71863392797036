export const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
  },
  icon: {
    color: "#BFC5D2",
  },
  activeLink: {
    display: "block",
    backgroundColor: theme.palette.background.menuActive,
    boxShadow: "inset 3px 0px 0px " + theme.palette.primary.main,
    color: theme.palette.primary.main + " !important",
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
  popover: {
    marginLeft: 70,
  },
  alertsContent: {
    width: 340,
  },
  libutton: {
    paddingLeft: 29,
    color: theme.palette.text.secondary,
  }
});
