export const taskListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "DUEDATE",
  sortType: "ASC",
  showInactive: false,
  showAssignedToMe: true,
  showAsAdmin: false,
  status: [ "PND" ],
  search: undefined,
  startDate: null,
  endDate: null,
}

export const taskListWeekDefaultFilter = {
  limit: 10000,
  offset: 0,
  sortField: "DUEDATE",
  sortType: "ASC",
  weekly: true,
}

export const taskListCommentsDefaultFilter = {
  limit: 1000,
  offset: 0,
  taskId: undefined,
  sortType: "DESC",
}

