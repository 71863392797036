import React from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Typography, Stack } from "@mui/material";

import { styles } from "./BatchOrderItem.styles";
import OrderIcon from "../../_common/OrderIcon/OrderIcon";
import { dateFormat } from "../../../../common/utils";
import ProductThumbnail from "./ProductThumbnail/ProductThumbnail";
import BatchCard from "./BatchCard/BatchCard";

function BatchOrderItem({ classes, order, onClick, resources }) {
  // const { t } = useTranslation();
  const { orderNumber, type, date, products, batches } = order;

  return (
    <div className={classes.root} onClick={() => onClick && onClick(order)}>
      <Stack spacing={1}>
        <div>
          <Grid container spacing={2} alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item>
                  <OrderIcon type={type} />
                </Grid>
                <Grid item>
                  <Typography color="textSecondary" style={{ margin: 0 }}>{orderNumber}</Typography>
                </Grid>
                {(products || []).map((element, index) => <Grid item key={element.product.productId + "_" + index}>
                  <ProductThumbnail product={element.product} quantity={element.quantity} />
                </Grid>)}
              </Grid>
            </Grid>
            <Grid item>
              <Typography color="textSecondary" style={{ margin: 0 }}>{dateFormat(date)}</Typography>
            </Grid>
          </Grid>
        </div>
        <div>
          <Grid container spacing={1}>
            {(batches || []).map(batch => <Grid key={batch.batchId} item xs={12} sm={3}><BatchCard batch={batch} resources={resources} /></Grid>)}
          </Grid>
        </div>
      </Stack>
    </div>
  );
}

export default withStyles(BatchOrderItem, styles);