import { createSlice } from "@reduxjs/toolkit";
import { setLogout } from "../../features/authentication/authenticationSlice";
import { setLoadedI18n, setPromoterDetails, setPromoterDetailsHighlightsImages } from "../coreSlice";
import { loadNewI18n } from "../i18n";
import { notificationSubscriptionHandlers } from "./subscriptions/notificationSubscriptionHandlers";
import promoterDetailsCall from "./_sliceCalls/promoterDetailsCall";
import { promoterSubscriptionHandlers } from "./subscriptions/promoterSubscriptionHandlers";
import { init, disconnect, subscribe, unsubscribe, call } from "./_sliceCalls/websocket";
import { processHomeHighlights } from "./_auxiliars/processHomeHighLights";

export const websocketSlice = createSlice({
  name: "websocket",
  initialState: {
    connected: false,
    connecting: false,
    credentials: {},
    connectError: undefined,
    subscriptions: {
      notification: false,
      promoter: false,
    },
    submitLogin: false
  },
  reducers: {
    setConnected: (state, action) => {
      state.connected = action.payload;
    },
    setConnecting: (state, action) => {
      state.connecting = action.payload;
    },
    setConnectError: (state, action) => {
      state.connectError = action.payload;
    },
    setCredentials: (state, action) => {
      state.credentials = action.payload;
    },
    setSubscription: (state, action) => {
      state.subscriptions = { ...state.subscriptions, ...action.payload };
    },
    setSubmitLogin: (state, action) => {
      state.submitLogin = action.payload;
    },
  },
});

export const { setConnected, setConnecting, setConnectError, setCredentials, setSubscription, setSubmitLogin } = websocketSlice.actions;
export default websocketSlice.reducer;

// SELECTS
export const selectLogger = (state) => state.websocket.logger;
export const selectConnected = (state) => state.websocket.connected;
export const selectConnecting = (state) => state.websocket.connecting;
export const selectCredentials = (state) => state.websocket.credentials;
export const selectConnectError = (state) => state.websocket.connectError;
export const selectSubscriptions = (state) => state.websocket.subscriptions;

// CALLS

export const initWS = (credentials, newLogin = false) => async (dispatch, getState) => {
  const host = process.env.REACT_APP_WS_HOST + "/websocket";
  dispatch(setCredentials({}));
  dispatch(setConnecting(true));
  dispatch(setConnected(false));
  dispatch(setSubmitLogin(newLogin));

  init(host, credentials, {
    onConnect: async (sessionId) => {
      dispatch(setConnectError());
      const state = getState();

      let subs = state.websocket.subscriptions;
      // SUB - NOTIFICATION
      if (subs?.notification) {
        dispatch(unsubscribeNotification());
      }
      dispatch(subscribeNotification(sessionId));
      // SUB - PROMOTER
      if (subs.promoter) {
        dispatch(unsubscribePromoter());
      }

      let promoterDetails;

      if (!state.core.promoterDetails) {
        promoterDetails = await promoterDetailsCall();
        dispatch(setPromoterDetails(promoterDetails));
        // process highLightImages for home
        processHomeHighlights(setPromoterDetailsHighlightsImages, dispatch, promoterDetails);
      }
      else {
        promoterDetails = state.core.promoterDetails;
      }
      let promoterCode = promoterDetails?.details?.code || process.env.REACT_APP_DEFAULT_PROMOTER;
      if (process.env.REACT_APP_ENV !== "production") {
        console.log('SUBSCRIBING PROMOTER: %o', promoterCode);
      }
      dispatch(subscribePromoter(promoterCode));

      if (!state.core.loggedUser) {
        try {
          call({
            wsid: null,
            type: "USER",
            subType: "DETAILS",
          });
        } catch (ex) {
          //dispatch(setError("SERVER_ERROR"));
        }
      }
      else {
        dispatch(setConnecting(false));
      }
      dispatch(setCredentials(credentials));
      dispatch(setConnected(true));
      if (state.websocket.submitLogin) {
        localStorage.setItem("account-credentials", JSON.stringify(credentials));
        dispatch(setSubmitLogin(false));
      }
      if (state.core.loadedI18n !== promoterCode) {
        dispatch(setLoadedI18n(promoterCode));
        loadNewI18n(promoterCode);
      }
    },
    onConnectError: () => {
      dispatch(setConnected(false));
      dispatch(setConnecting(false));
    },
    onDisconnect: () => {
      dispatch(setConnected(false));
      dispatch(setConnecting(false));
    },
    onWebsocketClose: (count) => {
      let connecting = getState().websocket.connecting;
      if (count >= 5) {
        disconnect();
        dispatch(setConnectError({ tag: "WS_SERVICE_ERROR" }));
        dispatch(setConnecting(false));
        dispatch(setConnected(false));
        dispatch(setCredentials({}));
        dispatch(setLogout());
        localStorage.removeItem("account-credentials");
      } else if (connecting) {
        dispatch(setConnecting(true));
      }
    },
    onFailedLogin: (msg) => {
      if (process.env.REACT_APP_ENV !== "production") {
        console.log("FAILED LOGIN msg: %o", msg);
      }
      dispatch(setConnectError({ tag: msg }));
      dispatch(setConnecting(false));
      dispatch(setCredentials({}));
      localStorage.removeItem("account-credentials");
    },
  });
};

export const logout = () => async (dispatch) => {
  disconnect();
  dispatch(setConnected(false));
  dispatch(setCredentials({}));
  dispatch(setLogout());
  localStorage.removeItem("account-credentials");
};

export const subscribeNotification = (sessionId) => async (dispatch, getState) => {
  subscribe("notification", "/secured/user/queue/notification-user" + sessionId, (message) => {
    try {
      let body = JSON.parse(message.body);
      notificationSubscriptionHandlers(body, dispatch);
    } catch (e) {
      console.error(e);
      return;
    }
  });
  dispatch(setSubscription({ notification: true }));
};

export const unsubscribeNotification = () => async (dispatch) => {
  unsubscribe("notification");
  dispatch(setSubscription({ notification: false }));
};

export const subscribePromoter = (promoter) => async (dispatch, getState) => {
  subscribe("promoter", "/queue/promoter/" + promoter, (message) => {
    try {
      let body = JSON.parse(message.body);
      promoterSubscriptionHandlers(body, dispatch, getState());
    } catch (e) {
      console.error(e);
      return;
    }
  });
  dispatch(setSubscription({ promoter: true }));
};

export const unsubscribePromoter = () => async (dispatch) => {
  unsubscribe("promoter");
  dispatch(setSubscription({ promoter: false }));
};
