import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Stack, Tooltip } from "@mui/material";

import { styles } from "./AreaCard.styles";

function AreaCard({ classes, areaName, tanks, highLightResources }) {
  return (
    <div className={classes.root}>
      {
        tanks?.length > 0 ?
          <Stack>
            {tanks.map((tank, index) => <div className={classes.tank} key={"tank_" + index}>
              <div className={classes.tankContent}>
                <Stack>
                  {/* <Tooltip title={areaName} placement="left">
                    <Typography color="textSecondary" style={{ margin: 0 }}>{tank.title}</Typography>
                  </Tooltip> */}
                  <Typography color="textSecondary" style={{ margin: 0 }}>{areaName + " | " + tank.title}</Typography>

                  <Stack direction="row">
                    {Array.from({ length: parseInt(tank?.attributes?.slots || 1) }).map((_, indexSlot) => {
                      if (tank.resources?.[indexSlot]) {
                        return (
                          <Tooltip key={"slot_" + indexSlot} title={tank.resources?.[indexSlot]?.title} placement="left">
                            <div className={classes.cylinder + " " + classes.cylinderUsed + " " + ((highLightResources || []).some(item => item === tank.resources?.[indexSlot]?.id) ? classes.cylinderHighLighted : "")}></div>
                          </Tooltip>
                        )
                      }
                      return (
                        <div key={"slot_" + indexSlot} className={classes.cylinder}></div>
                      )
                    })}
                  </Stack>
                </Stack>
              </div>
            </div>)}
          </Stack>
          :
          <div className={classes.empty}><Typography color="textSecondary" style={{ margin: 0 }}>{areaName}</Typography></div>
      }
    </div>
  );
}

export default withStyles(AreaCard, styles);