export const styles = (theme) => ({
  root: {},
  userItem: {
    "& .MuiTypography-body1": {
      overflow: "hidden",
    },
  },
  avatar: {
    marginLeft: -9,
  },
  user: {
    margin: 0,
  },
  entity: {
    color: theme.palette.text.secondary,
    margin: 0,
    cursor: "pointer",
  },
  sector: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    margin: 0,
  },
  rotateIcon: {
    transform: "rotate(180deg)",
  },
  accountMenuPopover: {
    marginLeft: 70,
  },
  activeLink: {
    display: "block",
    backgroundColor: theme.palette.background.menuActive,
    boxShadow: "inset 3px 0px 0px " + theme.palette.primary.main,
    color: theme.palette.primary.main + " !important",
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
  name: {
    whiteSpace: "nowrap",
  },
  userMenuContent: {
    width: 320,
  },
  libutton: {
    paddingLeft: 29,
    color: theme.palette.text.secondary,
  }
});
