import moment from "moment";

export const calculatePriceWithPromotions = (price, promotions, promoter) => {
  // if has price accumulation, should sum the promotions, if not use the biggest one
  const cpAccumulation = promoter?.getConfig("order", "cpAccumulation");
  let percentageFactor = 0;
  let activePromotions = promotions.filter(item => moment().isBetween(moment(item.startDate), moment(item.endDate), "day", "[]"));
  if (cpAccumulation === "AL") {
    percentageFactor = activePromotions.reduce((agg, item) => item.type === "PD" ? agg + parseInt(item.percentage) : agg, 0);
    if (percentageFactor > 100) {
      percentageFactor = 100;
    }
  }
  else {
    percentageFactor = activePromotions.reduce((agg, item) => item.type === "PD" ? (agg > parseInt(item.percentage) ? agg : parseInt(item.percentage)) : agg, 0);
  }
  return price * (1 - (percentageFactor / 100));
}

export const calculatePriceWithDproms = (price, dproms) => {
  let processedDproms = [];
  if (dproms && dproms.length > 0) {
    dproms.forEach(dprom => {
      let discount = getDpromDiscount(dprom.value, price);
      processedDproms.push({ ...dprom, discount })
    })
  }
  if (processedDproms && processedDproms.length > 0) {
    let maxDiscount = 0;
    processedDproms.forEach(({ discount }) => {
      maxDiscount = Math.max(maxDiscount, discount);
    });
    let total = price - maxDiscount;
    return total <= 0 ? 0 : total;
  }
  return price;
}

export const getDpromDiscount = (value, price) => {
  if (!value) {
    return 0
  }
  if (value.type === "P") {
    return price * value.amount / 100
  }
  else
    return value.amount;
}

export const getEntitiesInGroups = (entityGroups, groups) => {
  // console.log('getEntitiesInGroups: %o', { entityGroups, groups })
  // return all entities Ids, that are connected to groups (list of ids)
  let filteredGroups = (entityGroups || []).filter(item => (groups || []).some(group => group === item.groupId));
  let result = [];
  filteredGroups.forEach(item => {
    result.push(item.entityId);
    (item?.listEntities || []).forEach(entity => result.push(entity.entityId))
  });

  return [...new Set(result)];
}

export const processDate = (date) => {
  let nDate = !moment.isMoment(date) ? moment(date) : date;
  return nDate.isValid() ? date.format('YYYY-MM-DD') : null;
}

export const processPriceDpromsByLocation = (price, locationsList) => {
  // console.log('processPriceDproms: %o', { price, locationsList });

  let dpromByLocation = [];
  // price with only one location
  if (price.locations?.length === 1) {
    dpromByLocation.push({
      location: locationsList.find(item => item.id === price.locations[0]),
      dprom: getPriceDpromByLocation(price.locations[0], price.dproms),
    })
  }
  else if (price.locations?.length > 1) {
    price.locations.forEach(location => {
      dpromByLocation.push({
        location: locationsList.find(item => item.id === location),
        dprom: getPriceDpromByLocation(location, price.dproms),
      })
    })
  }
  else {
    const distinctLocations = [...new Set((price.dproms || []).reduce((acc, obj) => {
      return acc.concat(obj.locations);
    }, []))].filter(item => item);
    // console.log('distinctLocations: %o', distinctLocations);
    distinctLocations.forEach(location => {
      dpromByLocation.push({
        location: locationsList.find(item => item.id === location),
        dprom: getPriceDpromByLocation(location, price.dproms),
      })
    })
  }

  // console.log('dpromByLocation: %o', dpromByLocation);
  return dpromByLocation;
}

const getPriceDpromByLocation = (locationId, dproms) => {
  if (!dproms || dproms?.length === 0) {
    return {};
  }
  // filter dproms without location or with the locationId
  let filteredDproms = dproms.filter(item => !item.locations || item.locations.some(locId => locationId === locId));

  if (filteredDproms.some(item => item.products)) {
    // if dproms with products exist the rest will be discarded
    filteredDproms = filteredDproms.filter(item => item.products);
  } else if (filteredDproms.some(item => item.family_code)) {
    // if dproms with families exist the rest will be discarded
    filteredDproms = filteredDproms.filter(item => item.family_code);
  }

  if (!filteredDproms) {
    return {};
  }

  // returns the dprom with the max discount (this assumes the discount is always % (type P))
  return filteredDproms.reduce((max, item) =>
    parseFloat(item.value.amount) > parseFloat(max.value.amount) ? item : max, { value: { amount: 0 } }
  );
}
