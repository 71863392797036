import DateTime from "../../../../common/displays/DateTime/DateTime";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import EntitySnip from "../../../../common/displays/EntitySnip/EntitySnip";

export function listColumns(t, promoter, classes) {

  return [
    {
      field: "productName",
      headerName: t("business.queries.ProductList.columns_productName"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <ProductSnip image={v.thumbnail || v.image} description={v.productName} gtin={v.gtin} promoterInternalCode={v.productInternalCode} />;
      },
    },
    {
      field: "gln",
      headerName: t("business.queries.ProductList.columns_gln"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "ownerTaxNumber",
      headerName: t("business.queries.ProductList.columns_ownerName"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <EntitySnip name={v.ownerName} taxNumber={v.ownerTaxNumber} internalCode={ promoter?.getConfig("entity", "requiresInternalCode") ? v.ownerInternalCode : ""} />;
      },
    },
    {
      field: "createdBy",
      headerName: t("business.queries.ProductList.columns_createdBy"),
      align: "left",
      headerAlign: "left"
    },
    {
      field: "createdByEntityTaxNumber",
      headerName: t("business.queries.ProductList.columns_createdByEntityName"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return <EntitySnip name={v.createdByEntityName} taxNumber={v.createdByEntityTaxNumber} />;
      },
    },
    {
      field: "createdTime",
      headerName: t("business.queries.ProductList.columns_createdTime"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.createdTime ? <DateTime date={v.createdTime} /> : "";
      },
    },{
      field: "acceptedDate",
      headerName: t("business.queries.ProductList.columns_acceptedDate"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return v.acceptedDate ? <DateTime date={v.acceptedDate} /> : "";
      }
    },{
      field: "status",
      headerName: t("business.queries.ProductList.columns_status"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.productStatus." + v?.status)
      }
    },{
      field: "available",
      headerName: t("business.queries.ProductList.columns_available"),
      align: "left",
      headerAlign: "left",
      customValue: (v) => {
        return t("common.Enums.productAvailable." + v?.available)
      },

    },

  ];
}
