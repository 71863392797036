import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Divider, Tooltip } from "@mui/material";

import { styles } from "./RoomCard.styles";
import AreaCard from "./AreaCard/AreaCard";
import { sortList } from "../../../../../common/utils";

function RoomCard({ classes, placeName, places, buildingName, highLightResources }) {
  const items = [...(places || [])];
  return (
    <div className={classes.root}>
      <Tooltip title={buildingName} placement="left">
        <Typography className={classes.title} variant="h5">{placeName}</Typography>
      </Tooltip>
      <Divider />
      {sortList(items, "placeName").map(place => <AreaCard key={place.placeId || place.id} areaName={place.placeName || place.title} tanks={place.resources} highLightResources={highLightResources} />)}
    </div>
  );
}

export default withStyles(RoomCard, styles);