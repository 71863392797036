import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "@mui/material";

import {styles} from "./StepsDataGrid.styles";
import {listColumns} from "./StepsDataGrid.columns";

import {CustomDataGrid} from "../../../../../../common/displays/CustomDataGrid";

function StepsDataGrid({
  classes,
  productId,
  data,
}) {
  const { t } = useTranslation();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down('xl'));
  const columns = listColumns({ t, smallScreen });

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={data}
        hasHeader={false}
        total={data?.length}
        rowKeyField="tempId"
      />
    </div>
  );
}

export default withStyles(StepsDataGrid, styles);
