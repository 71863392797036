import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import styles from "./Login.styles";

import { LinearProgress, Typography, Grid, Button } from "@mui/material";
import { Alert } from "@mui/material";

import { cleanErrors, selectStatus, selectLoader } from "../authenticationSlice";

import { selectConnectError, selectConnecting, selectConnected, initWS } from "../../../app/websocket/websocketSlice";

import AuthenticationContainer from "../../../common/components/containers/AuthenticationContainer";

import { Form } from "../../../common/forms";
import { formFields, formSchema } from "./Login.schema";
import { hash } from "../../../common/utils/crypto";
import { useUser } from "../../../common/hooks";
import { classJoin } from "../../../common/utils";
import { userChangePromoter } from "../../users/usersSlice";

function Login({ classes }) {
  const { t } = useTranslation();
  const user = useUser();
  const status = useSelector(selectStatus);
  const loader = useSelector(selectLoader);
  const dispatch = useDispatch();
  const [autoLogin, setAutoLogin] = useState(false);

  const error = useSelector(selectConnectError);
  const connected = useSelector(selectConnected);
  const connectInProgress = useSelector(selectConnecting);

  const navigate = useNavigate();
  let location = useLocation();

  let fromPath = location.state?.from?.pathname || "";

  useEffect(() => {
    dispatch(cleanErrors());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!connectInProgress) {
      setAutoLogin(false);
      if (user && connected) {
        let pathPromoter = fromPath.split('/')[1];
        if (pathPromoter && user.promoterList?.map(item => item.code).includes(pathPromoter)) {
          if (user?.promoterSelected !== pathPromoter) {
            //change promoter
            dispatch(userChangePromoter({ promoterCode: pathPromoter, pathToRedirect: fromPath.split(pathPromoter)[1] }))
          }
          else {
            navigate({ pathname: fromPath });
          }
        }
        else {
          navigate({ pathname: `/${user?.promoterSelected}` });
        }
      } else {
        let account;
        try {
          account = JSON.parse(localStorage.getItem("account-credentials"));
        } catch (_ex) { }
        if (account?.username) {
          setAutoLogin(true);
          dispatch(initWS(account));
        }
      }
    }
    // eslint-disable-next-line
  }, [user, connected, connectInProgress]);

  const onSubmit = ({ email, password }) => {
    let credentials = {
      username: email.toLowerCase(),
      password: hash(password),
    };
    dispatch(initWS(credentials, true));
  };

  return (
    <AuthenticationContainer imgUrl={process.env.REACT_APP_BACKGROUND_IMAGE_URL}>
      {!connected && !autoLogin && !connectInProgress && <div>
        <div className={classJoin([classes.form, process.env.REACT_APP_ENV === "dev" ? classes.formDrunk : undefined])}>
          <Form
            onSubmit={onSubmit}
            initialValues={{}}
            schema={formSchema(t)}
            render={({ submitting }) => (
              <>
                <Typography variant="h2" align="left" component="h1">
                  {t("authentication.Login.form.title")}
                </Typography>
                <Typography
                  variant="subtitle1"
                  align="left"
                  component="h6"
                  color="textSecondary"
                  style={{ whiteSpace: "nowrap" }}
                >
                  {t("authentication.Login.form.subtitle")}
                </Typography>
                <Grid container alignItems="flex-start" justifyContent="flex-end" spacing={2}>
                  {formFields(t).map((item, idx) => (
                    <Grid item xs={item.size} key={idx}>
                      {item.field}
                    </Grid>
                  ))}
                  {error && (
                    <Grid item xs={12}>
                      <Alert variant="filled" severity="error">
                        {t("authentication.Login.form." + error?.tag || error)}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      className={classes.btn}
                      disabled={submitting || !!loader}
                    >
                      {t("authentication.Login.form.submit")}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Link className={classes.link} to={`/recover`}>
                      {t("authentication.Login.form.recoverLink")}
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          />
        </div>
        <div className={classes.snackBar}>
          {status === "RECOVER_SUCCESS" && (
            <Alert variant="filled" severity="success">
              {t("authentication.RecoverPass.form.success")}
            </Alert>
          )}
          {status === "PASSWORD_UPDATED" && (
            <Alert variant="filled" severity="success">
              {t("authentication.PasswordUpdate.form.success")}
            </Alert>
          )}
        </div>
      </div>
      }
      {(loader || connected || autoLogin || connectInProgress) && <LinearProgress className={classes.loader} />}
    </AuthenticationContainer>
  );
}

export default withStyles(Login, styles);
