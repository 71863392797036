import React from "react";
import { Grid } from "@mui/material";
import { Masonry } from "@mui/lab";

function FormFields({ fields, masonry }) {
  return masonry ? (
    <Masonry columns={{ xs: 4 }} spacing={1}>
      {fields.map((item, idx) =>
        !item.hidden ? <div key={idx}>
          {item.field}
        </div> : null
      )}
    </Masonry>
  ) : (
    <Grid container spacing={1}>
      {fields.map((item, idx) =>
        !item.hidden ? (
          <Grid item sm={item.size} xs={12} key={idx}>
            {item.field}
          </Grid>
        ) : item.keepSpace ? (
          <Grid item sm={item.size} xs={12} key={idx}></Grid>
        ) : null
      )}
    </Grid>
  );
}

export default FormFields;
