import { CheckboxField, CheckListField, FileUploadField } from "../../../../common/forms";
import DocumentTypeField from "../DocumentTypeField/DocumentTypeField";

export const productFields = ({ t, user, refData, formOptions }) => {
  return [
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.product.autoAcceptUpdate"
          label={t("promoter.PromoterConfigs.form.productAutoAcceptUpdate")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.product.autoAcceptUpdateExternal"
          label={t("promoter.PromoterConfigs.form.productAutoAcceptUpdateExternal")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.product.autoAcceptCreate"
          label={t("promoter.PromoterConfigs.form.productAutoAcceptCreate")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.product.allowFamilyCode"
          label={t("promoter.PromoterConfigs.form.productAllowFamilyCode")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField
          id="functional.product.mandatoryImage"
          label={t("promoter.PromoterConfigs.form.productMandatoryImage")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
    {
      size: 4,
      field: (
        <CheckboxField
          id="technical.product.requiresInternalCode"
          label={t("promoter.PromoterConfigs.form.productRequiresInternalCode")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },{
      size: 4,
      field: (
        <CheckboxField
          id="technical.product.allowAllUnits"
          label={t("promoter.PromoterConfigs.form.productAllowAllUnits")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <FileUploadField
          id="technical.product.importTemplate"
          label={t("promoter.PromoterConfigs.form.importTemplateProduct")}
          placeholder={t("promoter.PromoterConfigs.form.importTemplateProductPlaceholder")}
          image
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 12,
    },
    {
      size: 4,
      field: (
        <CheckListField
          id="technical.product.allowedEntityTypes"
          options={formOptions.entityType}
          label={t("promoter.PromoterConfigs.form.allowedEntityTypes")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },
    {
      size: 4,
      field: (
        <DocumentTypeField
          id="functional.product.documentTypes"
          label={t("promoter.PromoterConfigs.form.productDocumentType")}
          documentType={refData?.documentType}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

    {
      size: 4,
      field: (
        <CheckListField
          id="functional.product.listProductColumns"
          options={formOptions.listProductColumns}
          label={t("promoter.PromoterConfigs.form.listProductColumns")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },

    {
      size: 4,
      field: (
        <CheckListField
          id="technical.product.listProductFields"
          options={formOptions.listProductFields}
          label={t("promoter.PromoterConfigs.form.listProductFields")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.technical")
    },

    {
      size: 4,
      field: (
        <CheckListField
          id="functional.product.listCustomForms"
          options={formOptions.customForms}
          label={t("promoter.PromoterConfigs.form.customForms")}
        />
      ),
      hidden: !user.hasPermission("promoter.actions.configs.functional")
    },
  ];
};