import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Alert, Button, Grid } from "@mui/material";

import { styles } from "./ProductDetails.styles";
import ProductMediaDetails from "./ProductMediaDetails/ProductMediaDetails";
import ProductDocumentsDetails from "./ProductDocumentsDetails/ProductDocumentsDetails";
import ProductHierarchyDetails from "./ProductHierarchyDetails/ProductHierarchyDetails";
import GeneralInfo from "./GeneralInfo/GeneralInfo";
import Units from "./Units/Units";
import Measures from "./Measures/Measures";
import Order from "./Order/Order";
import Management from "./Management/Management";
import Storage from "./Storage/Storage";
import Taxes from "./Taxes/Taxes";
import Sustainability from "./Sustainability/Sustainability";

import { useNavigate, usePromoter, useUser } from "../../../common/hooks";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { useFirstLoad } from "../../../common/hooks/useFirstLoad";
import { selectLoader } from "../../../app/coreSlice";
import RejectButtonWithComment from "../_common/RejectButtonWithComment/RejectButtonWithComment";
import Page from "../../../common/displays/Page/Page";
import ScrollNav from "../../../common/displays/ScrollNav/ScrollNav";
import { productGet, productPendingProductAction, productRecover, selectProductDetails } from "../productsSlice";
import Permission from "../../../app/components/Permission";
import AcceptProductCreateButton from "./AcceptProductCreateButton/AcceptProductCreateButton";
import CustomModal from "../../../common/displays/CustomModal/CustomModal";
import PendingPurchaseForm from "../../productsPurchases/PendingPurchasesList/PendingPurchaseForm/PendingPurchaseForm";
import CustomFormsDetails from "./CustomFormsDetails/CustomFormsDetails";
import ProductComments from "./ProductComments/ProductComments";
import ProductHistory from "./ProductHistory/ProductHistory";

function ProductDetails({ classes, confirmation, validation, recover }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const product = useSelector(selectProductDetails);
  const { id, task, processId } = useParams();
  const navigate = useNavigate();
  const user = useUser();
  const promoter = usePromoter();
  const loading = useSelector(selectLoader);
  const [modalPurchase, setModalPurchase] = useState(false);

  useFirstLoad(() => {
    if (product?.id !== id) {
      dispatch(productGet(id));
    }
  });

  const acceptCreateHandler = (productTemplate, comment) => {
    dispatch(
      productPendingProductAction({
        status: "VALIDATING",
        taskId: task,
        productId: id,
        productTemplate,
        comment
      })
    );
  };

  const rejectCreateHandler = (comment) => {
    dispatch(
      productPendingProductAction({
        status: "REFUSED",
        taskId: task,
        productId: id,
        comment,
      })
    );
  };

  const acceptValidationHandler = () => {
    dispatch(
      productPendingProductAction({
        status: "ACCEPTED",
        taskId: task,
        productId: id,
      })
    );
  };

  const rejectValidationHandler = (comment) => {
    dispatch(
      productPendingProductAction({
        status: "NOT_VALIDATED",
        taskId: task,
        productId: id,
        comment,
      })
    );
  };

  const recoverHandler = () => {
    dispatch(productRecover(id, processId));
  }

  return (
    <Page
      permission={!confirmation && !validation && !recover ? "products.actions.product.get" : "products.actions.pendingProduct.get"}
      header={
        <PageTitle
          title={product?.baseInfo?.description}
          onBack={() => (confirmation || validation || recover ? navigate("/products/validation") : navigate("/products"))}
          actions={
            <Grid container direction="row" spacing={2}>
              <Permission code="products.actions.product.history">
                <Grid item>
                  <ProductHistory productId={product?.id} product={product} />
                </Grid>
              </Permission>
                <Permission code="products.actions.product.listComments">
                <Grid item>
                  <ProductComments productId={product?.id} />
                </Grid>
              </Permission>
              {confirmation && product?.status === "PND" && (
                <Permission code="products.actions.pendingProduct.action">
                  <Grid item>
                    <AcceptProductCreateButton onAccept={(productTemplate, comment) => acceptCreateHandler(productTemplate, comment)} loading={loading} />
                  </Grid>
                </Permission>
              )}
              {confirmation && product?.status === "PND" && (
                <Permission code="products.actions.pendingProduct.action">
                  <Grid item>
                    <RejectButtonWithComment
                      btnLabel={t("products.ProductDetails.header.btnReject")}
                      cancelLabel={t("products.ProductDetails.header.rejectCancel")}
                      inputLabel={t("products.ProductDetails.header.rejectComment")}
                      title={t("products.ProductDetails.header.rejectTitle")}
                      onConfirm={rejectCreateHandler}
                      disabled={loading}
                    />
                  </Grid>
                </Permission>
              )}
              {confirmation && product?.status === "VAL" && (
                <Grid item>
                  <Alert sx={{ padding: "0px 8px", marginRight: "5px" }} severity="success">{t("products.ProductDetails.header.statusAccepted")}</Alert>
                </Grid>
              )}
              {confirmation && product?.status === "ACC" && (
                <Grid item>
                  <Alert sx={{ padding: "0px 8px", marginRight: "5px" }} severity="success">{t("products.ProductDetails.header.statusAccepted")}</Alert>
                </Grid>
              )}
              {confirmation && product?.status === "REF" && (
                <Grid item>
                  <Alert sx={{ padding: "0px 8px", marginRight: "5px" }} severity="error">{t("products.ProductDetails.header.statusRejected")}</Alert>
                </Grid>
              )}
              {validation && product?.status === "VAL" && (
                <Permission code="products.actions.pendingProduct.action">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="button"
                      disabled={loading}
                      onClick={acceptValidationHandler}
                    >
                      {t("products.ProductDetails.header.btnAccept")}
                    </Button>
                  </Grid>
                </Permission>
              )}
              {validation && product?.status === "VAL" && (
                <Permission code="products.actions.pendingProduct.action">
                  <Grid item>
                    <RejectButtonWithComment
                      btnLabel={t("products.ProductDetails.header.btnReject")}
                      cancelLabel={t("products.ProductDetails.header.rejectCancel")}
                      inputLabel={t("products.ProductDetails.header.rejectComment")}
                      title={t("products.ProductDetails.header.rejectTitle")}
                      onConfirm={rejectValidationHandler}
                      disabled={loading}
                    />
                  </Grid>
                </Permission>
              )}
              {validation && product?.status === "ACC" && (
                <Grid item>
                  <Alert sx={{ padding: "0px 8px", marginRight: "5px" }} severity="success">{t("products.ProductDetails.header.statusAccepted")}</Alert>
                </Grid>
              )}
              {validation && product?.status === "REF" && (
                <Grid item>
                  <Alert sx={{ padding: "0px 8px", marginRight: "5px" }} severity="error">{t("products.ProductDetails.header.statusRejected")}</Alert>
                </Grid>
              )}
              {validation && product?.status === "PND" && (
                <Grid item>
                  <Alert sx={{ padding: "0px 8px", marginRight: "5px" }} severity="error">{t("products.ProductDetails.header.statusRejected")}</Alert>
                </Grid>
              )}
              {recover && product?.status === "REF" && user?.entity?.type === "P" && (
                <Permission code="products.actions.product.create">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="button"
                      disabled={loading}
                      onClick={recoverHandler}
                    >
                      {t("products.ProductDetails.header.btnRecover")}
                    </Button>
                  </Grid>
                </Permission>
              )}
              {!confirmation && !validation && !recover && product?.status === "ACC" &&
                <Permission code="products.actions.purchase.request">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      type="button"
                      disabled={loading}
                      onClick={() => setModalPurchase(true)}
                    >
                      {t("products.ProductDetails.header.btnPurchase")}
                    </Button>
                  </Grid>
                </Permission>
              }
              {!confirmation && !validation && !recover && product?.status !== "PND" &&
                <Permission code="products.actions.product.copy">
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      type="button"
                      disabled={loading}
                      onClick={() => navigate(`/products/copy/${product.id}`)}
                    >
                      {t("products.ProductDetails.header.btnCopy")}
                    </Button>
                  </Grid>
                </Permission>
              }
              {!confirmation && !validation && !recover && product?.status !== "PND" &&
                <Permission code="products.actions.product.update">
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="large"
                      type="button"
                      disabled={loading}
                      onClick={() => navigate(`/products/edit/${product.id}`)}
                    >
                      {t("products.ProductDetails.header.btnEdit")}
                    </Button>
                  </Grid>
                </Permission>
              }
            </Grid>
          }
        />
      }
    >
      {product && product.id === id && (
        <ScrollNav
          tabs={[
            {
              id: "generalSection",
              title: t("products.ProductCreate.form.generalTitle"),
              content: <GeneralInfo product={product} taskId={task} />,
            },
            {
              id: "formsSection",
              title: t("products.ProductCreate.form.customFormsTitle"),
              content: <CustomFormsDetails forms={product?.forms} />,
              hidden: product?.externalSource || (promoter?.getConfig("product", "listCustomForms") || []).length === 0 || Object.keys(product?.forms || {}).length === 0
            },
            {
              id: "unitsSection",
              title: t("products.ProductCreate.form.unitsTitle"),
              content: <Units units={product?.units} />,
            },
            {
              id: "measuresSection",
              title: t("products.ProductCreate.form.measuresTitle"),
              content: <Measures product={product} />,
            },
            {
              id: "orderSection",
              title: t("products.ProductCreate.form.orderTitle"),
              content: <Order product={product} />,
            },
            {
              id: "taxesSection",
              title: t("products.ProductCreate.form.taxesTitle"),
              content: <Taxes taxes={product?.taxes} />,
            },
            {
              id: "managementSection",
              title: t("products.ProductCreate.form.managementTitle"),
              content: <Management product={product} />,
              hidden: !["hasBatchNumber", "hasExpirationDate", "hasVariableWeight", "hasSerialNumber"].some(name => (promoter?.getConfig("product", "listProductFields") || []).includes(name))
            },
            {
              id: "storageSection",
              title: t("products.ProductCreate.form.storageTitle"),
              content: <Storage product={product} />,
            },
            {
              id: "documentsSection",
              title: t("products.ProductCreate.form.documentsTitle"),
              content: <ProductDocumentsDetails documents={product?.documents} />,
            },
            {
              id: "hierarchySection",
              title: t("products.ProductCreate.form.hierarchyTitle"),
              content: <ProductHierarchyDetails allowEdit={!confirmation && !validation} product={product} />,
            },
            {
              id: "sustainabilitySection",
              title: t("products.ProductCreate.form.sustainabilityTitle"),
              content: <Sustainability sustainability={product?.sustainability || []} />,
            },
            {
              id: "mediaSection",
              title: t("products.ProductMediaDetails.header.title"),
              content: <ProductMediaDetails media={product?.media} />,
            },
          ]}
        />
      )}
      <CustomModal open={modalPurchase} setOpen={setModalPurchase} title={t("products.ProductDetails.modalPurchase.title")} size="small" height={440}>
        <PendingPurchaseForm
          action="CREATE"
          productId={id}
          onSubmit={() => {
            setModalPurchase(false);
          }}
        />
      </CustomModal>
    </Page>
  );
}

export default withStyles(ProductDetails, styles);
