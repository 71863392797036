import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";

import { styles } from "./ReassignModal.styles";
import CustomModal from "../../CustomModal/CustomModal";
import { Form, FormFields } from "../../../forms";
import { formFields, formSchema } from "./ReassignModal.schema";
import { usePromoter } from "../../../hooks";

function ReassignModal({ classes, open, setOpen, onSubmit, entity, users, groups, sectors }) {
  const { t } = useTranslation();
  const promoter = usePromoter();

  const onSubmitHandler = async (values) => {
    const { users, groups, sectors } = values;
    if (values?.associationType === "USER") {
      onSubmit && onSubmit({ users });
    }
    if (values?.associationType === "GROUP") {
      onSubmit && onSubmit({ groups });
    }
    if (values?.associationType === "SECTOR") {
      onSubmit && onSubmit({ sectors });
    }
    setOpen(false);
  };

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={325} title={t("common.ReassignModal.modal.title")}>
      <div className={classes.root}>
        <Form
          onSubmit={onSubmitHandler}
          schema={formSchema({ t })}
          initialValues={{ users, groups, sectors, associationType: (sectors?.length > 0 ? "SECTOR" : groups?.length > 0 ? "GROUP" : "USER") }}
          render={({ values }) => {
            return (
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <FormFields fields={formFields({ t, values, promoter, contacts: entity?.contacts, sectorsGroups: entity?.sectorsGroups })} />
                </Grid>
                <Grid item>
                  <Button variant="contained" color="primary" type="submit" fullWidth>
                    {t("common.ReassignModal.form.btnSubmit")}
                  </Button>
                </Grid>
              </Grid>
            );
          }}
        />
      </div>
    </CustomModal>
  );
}

export default withStyles(ReassignModal, styles);