import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Grid, IconButton, Stack, Tooltip} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import ActionsPopover from "../../../../../common/components/ActionsPopover/ActionsPopover";
import Permission from "../../../../../app/components/Permission";
import {dateFormat} from "../../../../../common/utils";
import {usePromoter, useUser} from "../../../../../common/hooks";
import HistoryIcon from "@mui/icons-material/History";

export default function PricesVariantsDataGridActions({classes, row, validation, onValidate, onRemove, product, onHistory }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || product.promoterInternalCode;

  let actionsButtons = [];

  const onHistoryHandler = (e) => {
    e.preventDefault();
    onHistory && onHistory(product, row.priceId);
  };

  const setValidHandler = (value) => {
    const { priceId, process, valid } = row;
    onValidate && onValidate({ priceId, valid, taskId: process?.taskId, productId: product?.productId }, value);
  }

  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove(row);
  }


  if(user.hasPermission("products.actions.price.history")){
    actionsButtons.push(
          <Button onClick={onHistoryHandler} color="primary" fullWidth>
            <Grid container spacing={1} alignItems="center">
              <Grid item style={{ display: "flex" }}>
                <HistoryIcon />
              </Grid>
              <Grid item>{t("productsPrices.PricesList.actions.history")}</Grid>
            </Grid>
          </Button>
    );
  }

  // DELETE - when is pending only owner can cancel, when not pending owner and promoter can remove
  // CANCEL
  if (row?.process?.processType !== "FlowRemovePrice" && user.hasPermission("products.actions.price.remove") && allowedToChange && row?.process?.status === "PENDING" && !user?.isPromoter) {
    actionsButtons.push(
      <Button onClick={onRemoveHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("productsPrices.PricesList.actions.cancel")}</Grid>
        </Grid>
      </Button>
    );
  }
  // REMOVE
  if (row?.process?.processType !== "FlowRemovePrice" && user.hasPermission("products.actions.price.remove") && allowedToChange && row?.status !== "END" && row?.status !== "REF" && row?.process?.status !== "PENDING") {
    actionsButtons.push(
      <Button onClick={onRemoveHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("productsPrices.PricesList.actions.remove")}</Grid>
        </Grid>
      </Button>
    );
  }

  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
      {row?.process?.processType === "FlowRemovePrice" && row?.removeDate && allowedToChange &&
          (
              row?.process?.status === "PENDING" ?
                  <Grid item>
                    <div style={{ margin: 6, display: "flex", justifyContent: "flex-end" }}>
                      <Tooltip title={t("productsPrices.PricesList.columns.pendingRemove")}>
                        <ReportProblemOutlinedIcon style={{ display: "block" }} color="warning" fontSize="small" />
                      </Tooltip>
                    </div>
                  </Grid> :
                  <Grid item>
                    <div style={{ margin: 6, display: "flex", justifyContent: "flex-end" }}>
                      <Tooltip title={`${t("productsPrices.PricesList.columns.removeDate")} ${dateFormat(row.removeDate)}`}>
                        <ReportProblemOutlinedIcon style={{ display: "block" }} color="warning" fontSize="small" />
                      </Tooltip>
                    </div>
                  </Grid>
          )
      }
      {!validation && actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }

      {validation && row?.process?.waitingAction && row?.process?.status === "PENDING" && allowedToChange &&
        <Permission code="products.actions.pendingPrice.action">
          <Grid item>
            <Stack direction="row" spacing={0} >
              <IconButton onClick={() => setValidHandler(false)}>
                <CancelOutlinedIcon color={row.valid === false ? "error" : "default"} />
              </IconButton>
              <IconButton onClick={() => setValidHandler(true)}>
                <CheckCircleOutlineIcon color={row.valid === true ? "success" : "default"} />
              </IconButton>
            </Stack>
          </Grid>
        </Permission>
      }

    </Grid>
  );
}
