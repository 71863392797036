import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Button, Paper, Stack, Drawer, Divider, ToggleButtonGroup, ToggleButton } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./BatchesList.styles";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { selectLoader } from "../../../app/coreSlice";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import InfiniteList from "../../../common/components/displays/InfiniteList/InfiniteList";
import { batchActivity, batchGet, batchList, batchListPlaces, batchListTasks, selectBatchActivityListFilter, selectBatchList, selectBatchListFilter, selectBatchListTotalRows, selectPlacesList, selectTaskDetails, setBatchDetails, taskGet } from "../batchesSlice";
import BatchListItem from "./BatchListItem/BatchListItem";
import BatchDrawerDetails from "./BatchDrawerDetails/BatchDrawerDetails";
import PlaceListItem from "./PlaceListItem/PlaceListItem";
import BatchTaskListModal from "./BatchTaskListModal/BatchTaskListModal";
import TaskDetailsModal from "../../tasks/TasksList/TaskDetailsModal/TaskDetailsModal";
import { sortList } from "../../../common/utils";
import BatchMetricsModal from "./BatchMetricsModal/BatchMetricsModal";
import BatchActivityModal from "./BatchActivityModal/BatchActivityModal";

function BatchesList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { businessUnitId } = useParams();
  const batchesData = useSelector(selectBatchList);
  const placesData = useSelector(selectPlacesList);
  const totalRows = useSelector(selectBatchListTotalRows);
  const filter = useSelector(selectBatchListFilter);
  const loading = useSelector(selectLoader);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [highLightResources, setHighLightResources] = useState([]);
  const [taskModal, setTaskModal] = useState(false);
  const [modalDetails, setModalDetails] = useState(false);
  const [modalMetrics, setModalMetrics] = useState(false);
  const [modalActivity, setModalActivity] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const taskDetails = useSelector(selectTaskDetails);
  const activityFilter = useSelector(selectBatchActivityListFilter);

  useFirstLoad(() => {
    dispatch(batchList(businessUnitId, filter, true));
    dispatch(batchListPlaces({ businessUnitId }));
  })

  useEffect(() => {
    console.log('taskDetails: %o', taskDetails)
    if (taskDetails && taskDetails.taskId === selectedTask?.taskId) {
      setSelectedTask(taskDetails);
    }
    // eslint-disable-next-line
  }, [taskDetails])

  const batchesLoadMoreHandler = () => {
    if (batchesData.length < totalRows) {
      dispatch(batchList(businessUnitId, filter, false));
    }
  }

  const placesLoadMoreHandler = () => {
    if (batchesData.length < totalRows) {
      dispatch(batchList(businessUnitId, filter, false));
    }
  }

  const onAddHandler = () => {
    navigate("/batches/" + businessUnitId + "/create");
  }

  const batchSelectHandler = (item) => {
    console.log('batch: %o', item);
    const { batchId } = item;
    setDrawerOpen(true);
    dispatch(setBatchDetails(item));
    dispatch(batchGet({ batchId }))
  }

  const resources = useMemo(() => {
    return extractResourcesFromPlacesList(placesData);
  }, [placesData]);

  const onBatchMouseOverHandler = (item) => {
    setHighLightResources(Object.keys(item?.processedData?.resources || {}));
  }

  const onTasksClickHandler = ({ batchId, stepId }) => {
    console.log('onTasksClickHandler: %o', { batchId, stepId })
    dispatch(batchListTasks({ batchId, stepId }));
    setTaskModal(true);
  }

  const onTaskSelectHandler = (task) => {
    setTaskModal(false);
    setSelectedTask(task);
    setModalDetails(true);
    dispatch(taskGet({ taskId: task.taskId }))
  }

  const onMetricsClickHandler = (batchDetails) => {
    console.log('11')
    setModalMetrics(true);
  }

  const onActivityClickHandler = (batchDetails) => {
    console.log('22')
    setModalActivity(true);
    dispatch(batchActivity(batchDetails?.batchId, activityFilter, true));
  }

  return (
    <Page
      // permission={"promoter.actions.batches.list"}
      header={<PageTitle
        title={t("batches.BatchesList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            {/* <Permission code={"promoter.actions.batches.create"}> */}
            <Grid item>
              <Button variant="outlined" size="large" onClick={onAddHandler} disabled={loading} id="add">
                <AddIcon fontSize="inherit" />
                <Typography>{t("batches.BatchesList.header.btnAdd")}</Typography>
              </Button>
            </Grid>
            {/* </Permission> */}
          </Grid>
        }
      />}>
      <Stack spacing={2}>
        <div className={classes.links}>
          <ToggleButtonGroup color="primary" size="small" value="batches">
            <ToggleButton color="primary" value="batches">
              {t("batches.BatchesList.header.batchesLink")}
            </ToggleButton>
            <ToggleButton color="primary" value="orders" onClick={() => navigate('/batches/' + businessUnitId + '/orders')}>
              {t("batches.BatchesList.header.batchesOrdersLink")}
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={9}>
              <Paper className={classes.paper}>
                <Stack>
                  <Typography variant="h4" style={{ marginBottom: 8 }}>{t("batches.BatchesList.header.titleBatches")}</Typography>
                  <InfiniteList style={{ height: "calc(100vh - 257px)" }} isLoading={loading} data={batchesData} loadMore={batchesLoadMoreHandler} renderElement={(row) => (
                    <BatchListItem key={row.batchId} item={row} onClick={batchSelectHandler} resources={resources} onMouseOver={onBatchMouseOverHandler} onTasksClick={onTasksClickHandler} />
                  )} />
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper className={classes.paper}>
                <Stack>
                  <Typography variant="h4" style={{ marginBottom: 0 }}>{t("batches.BatchesList.header.titlePlaces")}</Typography>
                  <Divider />
                  <InfiniteList style={{ height: "calc(100vh - 250px)" }} isLoading={loading} data={sortList(placesData, "placeName")} loadMore={placesLoadMoreHandler} renderElement={(row) => (
                    <PlaceListItem item={row} highLightResources={highLightResources} />
                  )} />
                </Stack>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </Stack>
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.drawer}>
          <BatchDrawerDetails loading={loading} resources={resources} onTasksClick={onTasksClickHandler}
            onMetricsClick={onMetricsClickHandler}
            onActivityClick={onActivityClickHandler} />
        </div>
      </Drawer>
      <BatchTaskListModal
        open={taskModal}
        setOpen={setTaskModal}
        onTaskClick={onTaskSelectHandler}
      />
      <TaskDetailsModal open={modalDetails} setOpen={setModalDetails} task={selectedTask} />
      <BatchMetricsModal open={modalMetrics} setOpen={setModalMetrics} />
      <BatchActivityModal open={modalActivity} setOpen={setModalActivity} />
    </Page>
  );
}

export default withStyles(BatchesList, styles);

export const extractResourcesFromPlacesList = (array) => {
  // console.log('extractResourcesFromPlacesList: %o', array);
  let resources = [];

  function traverse(node) {
    if (node.resources && Array.isArray(node.resources)) {
      node.resources.forEach(resource => {
        resources.push(resource);
        traverse(resource);
      });
    }
    if (node.places && Array.isArray(node.places)) {
      node.places.forEach(traverse);
    }
  }

  array.forEach(traverse);
  return resources;
}
