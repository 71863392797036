import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Grid, Button } from "@mui/material";
import { withStyles } from "tss-react/mui";
import arrayMutators from "final-form-arrays";

import { styles } from "./ProductForm.styles";
import {
  draftFormSchema,
  formDocumentsFields,
  formFields,
  formHierarchyFields,
  formManagementFields,
  formMeasuresFields,
  formOrderFields,
  formSchema,
  formStorageFields,
  formSustainabilityFields,
  formTaxesFields,
  formUnitsFields,
} from "./ProductForm.schema";
import { useUser, usePromoter, useNavigate } from "../../../common/hooks";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import { isGtinValid, mergeObjects } from "../../../common/utils";
import { entityGet, selectEntityDetails, setEntityDetails } from "../../entities/entitiesSlice";
import { Form, FormArrayFields, FormFields } from "../../../common/forms";
import Permission from "../../../app/components/Permission";
import {
  selectLoader,
  selectProductBase,
  selectProductDetails,
  productCreate,
  productUpdate,
  productGet,
  productGetBase,
  formProductListFamily,
  selectFormFamilyList,
  productDraft,
  setProductBase,
  setProductDetails,
  productGroupContacts,
  selectGroupContacts,
  setCheckInternalCodeResult,
  selectProductBasePendingHierarchy,
} from "../productsSlice";
import { useFirstLoad } from "../../../common/hooks/useFirstLoad";
import FormErrors from "../../../common/forms/FormErrors/FormErrors";
import FormSection from "../../../common/displays/FormSection/FormSection";
import ScrollNav from "../../../common/displays/ScrollNav/ScrollNav";
import { getVatOptions } from "./ProductForm.options";
import Page from "../../../common/displays/Page/Page";
import CreateInitStep from "./CreateInitStep/CreateInitStep";
import ProductCustomForms from "./ProductCustomForms/ProductCustomForms";

const defaultProduct = {
  baseInfo: {
    active: false,
    available: "O",
    addressId: undefined,
  },
  management: {
    hasBatchNumber: false,
    hasExpirationDate: false,
    hasVariableWeight: false,
    hasSerialNumber: false,
  },
  units: {
    isBaseUnit: false,
    isDispatchUnit: false,
    isConsumerUnit: false,
    unitDescriptor: "BASE_UNIT_OR_EACH",
    //transportUnit: "BASE_UNIT_OR_EACH",
    consumerUnit: "BASE_UNIT_OR_EACH",
  },
  weightMeasure: {
    hasVariableDimensions: false,
    height: { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
    width: { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
    depth: { category: "COMP", value: 0, unit: "CMT", valueMax: 0 },
    netWeight: { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
    grossWeight: { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
    netContent: { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
    conversionFactor: { category: "MASS", value: 1, unit: "KGM", valueMax: 0 },
  },
  orders: {
    minQuantity: 1,
    maxQuantity: 10000,
    quantityMultiple: 1,
    tax: [],
  },
  storage: {},
  sustainability: [],
};

function ProductForm({ classes, copy }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useUser();
  const promoter = usePromoter();
  const loading = useSelector(selectLoader);
  const details = useSelector(selectProductDetails);
  const productBase = useSelector(selectProductBase);
  const entity = useSelector(selectEntityDetails);
  const families = useSelector(selectFormFamilyList);
  const groupContacts = useSelector(selectGroupContacts);
  const [isDraft, setIsDraft] = useState(false);
  const pendingHierarchy = useSelector(selectProductBasePendingHierarchy);
  const [initStep, setInitStep] = useState(false);

  const [productObject, setProductObject] = useState();

  useFirstLoad(() => {
    dispatch(formProductListFamily());
    dispatch(productGroupContacts());
    dispatch(setCheckInternalCodeResult());
    if (id) {
      // UPDATE
      if (details?.id !== id) {
        dispatch(productGet(id));
      }
    } else {
      // CREATE
      dispatch(setProductBase(null));
      dispatch(setProductDetails(null));
      setInitStep(true);
    }
    return () => {
      dispatch(setEntityDetails());
    }
  });

  useEffect(() => {
    if (!id) {
      if (productBase) {
        let product = mergeObjects(productObject, productBase);
        if (!productBase.media) {
          product.media = undefined;
          product.logo = undefined;
        }
        setProductObject(product);
      } else {
        setProductObject({
          ...defaultProduct,
          baseInfo: {
            ...defaultProduct.baseInfo,
            gln: (user?.entity?.type !== "P" && user?.entity?.gln) ? user?.entity?.gln : undefined,
            ownerId: (user?.entity?.type !== "P" && user?.entity?.id) ? user?.entity?.id : undefined,
            ownerName: (user?.entity?.type !== "P" && user?.entity?.name) ? user?.entity?.name : undefined,
          },
        });
      }
    }
    // eslint-disable-next-line
  }, [productBase]);

  useEffect(() => {
    if (details && id && details.id === id) {
      setProductObject(mergeObjects(defaultProduct, details));
      if (details.baseInfo && details.baseInfo.ownerId) {
        dispatch(entityGet(details.baseInfo.ownerId));
      }
      if (
        details.baseInfo.gtin &&
        isGtinValid(details.baseInfo.gtin) &&
        details.baseInfo.gln &&
        details.status !== "DFT" &&
        !copy
      ) {
        dispatch(productGetBase(details.baseInfo.gtin, details.baseInfo.gln, true));
      }
    }
    // ON CREATE ONLY
    else if (!id && !entity && user?.entity?.type !== "P") {
      dispatch(entityGet(user?.entity?.id));
    }
    // eslint-disable-next-line
  }, [details, id]);

  const vatOptions = useMemo(() => {
    return getVatOptions(t, promoter);
    // eslint-disable-next-line
  }, [promoter]);

  // const showGlnAndGtin = Boolean((user?.entity?.type !== "P" && user?.entity?.gln) || (user?.entity?.type === "P" && entity?.gln));
  const showGlnAndGtin = id ? !productObject?.baseInfo?.internalGtin : Boolean(productObject?.baseInfo?.gtin);

  const onSubmit = async (values) => {
    let zeros = "00000000000000";
    let gtinParsed = values.baseInfo.gtin;
    if (gtinParsed?.length < 14) {
      gtinParsed = zeros.substring(gtinParsed.length) + gtinParsed;
    }
    if (copy && values.baseInfo.internalGtin) {
      gtinParsed = undefined;
    }
    let parsedProductTemplate = typeof values.baseInfo.productTemplate === 'object' ? values.baseInfo.productTemplate?.value : values.baseInfo.productTemplate;
    // remove taxes that have value 0
    let otherTaxes = (values.taxes?.other || []).filter(item => parseFloat(item.value) > 0);
    let nValues = {
      ...values,
      baseInfo: {
        ...values.baseInfo,
        gln: values.baseInfo.gln || entity?.taxNumber,
        gtin: gtinParsed,
        productTemplate: parsedProductTemplate,
      },
      taxes: { ...values.taxes, other: otherTaxes },
      storage: {
        ...values.storage,
        lifecycle: values.lifecycle // lifecycle was moved to root to allow dependency validation from productClass
      }
    }
    if (!isDraft) {
      if (!id || details?.status === "DFT" || copy) {
        dispatch(productCreate(nValues));
      } else {
        dispatch(productUpdate({ ...nValues, id }, details));
      }
    } else {
      dispatch(productDraft({ ...nValues, id: copy ? undefined : id }));
    }
  };

  const onFamilyChangeHandler = (familyId) => {
    const selectedFamily = families.find((item) => item.id === familyId);
    setProductObject({
      ...productObject,
      storage: {
        ...productObject?.storage,
        transportTemperature: selectedFamily?.type,
        storageTemperature: selectedFamily?.type,
      },
    });
  };

  const onInitStepSubmitHandler = (values) => {
    let nValues = mergeObjects(productObject, values);
    setProductObject(nValues);
    setInitStep(false);
    if (user?.entity?.type === "P") {
      dispatch(entityGet(values.baseInfo?.ownerId));
    }
  }

  const initialValues = useMemo(() => {
    let ownerId = user?.entity?.type === "P" || productObject?.baseInfo?.ownerId
      ? productObject?.baseInfo?.ownerId
      : user?.entity?.id;
    let ownerName = user?.entity?.type === "P" || productObject?.baseInfo?.ownerId
      ? productObject?.baseInfo?.ownerName
      : user?.entity?.name;

    let nObj = {
      ...productObject,
      baseInfo: {
        ...productObject?.baseInfo,
        description: copy ? t("products.ProductCreate.form.copyOfPrefix") + " " + productObject?.baseInfo?.description : productObject?.baseInfo?.description,
        addressId: entity?.addresses?.length === 1 ? entity?.addresses[0].id : productObject?.baseInfo?.addressId,
        ownerId,
        ownerName,
        productTemplate: productObject?.baseInfo?.productTemplate ? { value: productObject?.baseInfo?.productTemplate, valid: true } : {},
        gln: (user?.entity?.type !== "P" && !id && user?.entity?.gln) ? user?.entity?.gln : productObject?.baseInfo?.gln,
        promoterInternalCode: copy ? null : productObject?.baseInfo?.promoterInternalCode,
        promoterDescription: copy ? null : productObject?.baseInfo?.promoterDescription,
        promoterGtin: copy ? null : productObject?.baseInfo?.promoterGtin,
      },
      lifecycle: productObject?.storage?.lifecycle, // lifecycle was moved to root to allow dependency validation from productClass
      hierarchy: copy ? [] : productObject?.hierarchy,
    };
    return nObj;
    // eslint-disable-next-line
  }, [entity, user, productObject]);

  const mandotoryDocs = Object.keys(promoter?.getConfig("product", "documentTypes") || {}).filter(item => promoter?.getConfig("product", "documentTypes")?.[item] === true);

  return (initStep && !id) ? <CreateInitStep onSubmit={onInitStepSubmitHandler} initialValues={productObject} entity={entity} /> : (
    <div>
      {productObject && (
        <Form
          onSubmit={onSubmit}
          schema={isDraft ? draftFormSchema(t) : formSchema({ t, promoter, entity, user, showGlnAndGtin, groupContacts, edit: Boolean(id) && initialValues?.status !== "DFT" && !copy, externalSource: productObject?.externalSource, copy, transactionUnit: productObject.transactionUnit, transportUnit: productObject.transportUnit })}
          mutators={{
            ...arrayMutators,
          }}
          keepDirtyOnReinitialize
          initialValues={initialValues}
          render={({ values, submitFailed, errors, form }) => {
            return (
              <Page
                permission="products.actions.product.get"
                header={<PageTitle
                  title={
                    id && !copy
                      ? t("products.ProductCreate.header.editTitle") + " " + initialValues?.baseInfo?.description
                      : t("products.ProductCreate.header.title")
                  }
                  onBack={() => Boolean(id) ? navigate("/products/" + id) : setInitStep(true)}
                  actions={
                    <Grid container direction="row" spacing={2} alignItems="center">
                      {(!id || details?.status === "DFT" || copy) && (
                        <Permission code="products.actions.product.draft">
                          <Grid item>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="large"
                              type="submit"
                              onClick={() => {
                                setIsDraft(true);
                              }}
                              disabled={loading}
                            >
                              {t("products.ProductCreate.header.btnSaveDraft")}
                            </Button>
                          </Grid>
                        </Permission>
                      )}
                      {submitFailed && Object.keys(errors)?.length > 0 && (
                        <Grid item>
                          <FormErrors errors={errors} />
                        </Grid>
                      )}

                      <Permission code={!id || details?.status === "DFT" ? "products.actions.product.create" : "products.actions.product.update"}>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                            onClick={() => {
                              setIsDraft(false);
                            }}
                            disabled={loading}
                          >
                            {t("products.ProductCreate.header.btnSave")}
                          </Button>
                        </Grid>
                      </Permission>
                    </Grid>
                  }
                />}>

                <ScrollNav
                  tabs={[
                    {
                      id: "generalSection",
                      title: t("products.ProductCreate.form.generalTitle"),
                      error: Object.keys(errors).includes("baseInfo"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.generalTitle")}>
                          <FormFields
                            fields={formFields({
                              t,
                              user,
                              promoter,
                              edit: Boolean(id),
                              entity,
                              families,
                              loading,
                              onFamilyChange: onFamilyChangeHandler,
                              productBase,
                              values,
                              groupContacts,
                              showGlnAndGtin,
                              externalSource: productObject?.externalSource,
                              copy
                            })}
                          />
                        </FormSection>
                      ),
                    },
                    {
                      id: "formsSection",
                      title: t("products.ProductCreate.form.customFormsTitle"),
                      error: Object.keys(errors).includes("forms"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.customFormsTitle")}>
                          <ProductCustomForms product={values} />
                        </FormSection>
                      ),
                      hidden: productObject?.externalSource || (promoter?.getConfig("product", "listCustomForms") || []).length === 0
                    },
                    {
                      id: "unitsSection",
                      title: t("products.ProductCreate.form.unitsTitle"),
                      error: Object.keys(errors).includes("units"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.unitsTitle")}>
                          <FormFields
                            fields={formUnitsFields({
                              t,
                              promoter,
                              values,
                              loading,
                              productBase,
                              user
                            })}
                          />
                        </FormSection>
                      ),
                    },
                    {
                      id: "measuresSection",
                      title: t("products.ProductCreate.form.measuresTitle"),
                      error: Object.keys(errors).includes("weightMeasure"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.measuresTitle")}>
                          <FormFields
                            fields={formMeasuresFields({
                              t,
                              values,
                              loading,
                              productBase,
                              promoter,
                              entity,
                              user
                            })}
                          />
                        </FormSection>
                      ),
                    },
                    {
                      id: "orderSection",
                      title: t("products.ProductCreate.form.orderTitle"),
                      error: Object.keys(errors).includes("orders"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.orderTitle")}>
                          <FormFields
                            fields={formOrderFields({
                              t,
                              loading,
                              values,
                              productBase,
                            })}
                          />
                        </FormSection>
                      ),
                    },
                    {
                      id: "taxesSection",
                      title: t("products.ProductCreate.form.taxesTitle"),
                      error: Object.keys(errors).includes("taxes"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.taxesTitle")}>
                          <FormFields
                            fields={formTaxesFields({
                              t,
                              loading,
                              values,
                              productBase,
                              vatOptions,
                            })}
                          />
                        </FormSection>
                      ),
                    },
                    {
                      id: "managementSection",
                      title: t("products.ProductCreate.form.managementTitle"),
                      error: Object.keys(errors).includes("management"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.managementTitle")}>
                          <FormFields
                            fields={formManagementFields({
                              t,
                              promoter,
                              loading,
                              values,
                              productBase,
                            })}
                          />
                        </FormSection>
                      ),
                      hidden: !["hasBatchNumber", "hasExpirationDate", "hasVariableWeight", "hasSerialNumber"].some(name => (promoter?.getConfig("product", "listProductFields") || []).includes(name))
                    },
                    {
                      id: "storageSection",
                      title: t("products.ProductCreate.form.storageTitle"),
                      error: Object.keys(errors).includes("storage") || Object.keys(errors).includes("lifecycle"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.storageTitle")}>
                          <FormFields
                            fields={formStorageFields({
                              t,
                              promoter,
                              loading,
                              values,
                              productBase,
                            })}
                          />
                        </FormSection>
                      ),
                    },
                    {
                      id: "documentsSection",
                      title: t("products.ProductCreate.form.documentsTitle"),
                      error: Object.keys(errors).includes("documents"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.documentsTitle") + (mandotoryDocs?.length > 0 ? " *" : "")} id="documentsSection">
                          <FormFields
                            fields={formDocumentsFields({
                              promoter,
                              loading,
                            })}
                          />
                        </FormSection>
                      ),
                    },
                    {
                      id: "hierarchySection",
                      title: t("products.ProductCreate.form.hierarchyTitle"),
                      error: Object.keys(errors).includes("hierarchy"),
                      content: (
                        <FormSection title={t("products.ProductCreate.form.hierarchyTitle") + " *"} id="hierarchySection">
                          <FormFields
                            fields={formHierarchyFields({
                              loading,
                              values,
                              showGlnAndGtin,
                              pendingHierarchy,
                              form
                            })}
                          />
                        </FormSection>
                      ),
                    },
                    {
                      id: "sustainabilitySection",
                      title: t("products.ProductCreate.form.sustainabilityTitle"),
                      error: Object.keys(errors).includes("sustainability"),
                      content: (
                        <FormSection
                          title={t("products.ProductCreate.form.sustainabilityTitle")}
                          id="sustainabilitySection"
                        >
                          <FormArrayFields
                            name="sustainability"
                            formFields={formSustainabilityFields({ t, loading, values, promoter, })}
                            defaultItem={{
                              packagingWeight: { category: "MASS", value: 0, unit: "GRM", valueMax: 0 },
                            }}
                            max={5}
                          />
                        </FormSection>
                      ),
                    },
                  ]}
                />

              </Page>
            );
          }}
        />
      )}
    </div>
  );
}

export default withStyles(ProductForm, styles);
