
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack, ToggleButtonGroup, ToggleButton, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./TasksList.styles";
import TasksDataGrid from "./TasksDataGrid/TasksDataGrid";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Permission from "../../../app/components/Permission";
import {
  selectTaskDetails,
  selectTaskList, selectTaskListCommentsFilter,
  selectTaskListFilter,
  selectTaskListTotalRows,
  selectTaskListWeekFilter,
  taskGet,
  taskList, taskListComments,
} from "../tasksSlice";
import { selectLoader } from "../../../app/coreSlice";
import TaskDetailsModal from "./TaskDetailsModal/TaskDetailsModal";
import { useNavigate } from "../../../common/hooks";
import SearchBox from "../../../common/components/inputs/SearchBox";
import TasksWeeklyView from "./TasksWeeklyView/TasksWeeklyView";
import moment from "moment";

function TasksList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectTaskList);
  const totalRows = useSelector(selectTaskListTotalRows);
  const filter = useSelector(selectTaskListFilter);
  const weekFilter = useSelector(selectTaskListWeekFilter);
  const filterListComments = useSelector(selectTaskListCommentsFilter);
  const loading = useSelector(selectLoader);
  const [modalDetails, setModalDetails] = useState(false);
  const [selectedTask, setSelectedTask] = useState();
  const taskDetails = useSelector(selectTaskDetails);
  const [tab, setTab] = useState(0);
  const [weekStartDate, setWeekStartDate] = useState(moment().startOf('isoWeek'));

  useEffect(() => {
    if (taskDetails && taskDetails.taskId === selectedTask?.taskId) {
      setSelectedTask(taskDetails);
    }
    // eslint-disable-next-line
  }, [taskDetails])

  const onAddHandler = () => {
    navigate("/tasks/create")
  }

  const onFilterChangeHandler = (v) => {
    dispatch(taskList({ ...filter, ...v.filter }, v.reset));
  }

  const onTaskSelectHandler = (row) => {
    setSelectedTask(row);
    dispatch(taskListComments({ ...filterListComments, taskId: row.taskId }, true));
    dispatch(taskGet({ taskId: row.taskId }))
    setModalDetails(true);
  }

  const onSearchHandler = (search) => {
    dispatch(taskList({ ...filter, search }, true));
  };

  const tabChangeHandler = (nTab) => {
    if (nTab === tab) {
      return;
    }
    if (nTab === 0) {
      // dispatch(taskList(filter, true));
    }
    if (nTab === 1) {
      dispatch(taskList({
        ...filter,
        ...weekFilter,
        startDate: weekStartDate.clone().startOf("day").toISOString(),
        endDate: weekStartDate.clone().startOf("day").add(7, "days").toISOString(),
      }, true));
    }
    setTab(nTab)
  }

  const setWeekStartDateHandler = (startDate) => {
    setWeekStartDate(startDate);
    dispatch(taskList({
      ...filter,
      ...weekFilter,
      startDate: startDate.clone().startOf("day").toISOString(),
      endDate: startDate.clone().startOf("day").add(7, "days").toISOString(),
    }, true));
  }

  return (
    <Page
      permission={"tasks.actions.tasks.list"}
      header={<PageTitle
        title={t("tasks.TasksList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Permission code={"tasks.actions.tasks.create"}>
              <Grid item>
                <SearchBox onEnter={(v) => onSearchHandler(v)} value={filter?.search} />
              </Grid>
              <Grid item>
                <Button variant="outlined" size="large" disabled={loading} onClick={onAddHandler} id="add"><AddIcon fontSize="inherit" /></Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={1} className={classes.root}>
        <div className={classes.menu}>
          <ToggleButtonGroup color="primary" size="small" value={tab} >
            <ToggleButton color="primary" value={0} onClick={() => tabChangeHandler(0)} >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={{ margin: 0 }}>
                  {t("tasks.TasksList.menu.list")}
                </Typography>
              </Stack>
            </ToggleButton>
            <ToggleButton color="primary" value={1} onClick={() => tabChangeHandler(1)} >
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={{ margin: 0 }}>
                  {t("tasks.TasksList.menu.weekly")}
                </Typography>
              </Stack>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {
          tab === 0 &&
          <TasksDataGrid
            data={data}
            totalRows={totalRows}
            onFilterChange={onFilterChangeHandler}
            filter={filter}
            onSelect={onTaskSelectHandler}
          />
        }
        {
          tab === 1 &&
          <TasksWeeklyView data={data} weekStartDate={weekStartDate} setWeekStartDate={setWeekStartDateHandler} onTaskClick={onTaskSelectHandler}/>
        }
        <TaskDetailsModal open={modalDetails} setOpen={setModalDetails} task={selectedTask} />
      </Stack>
    </Page>
  );
}

export default withStyles(TasksList, styles);