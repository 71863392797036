import React from "react";
import {Button, Grid, Tooltip} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useUser } from "../../../../common/hooks";
import DeleteButton from "../../../../common/components/dialogs/DeleteButton";
import Permission from "../../../../app/components/Permission";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectEntityDetails } from "../../entitiesSlice";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export default function UserListActions({ row, onEdit, onDelete }) {
  const { t } = useTranslation();
  const user = useUser();
  const { id } = useParams();
  const details = useSelector(selectEntityDetails);

  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      {row.toDisable && user?.entity?.type === "P" && (
          <Permission code="entities.actions.contact.disable">
            <Grid item>
              <DeleteButton
                labels={{
                  button: t("entities.Entities.confirmDeleteContact.button"),
                  title: t("entities.Entities.confirmDeleteContact.title"),
                  description: t("entities.Entities.confirmDeleteContact.description"),
                  ok: t("entities.Entities.confirmDeleteContact.ok"),
                  cancel: t("entities.Entities.confirmDeleteContact.cancel"),
                }}
                onConfirm={() => {
                  onDelete && onDelete(row);
                }}
              />
            </Grid>
          </Permission>
      )}
      {row.toDisable && (user?.entity?.type !== "P" || user?.entity?.id === id) &&

          <Grid item>
            <Tooltip title={t("entities.UsersList.columns.editWarn")}>
              <WarningAmberIcon style={{ display: "block" }} color="warning" />
            </Tooltip>
          </Grid>
      }
      {row?.roleType !== "A" && (user?.entity?.type === "P" || (user?.entity?.id === id && !row.toDisable)) && details?.status !== "PND" &&
        <Permission code="entities.actions.contact.update">
          <Grid item>
            <Button color="primary" onClick={() => onEdit && onEdit(row)}>
              {t("entities.UsersList.columns.edit")}
            </Button>
          </Grid>
        </Permission>
      }
    </Grid>
  );
}
