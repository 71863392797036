import { diffJson } from "diff";
import i18next from "i18next";
import moment from "moment";

export const guid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const sleep = (delay) => {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
};

export const getLocalStorage = (key) => {
  let value = localStorage.getItem(key);
  try {
    value = JSON.parse(value);
  } catch (ex) { }
  return value;
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getPromoterStorage = (key) => {
  let credentials = getLocalStorage("account-credentials");
  let nKey = `${credentials?.username}_${credentials?.promoter}_${process.env.REACT_APP_ENV}_${key}`;
  return getLocalStorage(nKey);
}

export const setPromoterStorage = (key, value) => {
  let credentials = getLocalStorage("account-credentials");
  console.log('credentials: %o', credentials);
  let nKey = `${credentials?.username}_${credentials?.promoter}_${process.env.REACT_APP_ENV}_${key}`;
  setLocalStorage(nKey, value);
};

export const asyncForEach = async (array, callback) => {
  for (let index = 0; index < (array || []).length; index++) {
    await callback(array[index], index, array);
  }
};

export const mergeObjects = (base, obj) => {
  let result = { ...base };
  for (let p in obj) {
    try {
      if (typeof obj[p] === "object" && !Array.isArray(obj[p])) {
        result[p] = mergeObjects(result[p], obj[p]);
      } else {
        result[p] = obj[p];
      }
    } catch (ex) {
      result[p] = obj[p];
    }
  }
  return result;
};

// replaces object in array with the same key
export const replaceObjectInArray = (array, object, key) => {
  if (!object) {
    return array;
  }
  let nArray;
  if (!array) {
    nArray = [];
  } else {
    nArray = [...array];
  }
  nArray = nArray.map((item) => {
    if (item[key] === object[key]) {
      return object;
    }
    return item;
  });
  return nArray;
};

// replaces object in array with the same key, or add object if not found
export const replaceOrAddObjectInArray = (array, object, key) => {
  if (!object) {
    return array;
  }
  let nArray;
  if (!array) {
    nArray = [];
  } else {
    nArray = [...array];
  }
  let found = false;
  nArray = nArray.map((item) => {
    if (item[key] === object[key]) {
      found = true;
      return object;
    }
    return item;
  });
  if (found) {
    return nArray;
  }
  return [...nArray, object];
};

export const mergeArraysByKey = (array1, array2, key) => {
  let nArray = array1 || [];
  (array2 || []).forEach((element) => {
    let origin = nArray.find((item) => item[key] === element[key]);
    if (origin) {
      nArray = replaceObjectInArray(nArray, { ...origin, ...element }, key);
    } else {
      nArray = [...nArray, element];
    }
  });
  return nArray;
};

export const leftMergeArraysByKey = (array1, array2, key) => {
  let nArray = array1 || [];
  (array2 || []).forEach((element) => {
    let origin = nArray.find((item) => item[key] === element[key]);
    if (origin) {
      nArray = replaceObjectInArray(nArray, { ...origin, ...element }, key);
    }
  });
  return nArray;
};

export const appendItemsToList = (array, items, append, key) => {
  if (!array) {
    array = [];
  }
  items.forEach(item => {
    const index = array.findIndex(existingItem => existingItem[key] === item[key]);
    if (index !== -1) {
      // If item exists, merge it
      array[index] = { ...array[index], ...item };
    } else {
      // If item doesn't exist, append/prepend it
      if (append) {
        array.push(item);
      } else {
        array.unshift(item);
      }
    }
  });
};

export const removeItemsFromList = (collection, arrayToRemove, key) => {
  let result = [];
  (collection || []).forEach(item => {
    if (!(arrayToRemove || []).some(itemToRemove => itemToRemove[key] === item[key])) {
      result.push(item)
    }
  })
  return result;
}

export const compareObjects = (obj1, obj2) => {
  let diff = diffJson(obj1, obj2);
  return diff?.length === 1 && !diff[0].added && !diff[0].removed;
  // let keys1 = Object.keys(obj1);
  // let keys2 = Object.keys(obj2);
  // if (keys1?.length !== keys2?.length) {
  //   return false;
  // }
  // for (let i = 0; i < keys1.length; i++) {
  //   let eq = false;
  //   if (Array.isArray(obj1[keys1[i]])) {
  //     eq = compareArrays(obj1[keys1[i]], obj2[keys1[i]]);
  //   } else if (typeof obj1[keys1[i]] === "object") {
  //     eq = compareObjects(obj1[keys1[i]], obj2[keys1[i]]);
  //   } else {
  //     eq = obj1[keys1[i]] === obj2[keys1[i]];
  //   }
  //   if (!eq) {
  //     return false;
  //   }
  // }
  // return true;
};

export const compareArrays = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    let eq = false;
    if (Array.isArray(arr1[i])) {
      eq = compareArrays(arr1[i], arr2[i]);
    } else if (typeof arr1[i] === "object") {
      eq = compareObjects(arr1[i], arr2[i]);
    } else {
      eq = arr1[i] === arr2[i];
    }
    if (!eq) {
      return false;
    }
  }
  return true;
};

export const listToTree = (list) => {
  let listAux = [];
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    listAux.push({ ...list[i], children: [] });
  }

  for (i = 0; i < listAux.length; i += 1) {
    node = listAux[i];
    if (node.parent) {
      // if you have dangling branches check that map[node.parentId] exists
      if (listAux[map[node.parent]]) {
        listAux[map[node.parent]].children.push(node);
      }
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const jsonTryParse = (jsonString) => {
  try {
    return JSON.parse(jsonString);
  } catch (ex) {
    return jsonString;
  }
};

export const classJoin = (arr) => {
  let filtered = (arr || []).filter((item) => item !== undefined);
  return filtered.join(" ");
};


export const multiLanguagePropToString = (obj, lang) => {
  if (!obj || Object.keys(obj).length === 0) {
    return "";
  }
  if (!obj.hasOwnProperty(lang) || obj[lang] === "") {
    const keys = Object.keys(obj);
    for (let key of keys) {
      if (obj[key] !== "") {
        return obj[key];
      }
    }
  }
  return obj[lang];
}

export const cleanUrlQueryString = (url) => {
  // Check if the URL contains a query string
  if (url.indexOf('?') !== -1) {
    // Remove the query string portion of the URL
    return url.split('?')[0];
  }

  // If there are no query strings, return the original URL
  return url;
}

export const zerosGtinParser = (gtin) => {
  if (gtin.length < 14) {
    gtin = "00000000000000".substring(gtin.length) + gtin;
  }
  return gtin;
}

// This function will replace the special characters with their corresponding escaped versions, making the string safer for JSON parsing.
export const sanitizeStringForJSON = (str) => {
  return str.replace(/[\\"\n\r\t\b\f]/g, (char) => {
    switch (char) {
      case '"':
      case '\\':
        return '\\' + char; // Escape double quote and backslash
      case '\n':
        return '\\n'; // Replace newline with \n
      case '\r':
        return '\\r'; // Replace carriage return with \r
      case '\t':
        return '\\t'; // Replace tab with \t
      case '\b':
        return '\\b'; // Replace backspace with \b
      case '\f':
        return '\\f'; // Replace formfeed with \f
      default:
        return char; // Return the character itself
    }
  });
}

export const dataTranslation = (dataItem) => {
  if (!dataItem) {
    return "";
  }
  if (typeof dataItem === "string") {
    dataItem = JSON.parse(dataItem);
  }
  let altLang = Object.keys(dataItem).filter((k) => k !== i18next.language)[0];
  return dataItem[i18next.language] || dataItem[altLang];
}

export const toggleItem = (array, item) => {
  let nArray = [...array];
  const index = array.indexOf(item);
  if (index > -1) {
    nArray.splice(index, 1);
  } else {
    nArray.push(item);
  }
  return nArray;
}

export const sortList = (array, field) => {
  let nArray = [...(array || [])];
  nArray.sort((a, b) => (a?.[field] || "").localeCompare((b?.[field] || "")));
  return nArray;
}

export const sortListByDate = (array, field) => {
  let nArray = [...(array || [])];
  nArray.sort((a, b) => moment(a?.[field]).isAfter(moment(b?.[field])) ? -1 : 1);
  return nArray;
}