export const styles = () => ({
  root: {
    "& .CdgRow": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: '#fdfdfd'
      }
    }
  },
  menu: {
    width: "100%",
    textAlign: "right",
    "& .MuiToggleButtonGroup-root": {
      backgroundColor: "#FFF"
    }
  },
});