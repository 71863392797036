import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {Button, Grid, IconButton, Stack, Tooltip} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import ActionsPopover from "../../../../common/components/ActionsPopover/ActionsPopover";
import Permission from "../../../../app/components/Permission";
import {useDispatch} from "react-redux";
import {productRemoveTax, removeTempProductTax} from "../../productsSlice";
import HistoryIcon from "@mui/icons-material/History";

export default function ProductTaxDataGridActions({ classes, row, validation, onOtherTaxAdd, onValidate, user, promoter, onHistory }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || row.promoterInternalCode;

  const otherTaxes = useMemo(() => {
    const selectedTaxes = (row?.tax?.other || [])
      .filter(item => item.code || (item.process?.status === "ACCEPTED" && !item.process?.removeDate) || item.process?.status === "PENDING")
      .map(item => item.code || item.process?.other?.code);

    return (promoter?.getConfig("tax", "allowTaxes") || [])
      .filter(taxType => taxType !== "VAT")
      .filter(taxType => !selectedTaxes.includes(taxType))
      .map((taxType) => ({
        text: t('common.Enums.taxGroup.' + taxType),
        value: taxType
      }))
    // eslint-disable-next-line
  }, [promoter, row])


  const onHistoryHandler = (e) => {
    e.preventDefault();
    onHistory && onHistory(row, 'VAT');
  };
  const onOtherTaxAddHandler = (e) => {
    e.preventDefault();
    onOtherTaxAdd && onOtherTaxAdd(row);
  };

  const setValidHandler = (value) => {
    onValidate && onValidate(row, value);
  }

  const onCancelTempOtherTaxHandler = (e) => {
    e.preventDefault();
    dispatch(removeTempProductTax({ productId: row.productId, code: row.tax.vat, isVat: true }))
  };

  const onRemoveOtherHandler = (e) => {
    e.preventDefault();
    dispatch(productRemoveTax({ productId: row.productId, code: row.ogTax?.process?.tax?.vat }));
  };

  let actionsButtons = [];

  // CANCEL TEMP
  if (row?.hasChanges) {
    actionsButtons.push(<Button onClick={onCancelTempOtherTaxHandler} color="error" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <DeleteIcon />
        </Grid>
        <Grid item>{t("products.ProductTaxList.actions.cancel")}</Grid>
      </Grid>
    </Button>);
  }
  // CANCEL PENDING
  if (row?.ogTax?.process?.status === "PENDING") {
    actionsButtons.push(<Button onClick={onRemoveOtherHandler} color="error" fullWidth>
      <Grid container spacing={1} alignItems="center">
        <Grid item style={{ display: "flex" }}>
          <DeleteIcon />
        </Grid>
        <Grid item>{t("products.ProductTaxList.actions.cancel")}</Grid>
      </Grid>
    </Button>);
  }
  if(user.hasPermission("products.actions.taxes.history")){
      actionsButtons.push(
          <Button onClick={onHistoryHandler} color="primary" fullWidth>
            <Grid container spacing={1} alignItems="center">
              <Grid item style={{ display: "flex" }}>
                <HistoryIcon />
              </Grid>
              <Grid item>{t("products.ProductTaxList.actions.history")}</Grid>
            </Grid>
          </Button>
    );
  }
  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" wrap="nowrap">
      {!validation && allowedToChange && otherTaxes?.length > 0 && <Permission code="products.actions.taxes.create">
        <Grid item>
          <IconButton size="small" onClick={onOtherTaxAddHandler}>
            <AddCircleOutlineIcon color="primary" />
          </IconButton>
        </Grid>
      </Permission>}
      {(row?.processes || []).some(item => item.status === "PENDING" && item.processType === "FlowRemovePromoDiscount") &&
        <Grid item>
          <div style={{ margin: 6, display: "flex", justifyContent: "flex-end" }}>
            <Tooltip title={t("products.ProductTaxList.columns.pendingRemove")}>
              <ReportProblemOutlinedIcon style={{ display: "block" }} color="warning" fontSize="small" />
            </Tooltip>
          </div>
        </Grid>
      }

      {!validation && actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }
      {validation && row?.tax?.process?.status === "PENDING" && row?.tax?.process?.waitingAction &&
        <Permission code="products.actions.taxes.action">
          <Grid item>
            <Stack direction="row" spacing={0} >
              <IconButton onClick={() => setValidHandler(false)}>
                <CancelOutlinedIcon color={row.valid === false ? "error" : "default"} />
              </IconButton>
              <IconButton onClick={() => setValidHandler(true)}>
                <CheckCircleOutlineIcon color={row.valid === true ? "success" : "default"} />
              </IconButton>
            </Stack>
          </Grid>
        </Permission>
      }

    </Grid>
  );
}
