import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Grid, Tooltip} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import ActionsPopover from "../../../../../common/components/ActionsPopover/ActionsPopover";
import {usePromoter, useUser} from "../../../../../common/hooks";
import HistoryIcon from "@mui/icons-material/History";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {dateFormat} from "../../../../../common/utils";

export default function ExtraPricesVariantsDataGridActions({ row, onRemove, product, onHistoryHandle }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const user = useUser();
  const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || product.promoterInternalCode;

  let actionsButtons = [];

  const onRemoveHandler = (e) => {
    e.preventDefault();
    onRemove && onRemove({ variant: row, product });
  }

  if(user.hasPermission("products.actions.price.history") && (row?.extraPrice?.prevPrice || row?.extraPrice?.removeDate )){
    actionsButtons.push(

          <Button onClick={() => onHistoryHandle(product, row)} color="primary" fullWidth>
            <Grid container spacing={1} alignItems="center">
              <Grid item style={{ display: "flex" }}>
                <HistoryIcon />
              </Grid>
              <Grid item>{t("productsPrices.PricesList.actions.history")}</Grid>
            </Grid>
          </Button>
    );
  }

  // REMOVE
  if (user.hasPermission("products.actions.price.remove") && allowedToChange && row?.extraPrice && !row?.extraPrice?.removeDate) {
    actionsButtons.push(
      <Button onClick={onRemoveHandler} color="error" fullWidth>
        <Grid container spacing={1} alignItems="center">
          <Grid item style={{ display: "flex" }}>
            <DeleteIcon />
          </Grid>
          <Grid item>{t("productsPrices.PricesList.actions.remove")}</Grid>
        </Grid>
      </Button>
    );
  }
 
  return (
    <Grid container spacing={1} justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
      { row?.extraPrice?.removeDate &&
        <Grid item>
          <div style={{ margin: 6, display: "flex", justifyContent: "flex-end" }}>
            <Tooltip title={`${t("productsPrices.PricesList.columns.removeDate")} ${dateFormat(row?.extraPrice?.removeDate)}`}>
              <ReportProblemOutlinedIcon style={{ display: "block" }} color="warning" fontSize="small" />
            </Tooltip>
          </div>
        </Grid>
      }
      {actionsButtons.length > 0 &&
        <Grid item>
          <ActionsPopover
            actions={actionsButtons}
          />
        </Grid>
      }
    </Grid>
  );
}
