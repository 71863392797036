const batches = {
  flowsMessages: {
    success: {
      BATCH_CREATE: "Batch created successfully!",
      BATCH_CREATE_INTERNAL_ORDER: "Order created successfully!",
      BATCH_INTERNAL_CHANGE_STATUS: "Batch status changed successfully!",
      INTERNAL_TRANSFER: "Transfer requested.",
    },
    error: {
      GENERIC: "Something went wrong",
      BATCH_ALREADY_EXISTS: "A batch with this number already exists"
    },
  },
  BatchesList: {
    header: {
      title: "Batches",
      btnAdd: "Create Batch",
      titleBatches: "Batches",
      titlePlaces: "Places",
      batchesLink: "Batches",
      batchesOrdersLink: "Orders",
    },
    details: {
      btnMetrics: "Metrics",
      btnActivity: "Activity"
    }
  },
  BatchesOrdersList: {
    header: {
      title: "Orders",
      titleOrders: "Orders",
      titleInternalBatches: "Internal Batches"
    }
  },
  BatchForm: {
    header: {
      title: "Create new batch",
    },
    form: {
      btnSubmit: "Save",
      productIdRequired: "Product is required",
      startDateRequired: "Start date is required",
      batchNumberRequired: "Batch number is required",
      product: "Product",
      productEmpty: "No product selected",
      productError: "Invalid product",
      startDate: "Start date",
      batchNumber: "Batch Number",
      description: "Description",
      batchOrders: "Orders",
      btnCreateInternalOrder: "Create Internal Order",
    }
  },
  CreateInternalOrderModal: {
    modal: {
      title: "Create Internal Order"
    },
    form: {
      btnSubmit: "Submit",
      productIdRequired: "Product is required",
      dateRequired: "Date is required",
      quantityRequired: "Quantity is required",
      product: "Product",
      productEmpty: "No product selected",
      productError: "Invalid product",
      date: "Date",
      quantity: "Quantity",
    }
  },
  BatchTaskListModal: {
    modal: {
      title: "Batch Tasks"
    }
  }
}

export default batches;