import React, { useState } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { styles } from "./ResourceGroupField.styles";
import { useForm, useFormState } from "react-final-form";
import { getTaskInputField } from "../../../_common/auxiliars";
import ResourceSelectorModal from "./ResourceSelectorModal/ResourceSelectorModal";

function ResourceGroupField({ classes, input, preview, fields, disabled, resources, places, task }) {
  const { t, i18n } = useTranslation();
  const form = useForm();
  const state = useFormState();
  const [modal, setModal] = useState(false);
  const resourcesPayload = state?.values?.payload?.resources || {};

  const onAddResourceHandler = (values) => {
    setModal(false);
    if (!Object.keys(resourcesPayload).some(resourceId => resourceId === values.resource)) {
      form.change("payload.resources", { ...(resourcesPayload), [values?.resource]: { isNew: true } });
    }
  }

  const selectedResources = resources.filter(item => Object.keys(resourcesPayload).some(resourceId => resourceId === item.id))
    .map(resource => ({
      ...resource,
      quantity: parseInt(resourcesPayload[resource.id]?.quantity) || 0,
      isNew: Boolean(resourcesPayload[resource.id]?.isNew)
    })).sort((a, b) => {
      if (a.isNew === b.isNew) {
        return b.quantity - a.quantity; // Sort by quantity descending
      }
      return a.isNew - b.isNew; // Sort by isNew (false comes first)
    })

  const availableResources = (resources || []).filter(item => item.type === input.resourceType && !(selectedResources || []).map(obj => obj.id).includes(item.id));

  return (
    <div className={classes.root}>
      <Stack spacing={1}>
        {preview ?
          <div>
            <div>
              <Typography className={classes.resourceTitle}>{t("common.Enums.resourceTypes." + input.resourceType)}</Typography>
            </div>
            <Stack spacing={1} className={classes.resource} >
              {
                (fields || []).map((field, index) => (<div key={"resource_" + index}>{getTaskInputField({ i18n, input: field, disabled, id: `payload.resources.example`, resources, places, task })}</div>))
              }
            </Stack>
          </div>
          :
          (selectedResources).map(item => (
            <div key={"resource_" + item.id}>
              <div>
                <Typography className={classes.resourceTitle}>{(item.parentName || item.placeName) + " | " + item.title}</Typography>
              </div>
              <Stack spacing={1} className={classes.resource} >
                {
                  (fields || []).map((field, index) => (<div key={"resource_" + item.id + "_" + index}>{getTaskInputField({ i18n, input: field, disabled, id: `payload.resources.${item.id}`, resources, places, task, resourceId: item.id })}</div>))
                }
              </Stack>
            </div>
          ))
        }
        {
          !preview && availableResources.length > 0 && !disabled && <IconButton size="small" className={classes.circleBtn} onClick={() => setModal(true)}>
            <AddIcon fontSize="small" />
          </IconButton>
        }
      </Stack>
      <br />
      <ResourceSelectorModal open={modal} setOpen={setModal} onSubmit={onAddResourceHandler} resources={availableResources} />
    </div>
  );
}

export default withStyles(ResourceGroupField, styles);