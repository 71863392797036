

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const batchListTasksCall = async (
  { setBatchTasksList },
  dispatch,
  { batchId, stepId }
) => {
  let rh = requestHelper(dispatch, "BATCH_LIST_TASKS");

  try {
    let result = await call({
      type: "BATCH",
      subType: "LIST_TASKS",
      request: {
        batchId, 
        stepId
      },
    });
    dispatch(setBatchTasksList(result.items));
    
  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchListTasksCall;