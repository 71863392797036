const promoter = {
  flowsMessages: {
    success: {
      PROMOTER_UPDATE: "promoter updated successfully!",
      PROMOTER_CREATE_VAT: "Vat created successfully!",
      PROMOTER_DISABLE_VAT: "Vat disabled successfully!",
      PROMOTER_ENABLE_VAT: "Vat enabled successfully!",
      PROMOTER_UPDATE_VAT: "Vat updated successfully!",
      PROMOTER_CREATE_LOCATION: "Location created successfully!",
      PROMOTER_UPDATE_LOCATION: "Location updated successfully!",
      PROMOTER_DELETE_LOCATION: "Location deleted successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
      WORKFLOW_VALIDATION_ERROR: "Invalid Request!",
      VAT_ALREADY_EXISTS: "This vat code is already defined for some of the selected locations"
    },
  },
  PromoterConfigs: {
    tabs: {
      admin: "System",
      general: "General",
      contact: "Contact",
      cutoff: "CutOff",
      order: "Order",
      price: "Price",
      product: "Product",
      home: "Home",
      document: "Document",
      notification: "Notifications",
      taxes: "Taxes",
      entity: "Entity",
    },
    form: {
      title: "Promoter Configurations",
      submitBtn: "Update",
      retentionBtn: "Audit Retention",
      defaultCurrency: "Default Currency",
      defaultLanguage: "Default Language",
      entityTypes: "Entity Types",
      roleTypes: "Role Types",
      placeTypes: "Place Types",
      resourceTypes: "Resource Types",
      website: "Website",
      email: "email",
      notifications: "notifications",
      userNotificationName: "notificationName",
      userNotificationEmail: "notificationEmail",
      cutoff: "cutoff",
      reminder: "reminder",
      reminderOneDayBefore: "reminderOneDayBefore",
      remindertwoDaysBefore: "remindertwoDaysBefore",
      cutoffType: "cutoffType",
      cutoffDayOfWeek: "cutoffDayOfWeek",
      cutoffDeliveryDay: "cutoffDeliveryDay",
      cutoffHour: "cutoffHour",
      cutoffMinute: "cutoffMinute",
      operationType: "operationType",
      trafficType: "trafficType",
      reportIncidentDeadline: "reportIncidentDeadline",
      minQuantityValue: "minQuantityValue",
      unitType: "unitType",
      hasLogisticOperator: "Has Logistic Operator",
      checkFinishedAccept: "Check Finished Accept",
      allowClientInput: "allowClientInput",
      allowPriceDiscount: "allowPriceDiscount",
      autoAcceptPrice: "autoAcceptPrice",
      cmpAccumulation: "Campaign Accumulation",
      cmpAccumulationNote: "Prevents creation of multiple campaign for the same product on the same period",
      cpAccumulation: "Price Accumulation",
      cpAccumulationNote: "If the product has more than one campaign they will accumulate",
      defaultType: "defaultType",
      numberQuantityRange: "numberQuantityRange",
      allowedEntityTypes: "Entity can have produts",
      minimumQuantityUnitTypes: "minimumQuantityUnitTypes",
      allowedSegments: "allowedSegments",
      adminAppApi: "Application Api",
      adminAppApiEnable: "Enable",
      adminAppApiKey: "Api Key",
      adminAppApiProductsApiAvailable: "Enable Products Api",
      adminTitle: "General",
      adminLogo: "Logo",
      adminLanguages: "Languages",
      adminEmailTitle: "Email configurations",
      adminEmailHost: "Host",
      adminEmailUsername: "Username",
      adminEmailPassword: "Password",
      adminEmailPort: "Port",
      adminEmailFrom: "From",
      adminFileStorageTitle: "File Storage Configurations",
      adminFileStorageProvider: "Provider",
      adminFileStorageRegion: "Region",
      adminFileStorageKey: "Key",
      adminFileStorageSecret: "Secret",
      adminFileStorageTempBucket: "Temp Bucket",
      adminFileStoragePrivateBucket: "Private Bucket",
      adminFileStoragePublicBucket: "Public Bucket",
      adminCatalogApiTitle: "Catalog Api Configurations",
      adminCatalogApiKey: "Key",
      adminCatalogApiCallback: "Callback Key",
      adminCatalogApiId: "Id",
      adminCatalogApiProductCategory: "Product Category",
      adminGoogleApiTitle: "Google Api Configurations",
      adminGoogleApiKey: "Key",
      importTemplatePrice: "Template Price",
      importTemplateProduct: "Template Product",
      importTemplateOrder: "Template Order",
      importTemplatePricePlaceholder: "Upload Price Template",
      importTemplateProductPlaceholder: "Upload Product Template",
      importTemplateOrderPlaceholder: "Upload Order Template",
      locationsBtn: "Locations",
      taxsBtn: "Vats",
      cuttOffAutomatic: "Automatic",
      hasDashboard: "Has Dashboard",
      hasFlyer: "Has flyer",
      flyer: "Flyer",
      flyerPreview: "Preview",
      hasHighlights: "Has highlights",
      highlights: "Highlights",
      docMetadata: "Documents metadata",
      productDocumentType: "Product Document Types",
      notificationAcceptPrice: "Accept Price",
      notificationProcessPrice: "Process Price",
      notificationAlert: "Alert",
      notificationEmail: "Email",
      notificationDepartment: "Department",
      listProductColumns: "Product List Columns",
      notificationTitleProducts: "Products",
      notificationTitlePrices: "Prices",
      notificationAcceptProduct: "Product Acceptance",
      notificationValidateProduct: "Product Validation",
      productAutoAcceptUpdate: "Auto Accept Update",
      productAutoAcceptUpdateExternal: "Auto Accept Update External",
      productAutoAcceptCreate: "Auto Accept Create",
      notificationAcceptValidateProductOwner: "Product Owner Acceptance and Validation",
      phoneNumber: "Phone Number",
      mobileNumber: "Mobile Number",
      faxNumber: "Fax Number",
      listProductFields: "Product extra fields",
      notificationTitleEntities: "Entities",
      checkDocExpEntity: "Check document expiration",
      daysBeforeExpireEntity: "Days before expiration",
      daysBeforeTaskDueDate: "Days before expiration",
      checkDocExpProduct: "Check document expiration",
      daysBeforeExpireProduct: "Days before expiration",
      allowGroupPrice: "Allow Group Price",
      checkPurchase: "Check Purchase",
      checkPurchaseProposal: "Check Purchase Proposal",
      processPurchase: "Process Purchase",
      checkTask: "Check Task",
      processTask: "Process Task",
      checkTaskDueDate: "Check task due date",
      orderAllowedEntityTypes: "Allowed Entity Types",
      allowTaxes: "Allowed Taxes",
      allowLocationPrice: "Allow Location Price",
      orderDocumentType: "Order Document Types",
      autoAcceptPurchase: "Auto Accept Purchase",
      productAllowFamilyCode: "Allow Family Codes",
      adminExternalProvider: "External Provider",
      adminExternalEnable: "Enable",
      adminExternalBaseUrl: "Base Url",
      adminExternalBasicAuth: "Basic Authentication",
      adminExternalUsername: "Username",
      adminExternalPassword: "Password",
      adminExternalEntityUseDataFromExternal: "Use entity data from external provider",
      adminExternalEntitySendDataToExternal: "Send entity data to external provider",
      adminExternalProductUseDataFromExternal: "Use product data from external provider",
      adminExternalProductSendDataToExternal: "Send product data to external provider",
      entityAutoCreate: "Allow auto create",
      entityAutoUpdate: "Allow auto update",
      entityWsListEntitiesByCatalog: "WS Entities by Catalog",
      entityRequiresInternalCode: "Requires internal code",
      entityAllowUpdateInternalCode: "Allow update internal code",
      entityDocumentType: "Document types",
      entityListEntityColumns: "List entity columns",
      adminExternalType: "Service Type",
      productMandatoryImage: "Image mandatory",
      acceptEntity: "Accept Entity",
      validateEntity: "Validate Entity",
      acceptValidateEntity: "Accept Validate Entity",
      notificationTitleProductPurchases: "Product Purchases",
      notificationTitleTasks: "Tasks",
      notificationAcceptPromoDiscount: "Accept Promotional Discount",
      notificationProcessPromoDiscount: "Processing Promotional Discount",
      autoAcceptPromoDiscount: "Auto Accept Promotional Discount",
      autoAcceptRemovePromoDiscount: "Auto Accept Promotional Discount Remove",
      baseUrl: "Base Url",
      requiredWebsite: "Website is required",
      includeHttps: "Must include https://",
      adminExternalProductSendDataSheetToExternal: "Send technical sheet to external provider",
      dataSheetServiceBaseUrl: "Base Url",
      dataSheetServiceApiKey: "Api Key",
      customForms: "Custom forms",
      dataSheetServiceType: "Data Sheet Type",
      daysBeforeExpirePromoDiscount: "Days Before Expire Promotional Discount",
      notificationCheckPromoDiscountExp: "Check Promotional Discount Expired",
      portalName: "Portal Name",
      priceListCreationTypes: "Allowed price types",
      allowedExtraPriceTypes: "Extra Price Types",
      allowPricePromotions: "Allow price promotions",
      allowPriceCampaigns: "Allow price campaigns",
      allowedExtraPriceTypesNote: "Separate price types using a comma, like type1, type2, type3",
      allowExtraPriceStartDate: "Allow Extra Price StartDate",
      allowExtraPriceVariants: "Allow Extra Price Variants",
      allowRetailPrice: "Allow retail price",
      autoAcceptCreateTax: "Auto Accept Create Tax",
      autoAcceptRemoveTax: "Auto Accept Remove Tax",
      acceptTax: "Accept Tax",
      processTax: "Process Tax",
      productRequiresInternalCode: "Requires Internal Code",
      productAllowAllUnits: "Allow All Units",
      allowStandard: "Allow standard price",
      adminFtp:"FTP Configurations",
      adminFtpEnable:"Enable",
      ftpHost:"Host",
      ftpPort:"Port",
      ftpUser:"Username",
      ftpPassword:"Password",
      adminFtpSecureFTP:"Secure FTP",
      adminFtpProductSendDataToExternal:"Send product data to external provider",
    },
    retention: {
      title: "Audit Retention",
      editBtn: "Edit",
      columns_tableName: "Table Name",
      columns_numberDays: "Number of Days",
      columns_active: "Active",
      statusTrue: "true",
      statusFalse: "false",
      columns_createdBy: "Created By",
      columns_createdTime: "Created Time",
      columns_updatedBy: "Upload By",
      columns_updatedTime: "Upload Time",
      form_requiredNumberDays: "Number of days is Required",
      form_numberDays: "Number of days",
      form_tableName: "Table Name",
      dialogTitle: "Audit Retention",
      dialogDescription: "Change the number of days (data retention)",
      dialogOk: "Submit",
      dialogCancel: "Cancel",
    },
    serviceType: {
      clear: "Clear",
      sogenave: "Sogenave"
    }
  },
  MetadataField: {
    labels: {
      required: "Required",
      metadata: "Metadata",
    },
  },
  DocumentTypeField: {
    labels: {
      documentType: "Document Type",
      required: "Required",
    },
  },
  RefDataTaxsList: {
    header: {
      title: "Vats",
      addBtn: "Add New Vat",
      filterBtn: "Filter",
    },
    filter: {
      locations: "Locations",
      active: "Show only active vats"
    },
    columns: {
      code: "Code",
      locations: "Locations",
      value: "Value",
      active: "Active",
      deleteBtn: "Disable",
      activateBtn: "Enable",
      editBtn: "Edit",
    },
    active: {
      true: "Active",
      false: "Inactive",
    },
    deleteConfirm: {
      title: "Are you sure?",
      description: "You can re-enable it later",
      ok: "Ok",
      cancel: "Cancel",
    },
  },
  RefDataTaxsForm: {
    form: {
      title: "Add new vat",
      titleUpdate: "Update vat",
      submitBtn: "Save",
      requiredCode: "Code is required",
      requiredValue: "Value is required",
      code: "Code",
      locations: "Locations",
      value: "Value",
    },
  },
  LocationsList: {
    header: {
      title: "Locations",
      addBtn: "Add location"
    },
    columns: {
      name: "Name",
      states: "States",
      code: "Code",
      deleteBtn: "Delete",
      editBtn: "Edit",
    },
    deleteConfirm: {
      title: "Are you sure?",
      description: "This action is not reversible",
      ok: "Ok",
      cancel: "Cancel",
    }
  },
  LocationsForm: {
    header: {
      title: "Create new location",
      updateTitle: "Update location:",
      submitBtn: "Submit"
    },
    form: {
      name: "Name",
      states: "States",
      code: "Code",
      requiredName: "Name is required"
    }
  },
  timer: {
    header: {
      title: "Timer List",
      addBtn: "Add",
    },
    filter: {
      description: "Description",
      subType: "SubType",
      status: "Status",
      optionAll: "All",
      optionActive: "Active",
      optionInactive: "Inactive",
    },
    timerList:{
      columns_description: "Description",
      columns_dayOfMonth: "Day of Month",
      columns_dayOfWeek: "Day of Week",
      columns_time: "Time",
      columns_active: "Status",
      columns_active_true: "Active",
      columns_active_false: "Inactive",
    },
    details: {
      detail: "Detail",
      btnEdit: "Edit",
      btnEnable: "Enable",
      btnDisable: "Disable",
      description: "Description",
      type: "Type",
      subtype: "Subtype",
      status: "Status",
      userRequest: "User Request",
      dayOfMonth: "Day of Month",
      dayOfWeek: "Day of Week",
      hour: "Hour",
      minute: "Minute",
      createdBy: "Created By",
      createdTime: "Created Time",
      updatedBy: "Updated By",
      updatedTime: "Updated Time",
      request: "Request",
    }
  },
  systemStatus: {
    header: {
      title: "System Status",
      refreshAll: "Refresh All",
    },
    detail: {
      pod: "Pod",
      cache: "Cache",
      bpm: "BPM",
      dbBigdata: "Database Big Data",
      dbCoreReadNode: "Database Core Read Node",
      dbCoreWrite: "Database Core Write",
      dbCoreRead: "Database Core Read",
      catalog: "Catalog",
      catalog_db: "Catalog Database",
      catalog_diskSpace: "Catalog Disk Space",
      requestTime: "Request In",
      responseTime: "Response Time (ms)",
      true: "Ok",
      false: "Error",
      undefined: "N/D",
    }
  },
  TimerForm: {
    header: {
      title: "Create new timer",
      updateTitle: "Update timer:",
      submitBtn: "Submit"
    },
    form: {
      description: "Description",
      type: "Type",
      subtype: "Subtype",
      status: "Status",
      userRequest: "User Request",
      dayOfMonth: "Day of Month",
      dayOfWeek: "Day of Week",
      hour: "Hour",
      minute: "Minute",
      time: "Time",
      frequency: "Frequency type",
      request: "Request",
      requiredDescription: "Description is required",
      requiredFrequency: "Frequency is required",
      requiredType: "Type is required",
      requiredSubtype: "Subtype is required",
      requiredUserRequest: "User Request is required",
      requiredTime: "Time is required",
    }
  },
};

export default promoter;
