
import { Chip, Grid, IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import { CurrencyInput } from "../../../../../common/inputs/custom";
import PriceOscilation from "../../../_common/PriceOscilation/PriceOscilation";
import CustomTooltip from "../../../../../common/displays/CustomTooltip/CustomTooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { dateFormat } from "../../../../../common/utils";
import moment from "moment";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PricePromotions from "../PricePromotions/PricePromotions";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Currency from "../../../../../common/displays/Currency/Currency";
import CampaignTooltip from "../../../../orders/_common/CampaignTooltip/CampaignTooltip";
import { getEntitiesInGroups } from "../../../_common/auxiliars";

export function listColumns({ t, promoter, user, product, validation, entityGroups, locations, onVariantPriceChange, onAddPromotion, onEditPromotion, comparerView, onPriceComparerOpen, onVariantRetailPriceChange }) {
  let columns = [];
  columns.push({
    field: "priceType",
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return t("common.Enums.priceType." + v.priceType)
    }
  });
  columns.push({
    field: "extraInfo",
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      if (v.priceType === "G") {
        let group = (entityGroups || []).find(item => v.groupEntity === item.groupId || v.groupId === item.groupId);
        return <Chip label={group?.groupName || v?.groupName} size="small" />
      }
      if (v.priceType === "D" || v.priceType === "W") {
        let locationsObjs = (locations || []).filter(item => (v.locations || []).includes(item.id));
        return (
          <Grid container direction="row" spacing={1} flexWrap="wrap">
            {locationsObjs.map(loc => <Grid item key={loc.id} ><Chip label={loc.name} size="small" /></Grid>)}
          </Grid>
        );
      }
      return "";
    }
  });
  columns.push({
    field: "date",
    disableSort: true,
    align: "center",
    width: 200,
    customValue: (v) => {
      let progressPercent = 0;
      if (v.expireDate && moment(v.effectiveDate).isBefore(moment())) {
        progressPercent = moment().diff(moment(v.effectiveDate), "days") / moment(v.expireDate).diff(moment(v.effectiveDate), "days") * 100;
        if (progressPercent > 100) {
          progressPercent = 100;
        }
      }
      return <Stack style={{ width: 180 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography style={{ margin: 0 }}>{dateFormat(v.effectiveDate)}</Typography>
          {v.expireDate && <ArrowRightIcon />}
          {v.expireDate && <Typography style={{ margin: 0 }}>{dateFormat(v.expireDate)}</Typography>}
        </Stack>
        <LinearProgress variant="determinate" color={moment(v.effectiveDate).isSameOrAfter(moment()) ? "inherit" : "primary"} value={progressPercent} />
      </Stack>
    }
  });

  if (promoter?.getConfig("price", "allowPricePromotions")) {
    columns.push({
      field: "promotions",
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || product.promoterInternalCode;
        const { process } = v;
        return (
          <Stack direction="row" alignItems="center">
            {v.promotions && <PricePromotions locations={locations} price={v} pending={validation || process?.status === "PENDING"} onEdit={(promotion) => allowedToChange && onEditPromotion(product, v, promotion)} />}
            {!comparerView && !validation && process?.status !== "PENDING" && v?.status !== "END" && v?.status !== "REM" && v?.status !== "REF" && (v.promotions || [])?.length < 3 && promoter?.getConfig("price", "allowPricePromotions") && allowedToChange &&
              <IconButton size="small" onClick={() => onAddPromotion(product, v)}>
                <AddCircleOutlineIcon color="default" />
              </IconButton>
            }
          </Stack>
        );
      }
    });
  }

  if (promoter?.getConfig("price", "allowPriceCampaigns")) {
    columns.push({
      field: "campaigns",
      headerName: t("productsPrices.PricesList.columns.campaigns"),
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        let availableCampaigns = (v.campaigns || []).filter((item) => item.active && (user?.isPromoter || getEntitiesInGroups(entityGroups, item.groups).includes(user?.entity?.id)));
        // should show all campaigns to promoter, or if the user entity is part of the groups associated or created the campaign

        return <Stack spacing={1} direction="row">
          {availableCampaigns.map((item, index) => (
            <div item key={`campaign_${index}`}>
              <CampaignTooltip campaign={item} forceHighlight groups={(entityGroups || []).filter(entityGroup => (item.groups || []).some(group => group === entityGroup.groupId))} />
            </div>
          ))}
        </Stack>
      }
    });
  }

  if (!comparerView) {
    columns.push({
      field: "comment",
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        const { process, comment } = v;
        return comment ? <CustomTooltip title={comment} placement="left">
          <InfoOutlinedIcon style={{ display: "block" }} color={process?.status === "REFUSED" ? "error" : "info"} />
        </CustomTooltip> : ""
      }
    });
  }
  columns.push({
    field: "salePrice",
    disableSort: true,
    align: "right",
    width: comparerView ? 60 : 180,
    customValue: (v) => {
      const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || product.promoterInternalCode;
      const { productId } = product;
      const { priceId, salePrice, priceType, process, prevSalePrice } = v;
      if (comparerView || v.status === "END" || v.status === "REM") {
        return <Currency value={salePrice} />
      }
      if (validation) {
        if (process?.status === "PENDING") {
          return <PriceOscilation
            oldPrice={salePrice}
            newPrice={process?.newSalePrice}
            showComparer={process?.status === "PENDING" && process?.processType !== "FlowRemovePrice"}
            removePending={process?.status === "PENDING" && process?.processType === "FlowRemovePrice"}
            removeDate={process?.price?.removeDate}
            onPriceComparerOpen={() => onPriceComparerOpen(product, v)}
          />;
        }
        if (process?.status === "ACCEPTED") {
          return <PriceOscilation oldPrice={prevSalePrice} newPrice={salePrice} />;
        }
        if (process?.status === "REFUSED") {
          return <PriceOscilation oldPrice={salePrice} newPrice={process?.newSalePrice} rejected />;
        }
        return <Currency value={salePrice} />
      }
      else {
        // price List only show oscilation if pending
        // if (process?.status === "PENDING" && !user?.isPromoter) { - removed this exeption, so is the same behaviour for promoter or owner, to change needs to cancel the pending process
        if (process?.status === "PENDING") {
          // OSCILATION
          return <PriceOscilation
            oldPrice={salePrice}
            newPrice={process?.newSalePrice}
            removePending={process?.status === "PENDING" && process?.processType === "FlowRemovePrice"}
            removeDate={process?.price?.removeDate}
          />
        }
      }
      return <CurrencyInput
        value={(v?.newSalePrice || salePrice || 0).toString()}
        disabled={!allowedToChange}
        onChange={(newSalePrice) => onVariantPriceChange({ productId, priceId, newSalePrice, priceType })}
      />
    }
  });

  if (promoter?.getConfig("price", "allowRetailPrice")) {
    columns.push({
      field: "retailPrice",
      disableSort: true,
      align: "right",
      width: comparerView ? 60 : 180,
      customValue: (v) => {
        const allowedToChange = user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || product.promoterInternalCode;
        const { productId } = product;
        const { priceId, retailPrice, priceType, process, prevRetailPrice } = v;
        if (comparerView || v.status === "END") {
          return <Currency value={retailPrice} />
        }
        if (validation) {
          if (process?.status === "PENDING") {
            return <PriceOscilation oldPrice={retailPrice} newPrice={process?.newRetailPrice} />;
          }
          if (process?.status === "ACCEPTED") {
            return <PriceOscilation oldPrice={prevRetailPrice} newPrice={retailPrice} />;
          }
          if (process?.status === "REFUSED") {
            return <PriceOscilation oldPrice={retailPrice} newPrice={process?.newRetailPrice} rejected />;
          }
          return <Currency value={retailPrice} />
        }
        else {
          // price List only show oscilation if pending
          if (process?.status === "PENDING") {
            // OSCILATION
            return <PriceOscilation oldPrice={retailPrice} newPrice={process?.newRetailPrice} />
          }
        }
        return <CurrencyInput
          value={(v?.newRetailPrice || retailPrice || 0).toString()}
          disabled={!allowedToChange}
          onChange={(newRetailPrice) => onVariantRetailPriceChange({ productId, priceId, newRetailPrice, priceType })}
        />
      }
    });
  }

  return columns;
}
