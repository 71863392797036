import { processImageUrl } from "../../../api/s3";
import { addAlertToList } from "../../../features/alerts/alertsSlice";
import { processHierarchy } from "../../../features/products/_sliceCalls/_auxiliars";
import {
  appendToProductTaxList,
  insertItemIntoPendingProductList,
  setProductBasePendingHierarchy,
  setProductDetails,
  updateProductBaseHierarchy
} from "../../../features/products/productsSlice";
import { prependToDpromsList, productDpromList } from "../../../features/productsDproms/productsDpromsSlice";
import { prependToPricesList, productPriceList } from "../../../features/productsPrices/productsPricesSlice";
import { insertItemIntoPurchasesList } from "../../../features/productsPurchases/productsPurchasesSlice";
import { getPromoterConfig, refreshUserAvatar, setLoggedUser, setSelectedPromoter } from "../../coreSlice";
import { setConnecting } from "../websocketSlice";
import { appendToTaskList, taskGet, taskList } from "../../../features/tasks/tasksSlice";
import i18next from "i18next";

export const notificationSubscriptionHandlers = (body, dispatch) => {
  const { type, subType, processMessage } = body;
  if (process.env.REACT_APP_ENV !== "production") {
    console.log("%c <<< NOTIFICATION SUB [%s/%s] %o", "color:#9302ed; font-weight:bold", type, subType, processMessage);
  }

  switch (type + "/" + subType) {
    case "USER/DETAILS":
      dispatch(updateUserDetails(processMessage));
      break;
    case "ALERT/CREATE":
      dispatch(addAlertToList(processMessage));
      break;
    case "PRODUCT/GET_BASE":
      dispatch(updateBaseHierarchy(processMessage?.hierarchy));
      break;
    case "PRODUCT/CREATE":
    case "PRODUCT/PENDING_PRODUCT_ACTION":
    case "PRODUCT/RECOVER":
    case "PRODUCT/UPDATE":
      dispatch(updatePendingProductList(processMessage));
      dispatch(updateProductDetails(processMessage));
      break;
    case "PRODUCT/PENDING_PURCHASE_ACTION":
    case "PRODUCT/REQUEST_PURCHASE":
      dispatch(updatePurchasesList(processMessage));
      break;
    case "PRODUCT/DPROM_ACTION":
    case "PRODUCT/DPROM_CREATE":
      dispatch(updateDpromsList(processMessage));
      break;
    case "PRODUCT/DPROM_REMOVE":
      dispatch(dpromRemoveNotification(processMessage));
      break;
    case "PRODUCT/PENDING_PRICE_ACTION":
    case "PRODUCT/CREATE_PRICE":
      dispatch(updatePricesList(processMessage));
      break;
    case "PRODUCT/REMOVE_PRICE":
      dispatch(priceRemoveNotification(processMessage));
      break;
    case "PRODUCT/CREATE_TAX":
    case "PRODUCT/PENDING_TAX_ACTION":
      dispatch(taxCreateNotification(processMessage));
      break;
    case "PRODUCT/REMOVE_TAX":
      dispatch(taxRemoveNotification(processMessage));
      break;
    case "TASK/CREATE":
      dispatch(taskCreateNotification(processMessage));
      break;
    case "TASK/ACTION":
    case "TASK/ASSIGN":
      dispatch(taskActionNotification(processMessage));
      break;
    default:
      break;
  }
};

const updateUserDetails = (processMessage) => async (dispatch) => {
  dispatch(setLoggedUser(processMessage));
  if (processMessage?.preferences?.language && i18next.language !== processMessage?.preferences?.language) {
    console.log('LANGUAGE CHANGED TO: %s', processMessage?.preferences?.language);
    i18next.changeLanguage(processMessage?.preferences?.language);
  }
  dispatch(setConnecting(false));
  dispatch(refreshUserAvatar());
  dispatch(setSelectedPromoter(processMessage?.promoterSelected));
}

const updatePricesList = (processMessage) =>
  async (dispatch) => {
    dispatch(prependToPricesList((processMessage?.items || [])));
  };

const updateDpromsList = (processMessage) =>
  async (dispatch) => {
    let nStatus;
    if (processMessage.processType === "FlowRemovePromoDiscount" && processMessage.status === "ACCEPTED") {
      nStatus = "REM"
    }
    dispatch(prependToDpromsList((processMessage?.items || []).map(item => ({ ...item, status: nStatus || item.status, processes: [{ ...processMessage, items: undefined }] }))));
  };

const dpromRemoveNotification = () => async (dispatch, getState) => {
  let filter = getState().productsDproms.dpromsListFilter;
  dispatch(productDpromList(filter, true));
}

const updatePurchasesList = (productProcess) =>
  async (dispatch, getState) => {
    const state = getState();
    const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
    if (productProcess) {
      dispatch(insertItemIntoPurchasesList({ ...productProcess, thumbnail: await processImageUrl(fileStorageConfigs, productProcess.thumbnail) }));
    }
  };

const updateBaseHierarchy = (hierarchy) =>
  async (dispatch, getState) => {
    let productBase = getState().products.productBase;
    let nHierarchy = await processHierarchy(productBase, hierarchy);
    dispatch(updateProductBaseHierarchy(nHierarchy));
    dispatch(setProductBasePendingHierarchy(false));
  };

const updatePendingProductList = (productProcess) =>
  async (dispatch, getState) => {
    const state = getState();
    const fileStorageConfigs = getPromoterConfig(state, "admin", "fileStorage");
    const loader = state.core.loader;
    const hasListCaller = loader.some(item => item.call === "PRODUCT_PENDING_PRODUCT_LIST");
    if (productProcess && !hasListCaller) {
      dispatch(insertItemIntoPendingProductList({ ...productProcess, thumbnail: await processImageUrl(fileStorageConfigs, productProcess.thumbnail) }));
    }
  };

const productStatusMapper = {
  "PENDING": "PND",
  "VALIDATING": "VAL",
  "ACCEPTED": "ACC",
  "REFUSED": "REF",
  "NOT_VALIDATED": "NVL",
  "DRAFT": "DFT",
  "RECOVERED": "VAL",
}

const updateProductDetails = (productProcess) =>
  async (dispatch, getState) => {
    let product = getState().products.productDetails;
    if (product && product.id === productProcess.productId) {
      dispatch(setProductDetails({ ...product, status: productStatusMapper[productProcess.status] }));
    }
  };

const priceRemoveNotification = () => async (dispatch, getState) => {
  let filter = getState().productsPrices.pricesListFilter;
  dispatch(productPriceList(filter, true));
}

const taxCreateNotification = (processMessage) => async (dispatch) => {
  dispatch(appendToProductTaxList(processMessage.items));
}

const taxRemoveNotification = (processMessage) => async (dispatch) => {
  dispatch(appendToProductTaxList(processMessage.items));
}

const taskActionNotification = (processMessage) =>
  async (dispatch, getState) => {
    console.log('taskActionNotification: %o', processMessage);
    // dispatch(appendToTaskList(processMessage?.task));

    // let filter = getState().tasks.taskListFilter;
    // dispatch(taskList(filter, true));
    // dispatch(taskGet({ taskId: processMessage?.taskId }));
  };

  const taskCreateNotification = (processMessage) =>
    async (dispatch) => {
      dispatch(appendToTaskList(processMessage?.items));
    };