import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

import { styles } from "./BatchMetricsModal.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

function BatchMetricsModal({ classes, open, setOpen }) {
  const { t } = useTranslation();

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={205} title={t("batches.BatchMetricsModal.modal.title")}>
      <Stack spacing={2}>
        <Typography>BATCH METRICS</Typography>
      </Stack>
    </CustomModal>
  );
}

export default withStyles(BatchMetricsModal, styles);
