import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper, setSuccess, setRedirect } from "../../../app/coreSlice";
import moment from "moment";
import { businessUnitProcessTaskBatches } from "../../businessUnits/businessUnitsSlice";

const batchCreateCall = async (
  dispatch,
  {
    batchNumber,
    productId,
    businessUnitId,
    type,
    startDate,
    description,
    batchOrders,
    taskInitializationOffset,
  }
) => {
  let rh = requestHelper(dispatch, "BATCH_CREATE");
  try {
    let result = await call({
      type: "BATCH",
      subType: "CREATE",
      request: {
        batchNumber,
        productId,
        businessUnitId,
        type,
        startDate,
        description,
        batchOrders,
      },
    });

    if (result) {
      let dateIni = moment(startDate).subtract(taskInitializationOffset, "days");
      if (dateIni.isSameOrBefore(moment())) {
        dispatch(businessUnitProcessTaskBatches({
          dateIni: dateIni.format("YYYY-MM-DD"),
          dateEnd: moment().format("YYYY-MM-DD"),
          batchId: result.id
        }))
      }

      setTimeout(() => {
        dispatch(setRedirect("/batches/" + businessUnitId));
        dispatch(setSuccess("batches", "BATCH_CREATE"));
      }, 300);
    }

  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchCreateCall;

