import { Client } from "@stomp/stompjs";
import SockJS from "sockjs-client";

class WebSocketService {
  init = (
    host,
    connectHeaders,
    { onConnect, onConnectError, onDisconnect, onWebsocketClose, onLog, onFailedLogin }
  ) => {
    let socket;
    let closeCounts = 0;

    const client = new Client({
      reconnectDelay: 7000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
      splitLargeFrames: true,
      connectHeaders,
   //   debug: (str) => process.env.REACT_APP_ENV !== "production" ? console.debug("%c WS - debug: %o","color:#949494",str) : "",
    });
    window.wsClient = client;

    client.webSocketFactory = () => {
      socket = new SockJS(host, null, {transports: ["websocket"]});
      return socket;
    };

    client.onConnect = (frame) => {
      closeCounts = 0;
      const sessionId = socket._transport.url.match(/ws.+\/(.+)\/websocket/);
      if (process.env.REACT_APP_ENV !== "production") {
        console.log('%c WebSocketService - onConnect: %o', "color:#949494", { socket, client, sessionId });
        console.log("%c WS - onConnect - sessionId: %o, %o", "color:#949494", sessionId?.[1], frame);
      }
      if (!sessionId || sessionId.length < 2) {
        onConnectError && onConnectError(new Error("Error while connecting to server. Please try again."));
        return;
      }
      onConnect && onConnect(sessionId[1]);
    };

    client.onDisconnect = (frame) => {
      if (process.env.REACT_APP_ENV !== "production") {
        console.log("%c WS - onDisconnect - %o", "color:#949494", frame);
      }
      onDisconnect && onDisconnect(frame);
    };

    client.onWebSocketClose = (event) => {
      closeCounts++;
      if (process.env.REACT_APP_ENV !== "production") {
        console.log("%c WS - onWebSocketClose[%s] - %o", "color:#949494", closeCounts, event);
      }
      onWebsocketClose && onWebsocketClose(closeCounts);
    };

    client.onStompError = (event) => {
      if (process.env.REACT_APP_ENV !== "production") {
        console.log("%c WS - onStompError - %o", "color:#949494", event);
      }
      if (
        event?.headers?.message === "USER_NOT_FOUND" ||
        event?.headers?.message === "FAILED_LOGIN" ||
        event?.headers?.message === "INCORRECT_LOGIN" ||
        event?.headers?.message === "TOO_MANY_ATTEMPTS"
      ) {
        client.deactivate();
        onFailedLogin && onFailedLogin(event?.headers?.message);
      }
    };

    // client.onChangeState = (arg1, arg2, arg3) => {
    //   console.log('--- onChangeState: %o', { arg1, arg2, arg3 })
    // }

    client.activate();

    this.client = client;
    this.onLog = onLog;
  };

  disconnect = () => {
    if (this.client.active) {
      this.client.deactivate();
    }
    return this.client.active;
  };

  publish = ({ destination, body }) => {
    this.client.publish({
      destination,
      body,
    });
  };

  subscribe = (destination, callback) => {
    return this.client.subscribe(destination, callback);
  };
}

export default WebSocketService;
