import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import moment from "moment";
import { useTranslation } from "react-i18next";

import { styles } from "./PriceExport.styles";

import { priceJsonToCsv } from "../../../_auxiliars";
import { usePromoter, useUser } from "../../../../../common/hooks";
import { selectLocations } from "../../../productsPricesSlice";
import { requestHelper, setError } from "../../../../../app/coreSlice";
import productPriceListComplete from "../../../_directCalls/productPriceListComplete";

function PriceExport({ classes, totalRows, filter }) {
  const { t } = useTranslation();
  const user = useUser();
  const dispatch = useDispatch();
  const promoter = usePromoter();
  const locations = useSelector(selectLocations);

  const onExportClickHandler = async () => {
    let rh = requestHelper(dispatch, "PRODUCT_PRICE_EXPORT");
    try {
      let data = await productPriceListComplete(promoter?.configs?.admin?.fileStorage, filter, totalRows);

      const result = priceJsonToCsv(
        t,
        data,
        "prices_" + moment().format("YYMMDDHHmm"),
        user?.isPromoter,
        locations,
        promoter?.getConfig("price", "allowRetailPrice"),
        promoter?.getConfig("price", "allowStandard"),
        promoter?.getConfig("price", "allowPricePromotions")
      );

      if (!result) {
        dispatch(setError("productsPrices", { tag: "NO_PRICES_TO_EXPORT" }));
      }
    } catch (ex) {
      rh.error("productsPrices", ex);
    }
    rh.close();
  }

  return (
    <div className={classes.root}>
      <Tooltip title={t("productsPrices.PricesList.header.tooltipExport")}>
        <IconButton id="priceExport" onClick={onExportClickHandler} size="small">
          <FileDownloadIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
}

export default withStyles(PriceExport, styles);