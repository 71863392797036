import * as Yup from "yup";
import { MultiSelectField, SelectField } from "../../../../common/forms";

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    status: Yup.array(),
  });
};

export const filterFields = ({ promoter, disabled }) => (t) => {
  return [
    {
      size: 12,
      field: (
        <SelectField
          id="active"
          disabled={disabled}
          label={t("places.PlaceList.filter.status")}
          options={[
            { value: null, text: t("places.PlaceList.filter.allStatusOption") },
            ...getStatusOptions(t)
          ]}
        />
      ),
    },
    {
      size: 12,
      field: (
        <MultiSelectField id="type" label={t("places.PlaceList.filter.type")} options={getTypeOptions(t, promoter)} disabled={disabled} />
      ),
    },
  ];
};


const getStatusOptions = (t) => {
  return [true, false].map((value) => {
    return {
      value,
      text: t("places.PlaceList.filter.status_" + value),
    };
  });
};

const getTypeOptions = (t, promoter) => {
  let placeTypesAllowed = promoter?.getConfig("general", "placeTypes") || [];
  return placeTypesAllowed.map((value) => {
    return {
      value: value,
      text: t("common.Enums.placeTypes." + value),
    };
  });
};
