import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";
import {Alert, Button, Grid, Stack} from "@mui/material";

import {styles} from "./ExtraPriceList.styles";
import ExtraPriceDataGrid from "./ExtraPriceDataGrid/ExtraPriceDataGrid";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import SearchBox from "../../../common/components/inputs/SearchBox";
import Permission from "../../../app/components/Permission";
import {selectLoader} from "../../../app/coreSlice";
import {mixExtraPricesWithTemp} from "../_sliceCalls/_auxiliars";
import {
  clearTempExtraPrices,
  entityListGroup_full,
  productExtraPriceList,
  productSetExtraPrice,
  productSetExtraPrice_remove,
  promoterListLocations_full,
  removeTempExtraPrice,
  selectExtraPriceList,
  selectExtraPriceListFilter,
  selectExtraPriceListTotalRows,
  selectTempExtraPrices,
  updateTempExtraPrice
} from "../productsPricesSlice";
import {useFirstLoad, usePromoter} from "../../../common/hooks";
import ExtraPriceRemoveModal from "./ExtraPriceRemoveModal/ExtraPriceRemoveModal";
import ExtraPriceHistoryModal from "./ExtraPriceHistory/ExtraPriceHistoryModal";

function ExtraPriceList({ classes }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const dispatch = useDispatch();
  const [removeModal, setRemoveModal] = useState(false);
  const [historyModal, setHistoryModal] = useState(false);
  const [productHistory, setProductHistory] = useState();
  const [priceHistory, setPriceHistory] = useState();
  const [priceToRemove, setPriceToRemove] = useState();
  const pricesList = useSelector(selectExtraPriceList);
  const totalRows = useSelector(selectExtraPriceListTotalRows);
  const filter = useSelector(selectExtraPriceListFilter);
  const loading = useSelector(selectLoader);
  const tempPrices = useSelector(selectTempExtraPrices);


  useFirstLoad(() => {
    if ((promoter?.getConfig("price", "listCreationTypes") || []).includes("G")) {
      dispatch(entityListGroup_full());
    }
    dispatch(promoterListLocations_full());
  });

  const hasChanges = useMemo(() => {
    return Object.keys(tempPrices).some(item => tempPrices[item].filter(item => item.newExtraPrice?.value > 0).length > 0)
  }, [tempPrices]);

  const data = useMemo(() => {
    return mixExtraPricesWithTemp(pricesList, tempPrices);
  }, [pricesList, tempPrices]);

  const onSubmitHandler = () => {
    dispatch(productSetExtraPrice());
  }

  const onFilterChangeHandler = (v) => {
    dispatch(productExtraPriceList({ ...filter, ...v.filter }, v.reset));
  }

  const onSearchHandler = (search) => {
    let nFilter = {
      ...filter,
      search,
      offset: 0,
    };

    dispatch(productExtraPriceList(nFilter, true));
  };

  const onExtraPriceChangeHandler = ({ productId, priceId, priceType, locations, newExtraPrice }) => {
    const product = data?.find(item => item.productId === productId);
    const priceObj = (product?.prices || []).find(item => item.priceType === "S") || {};
    if (parseFloat(priceObj?.extraPrice?.value) !== parseFloat(newExtraPrice.value) || priceObj?.extraPrice.type !== newExtraPrice.type || priceObj?.extraPrice.startDate !== newExtraPrice.startDate) {
      dispatch(updateTempExtraPrice({ productId, priceId, priceType, locations, newExtraPrice }));
    }
    else {
      dispatch(removeTempExtraPrice({ productId, priceId }));
    }
  }

  const onExtraPriceHistoryOpenHandler = (product, row) => {
    setProductHistory(product);
    setPriceHistory(row);
    setHistoryModal(true);
  }

  const onRemoveHandler = ({ variant, product }) => {
    //console.log('onRemoveHandler: %o', { variant, product });
    const { priceId, priceType, locations, extraPrice } = variant;
    const { productId } = product;
    setPriceToRemove({ priceId, productId, priceType, locations, extraPrice: { ...extraPrice } })
    setRemoveModal(true);
  }

  const onRemoveConfirmHandler = (removeDate) => {
    //console.log('onRemoveConfirmHandler: %o', { priceToRemove, removeDate });
    setRemoveModal(false);
    dispatch(productSetExtraPrice_remove({ ...priceToRemove, extraPrice: { ...priceToRemove.extraPrice, removeDate } }));

    // productId, priceId, extraPrice, locations, priceType

    // const { priceId, productId, ownerId, contactPoint, ownerName } = priceToRemove;
    // dispatch(updateTempExtraPrice({ productId, priceId, priceType, locations, newExtraPrice, removeDate }));
    // dispatch(productRemovePrice({ ...priceToRemove, removeDate }));
  }

  return (
    <Page
      permission={"products.actions.extraPrice.list"}
      header={<PageTitle
        title={t("productsPrices.ExtraPriceList.header.title")}
        info={`${totalRows} ${t("productsPrices.ExtraPriceList.header.rowsInfo")}`}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} value={filter?.search} />
            </Grid>
            <Permission code={"products.actions.extraPrice.update"}>
              <Grid item>
                <Button
                  variant="contained"
                  size="large"
                  onClick={onSubmitHandler}
                  disabled={loading || !hasChanges}
                >
                  {t("productsPrices.ExtraPriceList.header.btnSubmit")}
                </Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={1}>
        {hasChanges &&
          <Alert variant="outlined" severity="info" className={classes.alert}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              {t("productsPrices.ExtraPriceList.header.changesToSubmit")}
              <Button size="small" variant="text" color="info" disableRipple style={{ height: "auto", padding: 0 }} onClick={() => dispatch(clearTempExtraPrices())}>
                {t("productsPrices.ExtraPriceList.header.clearChanges")}
              </Button>
            </Stack>
          </Alert>
        }
        <ExtraPriceDataGrid
          data={data}
          totalRows={totalRows}
          filter={filter}
          onFilterChange={onFilterChangeHandler}
          onExtraPriceChange={onExtraPriceChangeHandler}
          onRemove={onRemoveHandler}
          onHistoryHandle={onExtraPriceHistoryOpenHandler}
        />

      </Stack>
      <ExtraPriceRemoveModal open={removeModal} setOpen={setRemoveModal} onRemoveConfirm={onRemoveConfirmHandler} />
      <ExtraPriceHistoryModal open={historyModal} setOpen={setHistoryModal} product={productHistory} row={priceHistory}  />

    </Page>
  );
}

export default withStyles(ExtraPriceList, styles);