export const productListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "OWNERNAME",
  sortType: "ASC",
  search: null,
  showDrafts: false,
  productFamily: [],
  contactPoint: null,
  owner: null,
  available: null
}

export const catalogListDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "NAME",
  sortType: "ASC",
  search: null,
  active: null,
  owner: null,
}

export const campaignsListDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "STATUS",
  sortType: "ASC",
  search: null,
  active: null,
  campaignType: [],
  groupId: null,
  startDate: null,
  endDate: null,
}

export const pendingProductsDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "PRODUCTACTIONDATE",
  sortType: "DESC",
  status: ["PENDING", "VALIDATING", "ACCEPTED", "REFUSED", "NOT_OK", "NOT_VALIDATED", "RECOVERED"],
  processTypes: ["FlowCreateProduct", "FlowUpdateProduct", "FlowRecoverProduct"],
  search: null,
  active: true,
}

export const pendingProductsDefaultFilterSupplier = {
  limit: 50,
  offset: 0,
  sortField: "PRODUCTACTIONDATE",
  sortType: "DESC",
  status: ["PENDING", "VALIDATING", "ACCEPTED", "REFUSED", "NOT_OK", "NOT_VALIDATED", "RECOVERED"],
  processTypes: ["FlowCreateProduct", "FlowUpdateProduct", "FlowRecoverProduct"],
  search: null,
  active: null,
}


export const productsPurchasesDefaultFilter = {
  limit: 25,
  offset: 0,
  sortField: "PURCHASEACTIONDATE",
  sortType: "DESC",
  status: ["PENDING", "PROPOSAL", "ACCEPTED", "EXECUTED", "REFUSED"],
}

export const productsDpromsDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: "DPROMACTIONDATE",
  sortType: "DESC",
  status: ["PND", "ACC", "REF", "CAN", "REM", "END"],
  owner: null,
  search: null,
}

export const productPickerListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "OWNERNAME",
  sortType: "ASC",
  search: null,
  showDrafts: false,
  productFamily: [],
  contactPoint: null,
  owner: null,
  justConsumer: true
}

export const priceListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "PRICEACTIONDATE",
  sortType: "DESC",
  search: null,
  productFamily: [],
  contactPoint: null,
  owner: null,
  status: []
}
export const priceHistoryListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: "ACTION_DATE",
  sortType: "DESC",
  productId: null,
  status: null,
  type: null,
  startDate: null,
  endDate: null,
}

export const productsTaxListDefaultFilter = {
  limit: 30,
  offset: 0,
  sortField: 'TAXACTIONDATE',
  sortType: 'DESC',
  search: null,
  tax: true,
  status: [],
  productFamily: [],
  contactPoint: null,
  owner: null
}
export const productsTaxHistoryListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: 'ACTION_DATE',
  sortType: 'DESC',
  productId: null,
  status: null,
  tax: null,
  startDate: null,
  endDate: null,
}
export const productDetailCommentsDefaultFilter = {
  limit: 10000,
  offset: 0,
  sortType: 'DESC',
  productId: null
}
export const productsHistoryListDefaultFilter = {
  limit: 50,
  offset: 0,
  sortField: 'PRODUCTACTIONDATE',
  sortType: 'DESC',
  businessId: null,
  processTypes: [ "FlowCreateProduct", "FlowUpdateProduct", "FlowRecoverProduct"],
  status: null,
  search: null,
  active: null,
}