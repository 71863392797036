const authentication = {
  Login: {
    form: {
      title: "Login to platform",
      subtitle: "Please enter your credentials to proceed.",
      email: "E-mail",
      password: "Password",
      submit: "Sign In",
      invalidEmail: "Invalid email",
      requiredEmail: "Email is required",
      requiredPassword: "Password is required",
      recoverLink: "Forgot password?",
      FAILED_LOGIN: "Authentication failed! Please confirm that your E-mail and password are correct.",
      INCORRECT_LOGIN: "Authentication failed! Please confirm that your E-mail and password are correct.",
      FAILED_CONNECTION: "Failed connection.",
      TOO_MANY_ATTEMPTS: "Account blocked, too many attempts",
      WS_SERVICE_ERROR: "Something went wrong, please retry again later"
    },
    noPromoter: {
      title: "Welcome!",
      subtitle: "Please select your promoter by providing it in the URL.",
      example: "Example:",
    },
  },
  RecoverPass: {
    form: {
      title: "Password Recover",
      subtitle: "Please provide your email address",
      email: "E-mail",
      invalidEmail: "Invalid email",
      requiredEmail: "Email is required",
      submit: "Submit",
      back: "Go back",
      ERROR: "Invalid Email",
      FAILED: "Invalid Email",
      success: "Password Recover - You should receive an email with further instructions",
    },
  },
  PasswordUpdate: {
    form: {
      title: "Password Update",
      subtitle: "Please insert your new password",
      password: "Password",
      password2: "Confirm password",
      requiredPassword: "Password is required",
      matchPassword: "Passwords mismatch",
      submit: "Submit",
      ERROR: "This url is no longer valid",
      FAILED: "This url is no longer valid",
      success: "Password Updated - Please login with the new password you defined",
    },
  },
};

export default authentication;
