
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Button, Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { styles } from "./ResourceList.styles";
import ResourceDataGrid from "./ResourceDataGrid/ResourceDataGrid";

import Page from "../../../common/displays/Page/Page";
import PageTitle from "../../../common/displays/PageTitle/PageTitle";
import Permission from "../../../app/components/Permission";
import {
  selectResourceList,
  selectResourceListFilter,
  selectResourceListTotalRows,
  resourceList,
  resourceDisable,
  resourceEnable,
  placesList,
  selectPlacesList,
  resourceDelete,
} from "../resourcesSlice";
import { selectLoader } from "../../../app/coreSlice";
import { useFirstLoad, useNavigate } from "../../../common/hooks";
import SearchBox from "../../../common/components/inputs/SearchBox";

function ResourceList({ classes }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector(selectResourceList);
  const totalRows = useSelector(selectResourceListTotalRows);
  const filter = useSelector(selectResourceListFilter);
  const loading = useSelector(selectLoader);
  const places = useSelector(selectPlacesList);

  useFirstLoad(() => {
    dispatch(placesList());
  })

  const onAddHandler = () => {
    navigate("/resources/create");
  }

  const onFilterChangeHandler = (v) => {
    dispatch(resourceList({ ...filter, ...v.filter }, v.reset));
  }

  const onSearchHandler = (search) => {
    console.log('search: %o', search);
    dispatch(resourceList({ ...filter, search }, true));
  };

  const onEditHandler = (row) => {
    navigate("/resources/edit/" + row.id);
  }

  const onCopyHandler = (row) => {
    navigate("/resources/copy/" + row.id);
  }

  const onDisableHandler = (row) => {
    dispatch(resourceDisable(row.id));
  }

  const onEnableHandler = (row) => {
    dispatch(resourceEnable(row.id));
  }

  const onRemoveHandler = (row) => {
    dispatch(resourceDelete(row.id));
  }

  return (
    <Page
      permission={"promoter.actions.resources.list"}
      header={<PageTitle
        title={t("resources.ResourceList.header.title")}
        actions={
          <Grid container direction="row" spacing={2}>
            <Grid item>
              <SearchBox onEnter={(v) => onSearchHandler(v)} value={filter?.search} />
            </Grid>
            <Permission code={"promoter.actions.resources.create"}>
              <Grid item>
                <Button variant="outlined" size="large" onClick={onAddHandler} disabled={loading} id="add"><AddIcon fontSize="inherit" /></Button>
              </Grid>
            </Permission>
          </Grid>
        }
      />}>
      <Stack spacing={1} className={classes.root}>
        <ResourceDataGrid
          data={data}
          totalRows={totalRows}
          onFilterChange={onFilterChangeHandler}
          filter={filter}
          onEdit={onEditHandler}
          onCopy={onCopyHandler}
          onDisable={onDisableHandler}
          onEnable={onEnableHandler}
          onRemove={onRemoveHandler}
          places={places}
        />
      </Stack>
    </Page>
  );
}

export default withStyles(ResourceList, styles);