import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const businessUnitProcessTaskBatchesCall = async (
  dispatch,
  { batchId, dateIni, dateEnd }
) => {
  let rh = requestHelper(dispatch, "BUSINESS_UNIT_PROCESS_TASK_BATCHES");
  try {
    await call({
      type: "BUSINESS_UNIT",
      subType: "PROCESS_TASK_BATCHES",
      request: {
        batchId, dateIni, dateEnd
      },
    });

  } catch (ex) {
    rh.error("businessUnits", ex);
  }
  rh.close();
};

export default businessUnitProcessTaskBatchesCall;

