import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import {
  List,
  ListItem,
  ListItemText,
  Popover,
  Divider,
  ListItemIcon,
  Avatar,
  Typography,
  ListItemButton,
  Stack,
} from "@mui/material";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import SettingsIcon from '@mui/icons-material/Settings';

import { styles } from "./UserMenu.styles";

import { useNavigate, useUser } from "../../../../common/hooks";
import { SelectInput } from "../../../../common/inputs";
import { userChangePromoter } from "../../../../features/users/usersSlice";

function UserMenu({ classes, onLogout }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const user = useUser();

  const handlePopoverOpen = (event) => {
    if (anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const onPromoterChangeHandler = (promoterCode) => {
    setAnchorEl(null);
    dispatch(userChangePromoter({ promoterCode }));
  }

  const displayName =
    user?.firstName || user?.lastName ? (user?.firstName || "") + " " + (user?.lastName || "") : user?.username;

  return (
    <div className={open ? classes.activeLink : null}>
      <ListItemButton className={classes.libutton} onClick={handlePopoverOpen}>
        <ListItemIcon>
          <Avatar className={classes.avatar} alt={displayName} src={user?.avatarSignedUrl} />
        </ListItemIcon>
        <ListItemText className={classes.name} primary={displayName} />
      </ListItemButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        className={classes.accountMenuPopover}
      >
        <List className={classes.userMenuContent}>
          <ListItem className="userMenu" aria-haspopup="true" aria-label={displayName}>
            <Stack style={{ width: "100%" }} spacing={1}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Avatar className={classes.avatar} alt={displayName} src={user?.avatarSignedUrl} />
                <Stack spacing={0}>
                  <Typography variant="caption" className={classes.user} component="h5">
                    {displayName}
                  </Typography>
                  <Typography variant="caption" className={classes.entity} component="span">
                    {user?.username}
                  </Typography>
                  <Typography variant="body1" component="span" className={classes.sector}>
                    {user?.sector && t("common.Enums.sector." + user?.sector) + " - "}
                    {t("common.Enums.roleType." + user?.roleType)}
                  </Typography>
                </Stack>
              </Stack>
              <div onClick={() => navigate(`/entities/${user?.entity?.id}`)}>
                <Typography variant="caption" className={classes.entity} component="span">
                  {user?.entity?.name}
                </Typography>
              </div>
            </Stack>
          </ListItem>
          <ListItem>
            <Stack style={{ width: "100%" }}>
              {user.promoterList?.length === 1 ?
                <Stack>
                  <Typography variant="caption">{t("common.Navigation.account.promoter")}</Typography>
                  <Typography>{user.promoterList[0].name}</Typography>
                </Stack> :
                <SelectInput
                  label={t("common.Navigation.account.promoter")}
                  value={user.promoterSelected}
                  options={user.promoterList?.map(({ code, name }) => ({ value: code, text: name }))}
                  onChange={onPromoterChangeHandler}
                />}
            </Stack>
          </ListItem>
          <ListItemButton onClick={() => navigate(`/settings`)}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary={t("common.Navigation.account.settings")} />
          </ListItemButton>
        </List>
        <Divider />
        <List>
          <ListItemButton onClick={onLogout}>
            <ListItemIcon>
              <MeetingRoomIcon />
            </ListItemIcon>
            <ListItemText primary={t("common.Navigation.account.logout")} />
          </ListItemButton>
        </List>
      </Popover>
    </div>
  );
}

export default withStyles(UserMenu, styles);
