import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

import { styles } from "./BatchActivityModal.styles";
import CustomModal from "../../../../common/displays/CustomModal/CustomModal";

function BatchActivityModal({ classes, open, setOpen }) {
  const { t } = useTranslation();

  return (
    <CustomModal open={open} setOpen={setOpen} size="medium" height={205} title={t("batches.BatchActivityModal.modal.title")}>
      <Stack spacing={2}>
        <Typography>BATCH ACTIVITY</Typography>
      </Stack>
    </CustomModal>
  );
}

export default withStyles(BatchActivityModal, styles);
