import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Stack, Divider, Grid2, Button } from "@mui/material";
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';

import { styles } from "./BatchDrawerDetails.styles";
import { useSelector } from "react-redux";
import { selectBatchDetails } from "../../batchesSlice";
import ProductSnip from "../../../../common/displays/ProductSnip/ProductSnip";
import HatcheryProgress from "../../_common/HatcheryProgress/HatcheryProgress";
import StepDetails from "./StepDetails/StepDetails";

function BatchDrawerDetails({ classes, resources, onTasksClick, onMetricsClick, onActivityClick, loading }) {
  const { t } = useTranslation();
  const batchDetails = useSelector(selectBatchDetails);
  const { batchNumber, product } = (batchDetails || {})

  console.log('batchDetails: %o', batchDetails);
  console.log('product: %o', product);

  const progressValues = useMemo(() => {
    let values = [0, 0, 0, 0, 0, 0, 0];
    if (!resources || !batchDetails) {
      return values;
    }
    const res = batchDetails?.processedData?.resources || [];
    const validResourceIds = Object.keys(res).filter(id => parseFloat(res[id]?.quantity) > 0);
    const filteredResources = validResourceIds.map(id => ({
      ...resources.find(item => item.id === id) || {},
      quantity: parseFloat(res[id]?.quantity)
    }))
    filteredResources.forEach(item => {
      if (parseFloat(item.attributes?.mesh) < 150) {
        values[0] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 300) {
        values[1] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 500) {
        values[2] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 1000) {
        values[3] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 1500) {
        values[4] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 2400) {
        values[5] += item.quantity;
      }
      else {
        values[6] += item.quantity;
      }
    })
    return values;
  }, [batchDetails, resources]);



  const steps = useMemo(() => {
    const distribution = batchDetails?.processedData?.distribution || {};
    let steps = [];

    Object.keys(distribution).forEach(id => {
      let step = distribution[id];
      steps.push({
        id,
        ...step,
        tasks: { completed: step?.tasks?.closedTasks || 0, toComplete: step?.tasks?.openTasks || 0 },
        completed: !step?.tasks?.openTasks,
        inprogress: step?.tasks?.closedTasks > 0,
      })
    });
    return steps.sort((a, b) => a.stepOrder - b.stepOrder);
  }, [batchDetails])

  return (
    <div className={classes.root}>
      <Stack spacing={2}>
        <ProductSnip image={product?.thumbnail} description={product?.description} gtin={product?.gtin} />
        <Divider />
        <Typography variant="h4">{batchNumber}</Typography>
        <HatcheryProgress values={progressValues} />
        <Stack>
          {steps.map((step, index) => {
            return (
              <div key={`step_${step.id}`}>
                <StepDetails
                  title={step.stepName}
                  date={step.startTime}
                  tasks={step.tasks}
                  completed={step.completed}
                  inprogress={step.inprogress}
                  isLast={index + 1 === steps.length}
                  onTasksClick={() => onTasksClick({ batchId: batchDetails?.batchId, stepId: step.id })}
                />
              </div>
            )
          })}
        </Stack>
        {false && <Grid2 container spacing={1}>
          <Grid2 size={6}>
            <Button variant="outlined" size="medium" fullWidth onClick={() => onMetricsClick(batchDetails)} disabled={loading}>
              <AnalyticsOutlinedIcon fontSize="inherit" />
              <Typography>{t("batches.BatchesList.details.btnMetrics")}</Typography>
            </Button>
          </Grid2>
          <Grid2 size={6}>
            <Button variant="outlined" size="medium" fullWidth onClick={() => onActivityClick(batchDetails)} disabled={loading}>
              <HistoryOutlinedIcon fontSize="inherit" />
              <Typography>{t("batches.BatchesList.details.btnActivity")}</Typography>
            </Button>
          </Grid2>
        </Grid2>}

      </Stack>
    </div>
  );
}

export default withStyles(BatchDrawerDetails, styles);