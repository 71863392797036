import React from "react";
import { withStyles } from "tss-react/mui";
import { Typography, Paper, Divider, Chip, Stack } from "@mui/material";

import { styles } from "./WeekColumn.styles";
import TaskItem from "./TaskItem/TaskItem";

function WeekColumn({ classes, weekTitle, tasks, onTaskClick }) {

  const placeTasks = tasks.filter(task => task.placeName);
  const batchTasks = tasks.filter(task => task.batchNumber && !task.placeName);
  const ungroupedTasks = tasks.filter(task => !task.placeName && !task.batchNumber);

  return (
    <div>
      <Paper className={classes.root}>
        <Typography>{weekTitle}</Typography>
        <Divider />
        <br />

        <Stack spacing={0.5}>
          {/* Display tasks grouped by placeName */}
          {placeTasks.length > 0 && (
            <>
              {[...new Set(placeTasks.map(task => task.placeName))].map((placeName, index) => (
                <Stack spacing={0.5} key={`place-${index}`}>
                  <div>
                    <Chip label={placeName} size="small" />
                  </div>
                  <Stack spacing={0.5}>
                    {placeTasks
                      .filter(task => task.placeName === placeName)
                      .map((task, idx) => (
                        <div key={`place-task-${idx}`}>
                          <TaskItem task={task} onClick={onTaskClick} />
                        </div>
                      ))}
                  </Stack>
                </Stack>
              ))}
            </>
          )}

          {/* Display tasks grouped by batchNumber */}
          {batchTasks.length > 0 && (
            <>
              {[...new Set(batchTasks.map(task => task.batchNumber))].map((batchNumber, index) => (
                <Stack key={`batch-${index}`}>
                  <div>
                    <Chip label={batchNumber} size="small" color="primary" />
                  </div>
                  <Stack spacing={0.5}>
                    {batchTasks
                      .filter(task => task.batchNumber === batchNumber)
                      .map((task, idx) => (
                        <div key={`batch-task-${idx}`}>
                          <TaskItem task={task} onClick={onTaskClick} />
                        </div>
                      ))}
                  </Stack>
                </Stack>
              ))}
            </>
          )}

          {/* Display ungrouped tasks */}
          {ungroupedTasks.length > 0 && (
            <div>
              <Divider />
              <Stack spacing={0.5}>
                {ungroupedTasks.map((task, idx) => (
                  <div key={`ungrouped-task-${idx}`}>
                    <TaskItem task={task} onClick={onTaskClick} />
                  </div>
                ))}
              </Stack>
            </div>
          )}
        </Stack>
      </Paper>
    </div>
  );
}

export default withStyles(WeekColumn, styles);

