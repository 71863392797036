import {Chip, Grid, LinearProgress, Stack, Typography} from "@mui/material";
import {dateFormat} from "../../../../../common/utils";
import moment from "moment";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Currency from "../../../../../common/displays/Currency/Currency";
import PricePromotions from "../../../PricesList/PricesDataGrid/PricePromotions/PricePromotions";
import {CurrencyInput} from "../../../../../common/inputs/custom";
import {DatePickerInput, SelectInput} from "../../../../../common/inputs";
import {
  calculatePriceWithDproms,
  calculatePriceWithPromotions,
  getEntitiesInGroups,
  processDate
} from "../../../_common/auxiliars";
import CampaignTooltip from "../../../../orders/_common/CampaignTooltip/CampaignTooltip";
import React from "react";


export function listColumns({ t, smallScreen, product, user, promoter, entityGroups, locations, onExtraPriceChange }) {
  let columns = [];
  columns.push({
    field: "priceType",
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      return t("common.Enums.priceType." + v.priceType)
    }
  });
  columns.push({
    field: "extraInfo",
    align: "left",
    headerAlign: "left",
    customValue: (v) => {
      if (v.priceType === "G") {
        let group = (entityGroups || []).find(item => v.groupEntity === item.groupId || v.groupId === item.groupId);
        return <Chip label={group?.groupName || v?.groupName} size="small" />
      }
      if (v.priceType === "D" || v.priceType === "W") {
        let locationsObjs = (locations || []).filter(item => (v.locations || []).includes(item.id));
        return (
          <Grid container direction="row" spacing={1} flexWrap="wrap">
            {locationsObjs.map(loc => <Grid item key={loc.id} ><Chip label={loc.name} size="small" /></Grid>)}
          </Grid>
        );
      }
      return "";
    }
  });
  columns.push({
    field: "date",
    disableSort: true,
    align: "center",
    width: 200,
    customValue: (v) => {
      let progressPercent = 0;
      if (v.expireDate && moment(v.effectiveDate).isBefore(moment())) {
        progressPercent = moment().diff(moment(v.effectiveDate), "days") / moment(v.expireDate).diff(moment(v.effectiveDate), "days") * 100;
        if (progressPercent > 100) {
          progressPercent = 100;
        }
      }
      return <Stack style={{ width: v.expireDate ? 180 : 100 }}>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography style={{ margin: 0 }}>{dateFormat(v.effectiveDate)}</Typography>
          {v.expireDate && <ArrowRightIcon />}
          {v.expireDate && <Typography style={{ margin: 0 }}>{dateFormat(v.expireDate)}</Typography>}
        </Stack>
        <LinearProgress variant="determinate" color={moment(v.effectiveDate).isSameOrAfter(moment()) ? "inherit" : "primary"} value={progressPercent} />
      </Stack>

    }
  });

  if (promoter?.getConfig("price", "allowPricePromotions")) {
    columns.push({
      field: "promotions",
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        return (
          <Stack direction="row" alignItems="center" justifyContent="center">
            {v.promotions && <PricePromotions price={v} />}
          </Stack>
        );
      }
    });
  }

  if (promoter?.getConfig("price", "allowPriceCampaigns")) {
    columns.push({
      field: "campaigns",
      headerName: t("productsPrices.PricesList.columns.campaigns"),
      disableSort: true,
      align: "right",
      width: 20,
      customValue: (v) => {
        let availableCampaigns = (v.campaigns || []).filter((item) => item.active && (user?.isPromoter || getEntitiesInGroups(entityGroups, item.groups).includes(user?.entity?.id)));
        // should show all campaigns to promoter, or if the user entity is part of the groups associated or created the campaign

        return <Stack spacing={1} direction="row">
          {availableCampaigns.map((item, index) => (
            <div item key={`campaign_${index}`}>
              <CampaignTooltip campaign={item} forceHighlight groups={(entityGroups || []).filter(entityGroup => (item.groups || []).some(group => group === entityGroup.groupId))} />
            </div>
          ))}
        </Stack>
      }
    });
  }

  columns.push({
    field: "salePrice",
    disableSort: true,
    align: "right",
    width: 180,
    customValue: (v) => {
      const { priceId, salePrice, promotions, dproms } = v;
      let price = parseFloat(salePrice || "0");
      if (price > 0) {
        const filteredPromotions = (promotions || []).filter(item => !item.toDelete);
        price = calculatePriceWithPromotions(price, filteredPromotions, promoter);
        price = calculatePriceWithDproms(price, dproms);
      }
      return priceId ? <Currency value={price} /> : null;
    }
  });

  columns.push({
    field: "extraPrice",
    headerName: t("productsPrices.ExtraPriceList.columns.extraPrice"),
    disableSort: true,
    align: "right",
    width: 400,
    customValue: (v) => {
      const { priceId, extraPrice, newExtraPrice, priceType, locations } = v;
      const allowedToChange = (user?.entity?.type !== "P" || !promoter?.getConfig("product", "requiresInternalCode") || product.promoterInternalCode) && !extraPrice?.removeDate;
      const extraPriceTypes = promoter?.getConfig("price", "allowedExtraPriceTypes") || [];
      const { productId } = product;
      const extraPriceOptions = extraPriceTypes.map(item => ({ value: item, text: item }));
      const extraPriceObj = {
        value: newExtraPrice?.value || extraPrice?.value || "0",
        type: newExtraPrice?.type !== undefined ? newExtraPrice.type : (extraPrice?.type || null),
        startDate: newExtraPrice?.startDate || extraPrice?.startDate || "",
        endDate: newExtraPrice?.endDate || extraPrice?.endDate || "",
      };
      const extraPriceRemoved = extraPrice?.removeDate && moment(extraPrice?.removeDate).isSameOrBefore(moment())
      return priceId && !extraPriceRemoved ?
        <Stack direction={smallScreen ? "column" : "row"} spacing={1} style={{ maxWidth: smallScreen ? 320 : "auto" }} alignItems="center">

          <div style={{ minWidth: 130 }}>
            <CurrencyInput
              value={extraPriceObj.value}
              disabled={!allowedToChange}
              onChange={(value) => onExtraPriceChange({ productId, priceId, priceType, locations, newExtraPrice: { ...extraPriceObj, value } })}
            />
          </div>

          {extraPriceTypes.length > 0 &&
            <div style={{ minWidth: 130 }}>
              <SelectInput
                options={extraPriceOptions}
                value={extraPriceObj.type || ""}
                allowEmpty
                disabled={!allowedToChange}
                clearLabel={t("productsPrices.ExtraPriceList.columns.extraPriceTypeClear")}
                onChange={(type) => onExtraPriceChange({ productId, priceId, priceType, locations, newExtraPrice: { ...extraPriceObj, type } })}
              />
            </div>
          }
          {promoter?.getConfig("price", "allowExtraPriceStartDate") &&
            <Stack direction="row" spacing={1}>
              <div style={{ minWidth: 155 }}>
                <DatePickerInput
                  value={extraPriceObj.startDate}
                  disabled={!allowedToChange}
                  maxDate={extraPriceObj.endDate}
                  onChange={(startDate) => onExtraPriceChange({ productId, priceId, priceType, locations, newExtraPrice: { ...extraPriceObj, startDate: processDate(startDate) } })}
                />
              </div>
              <div style={{ minWidth: 155 }}>
                <DatePickerInput
                  value={extraPriceObj.endDate}
                  disabled={!allowedToChange}
                  minDate={extraPriceObj.startDate}
                  onChange={(endDate) => {
                    onExtraPriceChange({ productId, priceId, priceType, locations, newExtraPrice: { ...extraPriceObj, endDate: processDate(endDate) } })
                  }}
                />
              </div>
            </Stack>
          }
        </Stack> :
        null;
    }
  });

  return columns;
}


