export const styles = (theme) => ({
  root: {
    "& .CdgRow ": {
      height: 48,
    },
    "& .CdgRow td": {
      padding: theme.spacing(0.5) + " " + theme.spacing(1)
    },
    "& .CdgRow-actions": {
      width: 20,
    },
    "& .CdgRow td:nth-last-of-type(1)": {
      padding: 0,
    },
    "& .CdgRow td:nth-last-of-type(2)": {
      padding: 0,
    }
  },

});