import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { getPromoterConfig, requestHelper } from "../../../app/coreSlice";
import { getImageUrlBase64 } from "../../../api/s3";
import moment from "moment/moment";

const taskListCall = async (
  { clearTaskList, setTaskListFilter, setTaskListWeekFilter, appendToTaskList, setTaskListTotalRows },
  dispatch,
  { filter, clear },
  state
) => {
  let rh = requestHelper(dispatch, "TASK_LIST");
  if (clear) {
    dispatch(clearTaskList());
    filter = { ...filter, offset: 0 };
  }
  if (filter.weekly) {
    dispatch(setTaskListWeekFilter(filter));
  }
  else {
    dispatch(setTaskListFilter(filter));
  }
  try {
    let result = await call({
      type: "TASK",
      subType: "LIST",
      request: {
        ...filter,
        weekly: undefined, // to clean the flag to identify if is weekly
        sortField: (filter.sortField || "").toUpperCase(),
        startDate: moment.isMoment(filter.startDate) ? filter.startDate.toISOString() : filter.startDate,
        endDate: moment.isMoment(filter.endDate) ? filter.endDate.toISOString() : filter.endDate,
      },
    });
    if (result && result.items && result.items.length > 0) {
      let items = [];
      for (let i = 0; i < result.items.length; i++) {
        let item = result.items[i];
        if (item.assigneesInfo && item.assigneesInfo.length > 0) {
          items.push({
            ...item,
            assigneesInfo: await processContacts(state, item.assigneesInfo),
          });
        }
      }
      dispatch(appendToTaskList(items));
      dispatch(setTaskListTotalRows(result.rows));
    }
  } catch (ex) {
    rh.error("tasks", ex);
  }
  rh.close();
};

export default taskListCall;

const processContacts = async (storeState, assigneesInfo) => {
  const fileStorageConfigs = getPromoterConfig(storeState, "admin", "fileStorage");
  const promoterCode = storeState.core.promoterDetails?.details?.code;
  let contactList = [];
  if (assigneesInfo && assigneesInfo.length > 0) {
    for (let i = 0; i < assigneesInfo.length; i++) {
      let c = await fromUserAssignee(fileStorageConfigs, promoterCode, assigneesInfo[i]);
      contactList.push(c);
    }
  }
  return contactList;
};

export const fromUserAssignee = async (filestorageConfigs, promoterCode, contact) => {
  let logo = "";
  try {
    logo = await getImageUrlBase64(filestorageConfigs?.provider, filestorageConfigs?.privateBucket, contact.avatar?.path);
  } catch (ex) {
    logo = "";
  }
  return {
    logo,
    ...contact,
  };
};