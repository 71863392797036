import React from "react";
import { useSelector } from "react-redux";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid, Stack, Chip, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';

import { styles } from "./BatchTaskListModal.styles";

import CustomModal from "../../../../common/displays/CustomModal/CustomModal";
import { selectBatchTasksList } from "../../batchesSlice";
import TaskIcon from "../../../tasks/TasksList/TasksDataGrid/TaskIcon/TaskIcon";
import { multiLanguagePropToString, sortListByDate, sortList } from "../../../../common/utils";
import DateTime from "../../../../common/displays/DateTime/DateTime";
import moment from "moment";

function BatchTaskListModal({ classes, open, setOpen, onTaskClick }) {
  const { t, i18n } = useTranslation();
  const tasks = useSelector(selectBatchTasksList);

  return (
    <CustomModal open={open} setOpen={setOpen} size="large" height={500} title={t("batches.BatchTaskListModal.modal.title")}>
      <Stack spacing={1}>
        {
          sortList(sortListByDate(tasks, "dueDate"), "status").reverse().map(task => (
            <div key={task?.id} className={classes.taskRow + (task.status === "CMP" ? (" " + classes.completed) : "")}>
              <Grid container spacing={1} direction="row" onClick={() => onTaskClick(task)} justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <TaskIcon task={task} />
                    <Stack direction="row" spacing={0.5} alignItems="center">
                      {task?.stepName && <div><Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{task?.stepName}</Typography></div>}
                      {task?.stepName && <div><Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{"-"}</Typography></div>}
                      <div><Typography style={{ margin: 0, whiteSpace: "nowrap" }}>{multiLanguagePropToString(task?.name, i18n.language)}</Typography></div>
                    </Stack>
                    {task?.placeId && <Chip label={task?.placeName || task?.place?.name} size="small" />}
                    {task?.batchId && <Chip label={task?.batchNumber || task?.batch?.batchNumber} size="small" color="primary" />}
                  </Stack>
                </Grid>
                <Grid item>
                  {task.status === "CMP" ? <CheckIcon color="success" style={{ display: "block" }} /> : <DateTime date={task?.dueDate} inline textColor={task?.status !== "CMP" && moment(task?.dueDate).isBefore(moment()) ? "error" : ""} />}
                </Grid>
              </Grid>
            </div>
          ))
        }

      </Stack>

    </CustomModal>
  );
}

export default withStyles(BatchTaskListModal, styles);
