import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";

import { styles } from "./TasksWeeklyView.styles";
import WeekSwitcher from "../../../../common/components/WeekSwitcher/WeekSwitcher";
import moment from "moment";
import WeekColumn from "./WeekColumn/WeekColumn";

function TasksWeeklyView({ classes, data, weekStartDate, setWeekStartDate, onTaskClick }) {
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <WeekSwitcher startDate={weekStartDate} onChange={(date) => setWeekStartDate(date)} />
      <Grid spacing={1} direction="row" container >
        {[0, 1, 2, 3, 4, 5, 6].map(weekday => (
          <Grid key={`weekday_${weekday}`} item xl={1.714} md={3} sm={6} xs={12}>
            <WeekColumn
              onTaskClick={onTaskClick}
              tasks={filterDatesByWeekday(data, weekStartDate.clone().add(weekday, 'days'))}
              weekTitle={`${t("common.Enums.weekday." + (weekday + 1))} (${weekStartDate.clone().add(weekday, 'days').format('DD MMM')})`}
            />
          </Grid>
        ))}
        {/* <Grid item xl={1.714} md={4} sm={6} xs={12}>
          <WeekColumn onTaskClick={onTaskClick} tasks={filterDatesByWeekday(data, weekStartDate)} weekTitle={`${t("common.Enums.weekday.1")} (${weekStartDate.format('DD MMM')})`} />
        </Grid>
        <Grid item xl={1.714} md={4} sm={6} xs={12}>
          <WeekColumn onTaskClick={onTaskClick} tasks={filterDatesByWeekday(data, weekStartDate.clone().add(1, 'days'))} weekTitle={`${t("common.Enums.weekday.2")} (${weekStartDate.clone().add(1, 'days').format('DD MMM')})`} />
        </Grid>
        <Grid item xl={1.714} md={4} sm={6} xs={12}>
          <WeekColumn onTaskClick={onTaskClick} tasks={filterDatesByWeekday(data, weekStartDate.clone().add(2, 'days'))} weekTitle={`${t("common.Enums.weekday.3")} (${weekStartDate.clone().add(2, 'days').format('DD MMM')})`} />
        </Grid>
        <Grid item xl={1.714} md={3} sm={6} xs={12}>
          <WeekColumn onTaskClick={onTaskClick} tasks={filterDatesByWeekday(data, weekStartDate.clone().add(3, 'days'))} weekTitle={`${t("common.Enums.weekday.4")} (${weekStartDate.clone().add(3, 'days').format('DD MMM')})`} />
        </Grid>
        <Grid item xl={1.714} md={3} sm={6} xs={12}>
          <WeekColumn onTaskClick={onTaskClick} tasks={filterDatesByWeekday(data, weekStartDate.clone().add(4, 'days'))} weekTitle={`${t("common.Enums.weekday.5")} (${weekStartDate.clone().add(4, 'days').format('DD MMM')})`} />
        </Grid>
        <Grid item xl={1.714} md={3} sm={6} xs={12}>
          <WeekColumn onTaskClick={onTaskClick} tasks={filterDatesByWeekday(data, weekStartDate.clone().add(5, 'days'))} weekTitle={`${t("common.Enums.weekday.6")} (${weekStartDate.clone().add(5, 'days').format('DD MMM')})`} />
        </Grid>
        <Grid item xl={1.714} md={3} sm={6} xs={12}>
          <WeekColumn onTaskClick={onTaskClick} tasks={filterDatesByWeekday(data, weekStartDate.clone().add(6, 'days'))} weekTitle={`${t("common.Enums.weekday.7")} (${weekStartDate.clone().add(6, 'days').format('DD MMM')})`} />
        </Grid> */}
      </Grid>
    </div>
  );
}

export default withStyles(TasksWeeklyView, styles);

const filterDatesByWeekday = (tasks, date) => {
  return tasks.filter(task => {
    return moment.utc(task.dueDate).local().isBetween(date.clone().startOf("day"), date.clone().endOf("day"), "minute", '[]')
  })
}