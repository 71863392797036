const tasks = {
  flowsMessages: {
    success: {
      TASK_ACTION: "Task updated successfully!",
      TASK_ASSIGN: "Task assigned successfully!",
      TASK_CREATE: "Task created successfully!",
    },
    error: {
      GENERIC: "Something went wrong",
    },
  },
  TasksList: {
    header: {
      title: "Tasks",
    },
    menu: {
      list: "List",
      weekly: "Weekly"
    },
    columns: {
      title: "Title",
      assignees: "Assignees",
      duedate: "Due Date",
      status: "Status",
    },
    filter: {
      showInactive: "Show Inactive",
      showAssignedToMe: "Show Assigned To Me",
      showAsAdmin: "Show As Admin",
      status: "Status",
      search: "Search",
      startDate: "Start Date",
      endDate: "End Date",
    },
  },
  TasksForm: {
    header: {
      title: "Create Task",
    },
    form: {
      title: "Title",
      templateCode: "Template Code",
      associationType: "Association Type",
      users: "Users",
      message: "Message",
      groups: "Groups",
      dueDate: "Due Date",
      btnSubmit: "Submit",
      titleRequired: "Title is required",
      messageRequired: "Message is required",
      userRequired: "User is required",
      groupRequired: "Group is required",
      sectorRequired: "Sector is required",
      comment: "New Comment"
    },
  },
  TaskDetailsModal: {
    header: {
      inputs: "Inputs",
      comments: "Comments",
      assignee: "Assignee",
      dueDate: "Due Date"
    },
    form: {
      btnComplete: "Complete",
      btnSubmit: "Submit",
      btnSave: "Save",
      comments: "Comments",
    },
  },
  CommentsGrid: {
    details: {
      nocomments: "No comments"
    }
  },
  ResourceSelectorModal: {
    modal: {
      title: "Choose the resource",
    },
    form: {
      btnSubmit: "Submit",
      resourceRequired: "Resource is required",
      resource: "Resource"
    }
  },
  ResourceTransferField: {
    label: {
      location: "Location"
    }
  },
  BatchDistribution: {
    header: {
      title: "Batch distribution"
    }
  }
};

export default tasks;