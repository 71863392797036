import {Chip, Grid, Stack, Typography} from "@mui/material";
import ActionSnip from "../../../../common/displays/ActionSnip/ActionSnip";
import {currencyFormat} from "../../../../common/utils";
import PricePromotions from "../../PricesList/PricesDataGrid/PricePromotions/PricePromotions";

export function listColumns(t, classes, promoter, entityGroups, locations) {
    let columns = [];

    columns.push(
        {
            field: "actionDate",
            headerName: t("productsPrices.PriceHistoryModal.columns.actionDate"),
            align: "left",
            headerAlign: "left",
            customValue: (v) => {
                return <ActionSnip username={v.actionUser} date={v.actionDate}/>;
            },
        });
    columns.push({
        field: "priceType",
        headerName: t("productsPrices.PriceHistoryModal.columns.priceType"),
        align: "left",
        headerAlign: "left",
        disableSort: true,
        customValue: (v) => {
            return v.price?.priceType ? t("common.Enums.priceType." + v.price?.priceType) : "";
        }
    });
    columns.push({
        field: "extraInfo",
        headerName: t("productsPrices.PriceHistoryModal.columns.subType"),
        align: "left",
        headerAlign: "left",
        disableSort: true,
        customValue: (v) => {
            if (v.price?.priceType === "G") {
                let group = (entityGroups || []).find(item => v.price?.groupEntity === item.groupId || v.price?.groupId === item.groupId);
                return <Chip label={group?.groupName || v?.groupName} size="small" />
            }
            if (v.price?.priceType === "D" || v.price?.priceType === "W") {
                let locationsObjs = (locations || []).filter(item => (v.price?.locations || []).includes(item.id));
                return (
                    <Grid container direction="row" spacing={1} flexWrap="wrap">
                        {locationsObjs.map(loc => <Grid item key={loc.id} ><Chip label={loc.name} size="small" /></Grid>)}
                    </Grid>
                );
            }
            return "";
        }
    });
    columns.push({
        field: "effectiveDate",
        headerName: t("productsPrices.PriceHistoryModal.columns.effectiveDate"),
        align: "left",
        headerAlign: "left",
        disableSort: true,
        customValue: (v) => {
            return v.price?.effectiveDate ? v.price?.effectiveDate : "";
        }
    });
    columns.push({
        field: "removeDate",
        headerName: t("productsPrices.PriceHistoryModal.columns.removeDate"),
        align: "left",
        headerAlign: "left",
        disableSort: true,
        customValue: (v) => {
            return v.price?.removeDate ? v.price?.removeDate : "";
        }
    });
    columns.push({
        field: "salePrice",
        headerName: t("productsPrices.PriceHistoryModal.columns.salePrice"),
        align: "center",
        headerAlign: "left",
        disableSort: true,
        customValue: (v) => {
            return currencyFormat(v.price?.salePrice);
        }
    });
    if(promoter?.getConfig("price", "allowRetailPrice")) {
      columns.push({
        field: "retailPrice",
        headerName: t("productsPrices.PriceHistoryModal.columns.retailPrice"),
        align: "center",
        headerAlign: "left",
        disableSort: true,
        customValue: (v) => {
          return currencyFormat(v.price?.retailPrice);
        }
      });
    }
    if(promoter?.getConfig("price", "allowPricePromotions")){
      columns.push({
          field: "promotions",
          headerName: t("productsPrices.PriceHistoryModal.columns.promotions"),
          align: "right",
          headerAlign: "left",
          disableSort: true,
          customValue: (v) => {
              if (v.price?.salePrice > 0) {
                  return (
                      <Stack direction="row" alignItems="center" justifyContent="center">
                          {v.price?.promotions && <PricePromotions price={v.price}/>}
                      </Stack>
                  );
              }
              return null;
          }
      });
    }
   /* columns.push({
        field: "active",
        headerName: t("productsPrices.PriceHistoryModal.columns.active"),
        align: "left",
        headerAlign: "left",
        disableSort: true,
        customValue: (v) => {
            return v.price?.active ? t('products.PriceHistoryModal.columns.active_' + v.price?.active) : "";
        }
    });*/
    columns.push({
        field: "status",
        headerName: t("productsPrices.PriceHistoryModal.columns.status"),
        align: "left",
        headerAlign: "left",
        customValue: (v) => {
            if (v.price?.status) {
                const status = v.price?.status;
                if (status === "ACC" || status === "END") {
                    return <Typography
                        className={classes.statusAcc}>{t("common.Enums.priceStatus." + status)}</Typography>
                } else if (status === "PND") {
                    return <Typography
                        className={classes.statusPnd}>{t("common.Enums.priceStatus." + status)}</Typography>
                } else {
                    return <Typography
                        className={classes.statusRef}>{t("common.Enums.priceStatus." + status)}</Typography>
                }
            }
        }
    });
    return columns;
}
