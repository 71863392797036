import React from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { Typography, Alert } from "@mui/material";

import { styles } from "./TaskInfoDisplay.styles";
import { multiLanguagePropToString } from "../../../../../common/utils";
import BatchDistribution from "./BatchDistribution/BatchDistribution";

function TaskInfoDisplay({ classes, info, resources, places, batch }) {
  const { i18n } = useTranslation();
  const content = typeof info.content === "string" ? info.content : multiLanguagePropToString(info.content, i18n.language)

  return (
    <div className={classes.root}>
      {info.type === "text" && <Typography>{content}</Typography>}
      {info.type === "warning" && <Alert severity="warning">{content}</Alert>}
      {info.type === "batch_distribution" && <BatchDistribution resources={resources} places={places} batch={batch} />}
    </div>
  );
}

export default withStyles(TaskInfoDisplay, styles);
