import * as Yup from "yup";
import { SwitchField } from "../../../../common/components/formFields";
import { DatePickerField, MultiSelectField } from "../../../../common/forms";

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    status: Yup.array(),
  });
};

export const filterFields = () => (t, values) => {
  return [
    {
      size: 12,
      field: (
        <DatePickerField id="startDate" label={t("tasks.TasksList.filter.startDate")} />
      ),
    },
    {
      size: 12,
      field: (
        <DatePickerField id="endDate" label={t("tasks.TasksList.filter.endDate")} minDate={values.startDate} />
      ),
    },
    {
      size: 12,
      field: (
        <MultiSelectField id="status" label={t("tasks.TasksList.filter.status")} options={getStatusOptions(t)} />
      ),
    },
    {
      size: 12,
      field: <SwitchField id="showInactive" label={t("tasks.TasksList.filter.showInactive")} />,
    },
    {
      size: 12,
      field: <SwitchField id="showAssignedToMe" label={t("tasks.TasksList.filter.showAssignedToMe")} />,
    },
    {
      size: 12,
      field: <SwitchField id="showAsAdmin" label={t("tasks.TasksList.filter.showAsAdmin")} />,
    },
  ];
};


const getStatusOptions = (t) => {
  return ["PND", "CMP"].map((value) => {
    return {
      value,
      text: t("common.Enums.taskStatus." + value),
    };
  });
};
