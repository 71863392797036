export const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  drawer: {
    width: "25vw",
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      width: "40vw"
    },
    [theme.breakpoints.down('md')]: {
      width: "60vw"
    },
    [theme.breakpoints.down('sm')]: {
      width: "80vw"
    }
  },
  drawerFilter: {
    width: "25vw",
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(3),
    position: "relative",
    width: "100%",
  },
  loader: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },

});
