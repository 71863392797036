import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./BatchDistribution.styles";
import RoomCard from "../../../../../batches/BatchesList/PlaceListItem/RoomCard/RoomCard";
import { Typography } from "@mui/material";

function BatchDistribution({ classes, resources, places, batch }) {
  const { t } = useTranslation();

  const placesDistribution = useMemo(() => {
    if (resources?.length > 0 && places?.length > 0 && batch) {
      return processDistribution(resources, places, batch);
    }
    return [];
  }, [resources, places, batch])

  console.log('placesDistribution: %o', placesDistribution);

  return (
    <div className={classes.root}>
      <Typography variant="h4">{t("tasks.BatchDistribution.header.title")}</Typography>
      {placesDistribution.map(item => (<RoomCard key={item.id} placeName={item.placeName} buildingName={item.buildingName} places={item.places} />))}

    </div>
  );
}

export default withStyles(BatchDistribution, styles);

const processDistribution = (resources, places, batch) => {
  let rooms = [];
  // console.log('processDistribution: %o', { resources, places, batch })
  Object.keys(batch?.processedData?.resources || {}).forEach(resourceId => {
    let data = batch?.processedData?.resources?.[resourceId];
    if (parseFloat(data?.quantity) > 0) {
      let resource = (resources || []).find(item => item.id === resourceId);
      let resourceTank = getTank(resource, resources);
      let resourceArea = getArea(resourceTank, places);
      let resourceRoom = getRoom(resourceArea, places);

      // console.log(' [%s] ---> %o', resourceId, { data, resource, resourceTank, resourceArea, resourceRoom });

      if (resourceRoom) {
        let room = rooms.find(item => item.id === resourceRoom.id);
        if (room) {
          let nRoom = room;
          let area = room.places.find(item => item.id === resourceArea.id);
          if (area) {
            let nArea = area;
            let tank = area.resources.find(item => item.id === resourceTank.id);
            if (tank) {
              let nTank = tank;
              let cylinder = tank.resources.find(item => item.id === resource.id);
              if (!cylinder) {
                nTank.resources.push(resource)
              }

              // tanks (nRoom.resources) - area exists -> remove from collection and add again
              nArea.resources = nArea.resources.filter(item => item.id !== resourceTank.id)
              nArea.resources.push({ ...nTank, attributes: { slots: nTank.resources.length } });
            }
            else {
              nArea.resources.push({
                ...resourceTank,
                attributes: { slots: 1 },
                resources: [resource]
              });
            }
            // areas (nRoom.places) - area exists -> remove from collection and add again
            nRoom.places = nRoom.places.filter(item => item.id !== resourceArea.id)
            nRoom.places.push(nArea);
          }
          else {
            nRoom.places.push({
              ...resourceArea,
              placeName: resourceArea.title,
              resources: [{
                ...resourceTank,
                attributes: { slots: 1 },
                resources: [resource]
              }]
            });
          }
          // rooms - room exists -> remove from collection and add again
          rooms = rooms.filter(item => item.id !== resourceRoom.id);
          rooms.push(nRoom);
        }
        else {
          rooms.push({
            ...resourceRoom,
            buildingName: resourceRoom.parentName,
            placeName: resourceRoom.title,
            places: [{
              ...resourceArea,
              placeName: resourceArea.title,
              resources: [{
                ...resourceTank,
                attributes: { slots: 1 },
                resources: [resource]
              }]
            }]
          })
        }

      }
    }
  })
  return rooms;
}

// Room ----
//  Area/Tank
//   () () () () (cylinder)

const getTank = (element, resources) => {
  if (element && element.type === "C" && element.parentId) {
    return (resources || []).find(item => item.id === element.parentId);
  }
  return null;
}

const getArea = (element, places) => {
  if (element && element.type === "T" && element.placeId) {
    return (places || []).find(item => item.id === element.placeId);
  }
  return null;
}

const getRoom = (element, places) => {
  if (element && element.type === "A" && element.parentId) {
    return (places || []).find(item => item.id === element.parentId);
  }
  return null;
}
