

import { call } from "../../../app/websocket/_sliceCalls/websocket";
import { requestHelper } from "../../../app/coreSlice";

const batchActivityCall = async (
  { clearBatchActivityList, setBatchActivityListFilter, appendToBatchActivityList, setBatchActivityListTotalRows },
  dispatch,
  { batchId, filter, clear }
) => {
  let rh = requestHelper(dispatch, "BATCH_ACTIVITY");
  if (clear) {
    dispatch(clearBatchActivityList());
    filter = { ...filter, offset: 0 };
  }
  dispatch(setBatchActivityListFilter(filter));
  try {
    let result = await call({
      type: "BATCH",
      subType: "ACTIVITY",
      request: {
        ...filter,
        batchId,
        sortField: (filter.sortField || "").toUpperCase(),
      },
    });
    dispatch(appendToBatchActivityList(result.items));
    dispatch(setBatchActivityListTotalRows(result.rows));
    
  } catch (ex) {
    rh.error("batches", ex);
  }
  rh.close();
};

export default batchActivityCall;