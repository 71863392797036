import * as Yup from "yup";
import {
  FileUploadField,
  TextField,
  SelectField,
  CardSelectField,
  NumberWithSelectField,
  CountryAutoCompleteField,
} from "../../../common/forms";
import { getStateOptions } from "../../../common/forms/options";
import { getSectorOptions } from "../UserForm/UserForm.options";
import {
  getAddressContactOptions,
  getAddressOperatorOptions,
  getBusinessUnitsOptions,
  getEntityCreationTypeOptions,
  getEntityPermissionOptions,
  getFeeTypeOptions,
  getFeeValueTypeOptions,
  getFrequencyTypeOptions,
  getOperationTypeOptions,
  getTrafficTypeOptions,
  getUserRoleOptions,
} from "./EntityForm.options";
import TaxNumberField from "../../../common/forms/fields/TaxNumberField";

let yupCep = (t) => Yup.string()
  .matches(/^[0-9]+$/, t("entities.CreateEntity.form.onlyDigits"))
  .min(7, t("entities.CreateEntity.form.atLeastDigits", { digits: 7 }))
  .max(11, t("entities.CreateEntity.form.atTopDigits", { digits: 11 }));

let yupGln = (t) => Yup.string()
  .matches(/^[0-9]+$/, t("entities.CreateEntity.form.onlyDigits"))
  .min(13, t("entities.CreateEntity.form.specificDigits", { digits: 13 }))
  .max(13, t("entities.CreateEntity.form.specificDigits", { digits: 13 }));

export function formSchema({ t, selectedType, promoter, disableFields, user }) {
  return Yup.object().shape({
    taxNumber: Yup.string().nif(t("entities.CreateEntity.form.invalidTaxNumber")),
    name: Yup.string().required(t("entities.CreateEntity.form.requiredName")),
    type: Yup.string().required(t("entities.CreateEntity.form.requiredType")),
    cep: user?.entity?.type === "P" ? yupCep(t) : Yup.string(),
    gln: yupGln(t),
    trafficType:
      selectedType === "S" ? Yup.string().required(t("entities.CreateEntity.form.requiredTrafficType")) : Yup.string(),
    operationType:
      selectedType === "S" &&
        promoter?.getConfig("order", "hasLogisticOperator") &&
        promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
        ? Yup.string().required(t("entities.CreateEntity.form.requiredOperationType"))
        : Yup.string(),
    cmpAccumulation:
      promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
        promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
        ? Yup.string().required(t("entities.CreateEntity.form.requiredCmpAccumulation"))
        : Yup.string(),
    cpAccumulation:
      promoter?.getConfig("price", "cpAccumulation") === "EC" &&
        promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
        ? Yup.string().required(t("entities.CreateEntity.form.requiredCpAccumulation"))
        : Yup.string(),
    // priceDiscount: Yup.number()
    //   .typeError(t("entities.CreateEntity.form.number"))
    //   .default(0),
    fee: Yup.object().shape({
      frequencyType: Yup.string(),
      quantity: Yup.number().typeError(t("entities.CreateEntity.form.number")).default(0),
      type: Yup.string(),
    }),
    taxAddress: Yup.object().shape({
      address: disableFields ? Yup.string() : Yup.string().required(t("entities.CreateEntity.form.requiredAddress")),
      county: Yup.string(),
      locale: disableFields ? Yup.string() : Yup.string().required(t("entities.CreateEntity.form.requiredLocale")),
      postalCode: disableFields ? Yup.string() : Yup.string().required(t("entities.CreateEntity.form.requiredPostalCode")),
      state: Yup.string(),
      country: disableFields ? Yup.string() : Yup.string().required(t("entities.CreateEntity.form.requiredCountry")),
    }),
    businessUnits: Yup.array().of(
      Yup.object().shape({
        businessUnitDesc: Yup.string().required(t("entities.CreateEntity.form.requiredBusinessUnitDesc")),
        gln: yupGln(t),
        cep: yupCep(t),
        trafficType: Yup.string().required(t("entities.CreateEntity.form.requiredTrafficType")),
        operationType: Yup.string().required(t("entities.CreateEntity.form.requiredOperationType")),
        cmpAccumulation:
          promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
            ? Yup.string().required(t("entities.CreateEntity.form.requiredCmpAccumulation"))
            : Yup.string(),
        cpAccumulation:
          promoter?.getConfig("price", "cpAccumulation") === "EC" &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
            ? Yup.string().required(t("entities.CreateEntity.form.requiredCpAccumulation"))
            : Yup.string(),
        // priceDiscount: Yup.number()
        //   .typeError(t("entities.CreateEntity.form.number"))
        //   .default(0),
        fee: Yup.object().shape({
          frequencyType: Yup.string(),
          quantity: Yup.number().typeError(t("entities.CreateEntity.form.number")).default(0),
          type: Yup.string(),
        }),
      })
    ),
    users: Yup.array()
      .ensure()
      .requiredUserWithFullAccess(t("entities.CreateEntity.form.requiredUserWithFullAccess"))
      .of(
        Yup.object().shape({
          firstName: Yup.string().required(t("entities.CreateEntity.form.requiredUserFirstName")),
          lastName: Yup.string().required(t("entities.CreateEntity.form.requiredUserLastName")),
          roleType: Yup.string().required(t("entities.CreateEntity.form.requiredUserRole")),
          phoneNumber: Yup.string().phone(t("entities.CreateEntity.form.invalidPhone")),
          email: Yup.string()
            .emailValidator(t("entities.CreateEntity.form.invalidUserEmail"))
            .required(t("entities.CreateEntity.form.requiredUserEmail")),
        })
      )
    ,
    addressList: Yup.array()
      .ensure()
      .min(1, t("entities.CreateEntity.form.requiredAtLeastOneAddress"))
      .of(
        Yup.object().shape({
          description: Yup.string().required(t("entities.CreateEntity.form.requiredAddressDescription")),
          contactIdIndex: Yup.string().required(t("entities.CreateEntity.form.requiredAddressContact")),
          state: Yup.string().required(t("entities.CreateEntity.form.requiredAddressState")),
          address: Yup.string().required(t("entities.CreateEntity.form.requiredAddressAddress")),
          county: Yup.string().required(t("entities.CreateEntity.form.requiredAddressCounty")),
          locale: Yup.string().required(t("entities.CreateEntity.form.requiredAddressLocale")),
          postalCode: Yup.string().required(t("entities.CreateEntity.form.requiredAddressPostalCode")),
          country: Yup.string().required(t("entities.CreateEntity.form.requiredAddressCountry")),
          operatorAddress:
            selectedType !== "LO" && selectedType !== "P" && promoter?.getConfig("order", "hasLogisticOperator")
              ? Yup.string().required(t("entities.CreateEntity.form.requiredOperatorAddress"))
              : Yup.string(),
        })
      ),
  });
}

export function formEditSchema({ t, user }) {
  return Yup.object().shape({
    gln: yupGln(t),
    cep: user?.entity?.type === "P" ? yupCep(t) : Yup.string(),
    trafficType: Yup.string(),
    fee: Yup.object().shape({
      frequencyType: Yup.string(),
      quantity: Yup.number().typeError(t("entities.CreateEntity.form.number")).default(0),
      type: Yup.string(),
    }),
  });
}

export function formFields(t, isEdit, loader, promoter, selectedType, disableFields, onNifChange, onTypeChange, user, values) {
  const addressStates = getStateOptions(promoter, values?.taxAddress?.country);
  return [
    {
      size: 4,
      field: (
        <TaxNumberField
          id="taxNumber"
          type="text"
          label={t("entities.CreateEntity.form.taxNumber")}
          required
          onCheck={(value) => onNifChange(value)}
          disabled={loader || isEdit}
          checkLabel={t("entities.CreateEntity.form.taxNumberCheck")}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="name"
          type="text"
          label={t("entities.CreateEntity.form.name")}
          required
          disabled={disableFields}
        />
      ),
    },
    {
      size: 4,
      field: (
        <FileUploadField
          id="logo"
          accept="image/*"
          label={t("entities.CreateEntity.form.logo")}
          placeholder={t("entities.CreateEntity.form.logoPlaceholder")}
          image
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="promoterInternalCode"
          type="text"
          label={t("entities.CreateEntity.form.promoterInternalCode")}
          disabled={!promoter?.getConfig("entity", "allowUpdateInternalCode")}
        />
      ),
      hidden: !(user?.isPromoter && promoter?.getConfig("entity", "requiresInternalCode"))
    },
    {
      size: 12,
      hidden: !(user?.isPromoter && promoter?.getConfig("entity", "requiresInternalCode"))
    },
    {
      size: 8,
      field: (
        <TextField
          id="taxAddress.address"
          type="text"
          label={t("entities.CreateEntity.form.address")}
          disabled={disableFields && !isEdit}
          required={!disableFields}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="taxAddress.county"
          type="text"
          label={t("entities.CreateEntity.form.county")}
          disabled={disableFields && !isEdit}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="taxAddress.locale"
          type="text"
          label={t("entities.CreateEntity.form.locale")}
          disabled={disableFields && !isEdit}
          required={!disableFields}
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id="taxAddress.postalCode"
          type="text"
          label={t("entities.CreateEntity.form.postalCode")}
          disabled={disableFields && !isEdit}
          required={!disableFields}
        />
      ),
    },
    {
      size: 4,
      field: (
        <CountryAutoCompleteField
          id="taxAddress.country"
          label={t("entities.CreateEntity.form.country")}
          disabled={disableFields && !isEdit}
          required={!disableFields}
        />
      ),
    },
    {
      size: 4,
      field: (
        addressStates && addressStates.length > 0
          ? <SelectField
            id="taxAddress.state"
            label={t("entities.CreateEntity.form.addressState")}
            disabled={disableFields && !isEdit}
            options={addressStates}
          />
          : <TextField id="taxAddress.state" type="text" label={t("entities.CreateEntity.form.addressState")} disabled={disableFields && !isEdit} />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id="website" type="text" label={t("entities.CreateEntity.form.website")} disabled={disableFields} />
      ),
    },
    {
      size: 4,
      field: <TextField id="fax" type="text" label={t("entities.CreateEntity.form.fax")} disabled={disableFields} />,
    },
    {
      size: 4,
      field: (
        <TextField id="phone" type="text" label={t("entities.CreateEntity.form.phone")} disabled={disableFields} />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id="email" type="text" label={t("entities.CreateEntity.form.email")} disabled={disableFields} />
      ),
    },
    {
      size: 12,
      field: (
        <CardSelectField
          onChange={(value) => onTypeChange(value)}
          id="type"
          label={t("entities.CreateEntity.form.type")}
          disabled={isEdit}
          xs={4}
          options={getEntityCreationTypeOptions(t, promoter, user)}
        />
      ),
      hidden: selectedType === "P"
    },
    {
      size: 4,
      field: (
        <TextField
          id="cep"
          label={t("entities.CreateEntity.form.cep")}
          maxLength={11}
          disabled={user?.entity?.type !== "P"}
        />
      ),
      hidden: selectedType !== "S" && selectedType !== "W",
    },
    {
      size: 4,
      field: (
        <TextField
          id="gln"
          label={t("entities.CreateEntity.form.gln")}
          maxLength={13}
          disabled={user?.entity?.type !== "P"}
        />
      ),
      hidden: selectedType !== "S" && selectedType !== "W" && selectedType !== "P",
    },
    {
      size: 4,
      field: (
        <SelectField
          id="trafficType"
          label={t("entities.CreateEntity.form.trafficType")}
          options={getTrafficTypeOptions(t)}
          required={selectedType === "S"}
          disabled={user?.entity?.type !== "P"}
        />
      ),
      hidden: selectedType !== "S",
    },
    {
      size: 4,
      field: <div></div>,
      hidden: selectedType !== "W",
    },
    {
      size: 4,
      field: (
        <SelectField
          id="operationType"
          label={t("entities.CreateEntity.form.operationType")}
          options={getOperationTypeOptions(t)}
          required={
            selectedType === "S" &&
            promoter?.getConfig("order", "hasLogisticOperator") &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
          }
          disabled={user?.entity?.type !== "P"}
        />
      ),
      hidden:
        !promoter?.getConfig("order", "hasLogisticOperator") ||
        !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType),
    },
    {
      size: 4,
      field: <div></div>,
      hidden: selectedType === "LO" || selectedType === "ST",
    },
    {
      size: 4,
      field: <div></div>,
      hidden: selectedType === "LO" || selectedType === "ST",
    },
    {
      size: 4,
      field: (
        <NumberWithSelectField
          id="fee"
          label={t("entities.CreateEntity.form.fee")}
          textKey="quantity"
          selectKey="valueType"
          options={getFeeValueTypeOptions()}
          disabled={user?.entity?.type !== "P"}
          decimalScale={0}
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType) || selectedType === "P",
    },
    {
      size: 4,
      field: (
        <SelectField
          id="fee.type"
          label={t("entities.CreateEntity.form.feeType")}
          options={getFeeTypeOptions(t)}
          disabled={user?.entity?.type !== "P"}
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType) || selectedType === "P",
    },
    {
      size: 4,
      field: (
        <SelectField
          id="fee.frequencyType"
          label={t("entities.CreateEntity.form.frequencyType")}
          options={getFrequencyTypeOptions(t)}
          disabled={user?.entity?.type !== "P"}
        />
      ),
      hidden: !promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType) || selectedType === "P",
    },
    {
      size: 4,
      field: (
        <SelectField
          id="cmpAccumulation"
          label={t("entities.CreateEntity.form.cmpAccumulation")}
          options={getEntityPermissionOptions(t)}
          disabled={user?.entity?.type !== "P"}
          required={
            promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
          }
        />
      ),
      hidden: !(
        promoter?.getConfig("price", "cmpAccumulation") === "EC" &&
        (promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType) || selectedType === "P")
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id="cpAccumulation"
          label={t("entities.CreateEntity.form.cpAccumulation")}
          options={getEntityPermissionOptions(t)}
          disabled={user?.entity?.type !== "P"}
          required={
            promoter?.getConfig("price", "cpAccumulation") === "EC" &&
            promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
          }
        />
      ),
      hidden: !(
        promoter?.getConfig("price", "cpAccumulation") === "EC" &&
        promoter?.getConfig("product", "allowedEntityTypes")?.includes(selectedType)
      ),
    },
  ];
}

export function userFormFields(item, t, promoter, selectedType, values) {
  return [
    {
      size: 4,
      field: (
        <TextField
          id={`${item}.firstName`}
          type="text"
          label={t("entities.CreateEntity.form.userFirstName")}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id={`${item}.lastName`} type="text" label={t("entities.CreateEntity.form.userLastName")} required />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.roleType`}
          label={t("entities.CreateEntity.form.userRole")}
          options={getUserRoleOptions(t, promoter)}
          required
        />
      ),
    },
    {
      size: 4,
      field: <TextField id={`${item}.email`} type="email" label={t("entities.CreateEntity.form.userEmail")} required />,
    },
    {
      size: 4,
      field: <TextField id={`${item}.phoneNumber`} type="text" label={t("entities.CreateEntity.form.userPhone")} />,
    },
    {
      size: 3,
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.businessUnitIndexs`}
          label={t("entities.CreateEntity.form.businessUnit")}
          options={getBusinessUnitsOptions(values)}
        />
      ),
      hidden: selectedType !== "S",
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.sector`}
          label={t("entities.CreateEntity.form.sector")}
          options={getSectorOptions(t, promoter?.refData)}
        />
      ),
    },
  ];
}

export function addressFormFields(item, t, promoter, selectedType, operators, values) {
  const [, , index] = item.match(/^(\w+)\[(\d+)\]$/);
  const states = getStateOptions(promoter, values?.addressList[index]?.country);
  return [
    {
      size: 4,
      field: (
        <TextField
          id={`${item}.description`}
          type="text"
          label={t("entities.CreateEntity.form.addressName")}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.contactIdIndex`}
          label={t("entities.CreateEntity.form.addressContact")}
          options={getAddressContactOptions(values)}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.businessUnitIndex`}
          multiple
          label={t("entities.CreateEntity.form.businessUnit")}
          options={getBusinessUnitsOptions(values)}
        />
      ),
      hidden: selectedType !== "S",
    },
    {
      size: 4,
      hidden: selectedType === "S",
    },
    {
      size: 8,
      field: (
        <TextField id={`${item}.address`} type="text" label={t("entities.CreateEntity.form.addressAddress")} required />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id={`${item}.county`} type="text" label={t("entities.CreateEntity.form.addressCounty")} required />
      ),
    },
    {
      size: 4,
      field: (
        <TextField id={`${item}.locale`} type="text" label={t("entities.CreateEntity.form.addressLocale")} required />
      ),
    },
    {
      size: 4,
      field: (
        <TextField
          id={`${item}.postalCode`}
          type="text"
          label={t("entities.CreateEntity.form.addressPostalCode")}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        <CountryAutoCompleteField
          id={`${item}.country`}
          label={t("entities.CreateEntity.form.addressCountry")}
          required
        />
      ),
    },
    {
      size: 4,
      field: (
        states && states.length > 0
          ? <SelectField
            id={`${item}.state`}
            label={t("entities.CreateEntity.form.addressState")}
            options={states}
            required
          />
          : <TextField id={`${item}.state`} type="text" label={t("entities.CreateEntity.form.addressState")} required />
      ),
    },
    {
      size: 4,
      field: (
        <SelectField
          id={`${item}.operatorAddress`}
          label={t("entities.CreateEntity.form.addressOperator")}
          options={getAddressOperatorOptions(operators)}
          required={promoter?.getConfig("order", "hasLogisticOperator")}
        />
      ),
      hidden: selectedType === "LO" || !promoter?.getConfig("order", "hasLogisticOperator"),
    },
    {
      size: 3,
      field: <div></div>,
    },
  ];
}
