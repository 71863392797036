import React, { useEffect, useMemo, useState } from "react";
import { withStyles } from "tss-react/mui";
import { Badge, Button, Drawer, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

import { styles } from "./FilterForm.styles";
import Form from "../../Form";
import FormFields from "../../FormFields";
import { compareObjects, jsonTryParse } from "../../../utils/utils";
import { usePromoter } from "../../../hooks";


function FilterForm({ classes, btnLabel, filter, schema, fields, onFilterChange, defaultFilter, slim, style, loading, storageKey, onStorageLoad }) {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const promoter = usePromoter();
  const sysStorageKey = useMemo(() => {
    if (storageKey && promoter?.details?.code) {
      return `${promoter.details.code}_FILTER_${storageKey}`
    }
  }, [promoter, storageKey]);
  const [isDefaultFilter, setIsDefaultFilter] = useState(true);

  useEffect(() => {
    if (sysStorageKey) {
      let nFilter = jsonTryParse(localStorage.getItem(sysStorageKey));
      if (nFilter) {
        onStorageLoad && onStorageLoad({ ...filter, ...nFilter });
        setIsDefaultFilter(nFilter.isDefaultFilter || checkDefaultFilter(nFilter, defaultFilter));
      }
      else {
        onStorageLoad && onStorageLoad(defaultFilter);
        setIsDefaultFilter(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  const onResetHandler = () => {
    if (sysStorageKey) {
      setIsDefaultFilter(true);
      localStorage.setItem(sysStorageKey, JSON.stringify({ ...defaultFilter, isDefaultFilter: true }));
    }
    onFilterChange && onFilterChange(defaultFilter);
  };

  const onSubmitHandler = (values) => {
    let nFilter = { ...filter, ...values };
    if (sysStorageKey) {
      let idf = checkDefaultFilter(nFilter, defaultFilter);
      setIsDefaultFilter(idf);
      localStorage.setItem(sysStorageKey, JSON.stringify({ ...nFilter, offset: 0, isDefaultFilter: idf })); // store allways with offset 0, so when loading it is always first page
    }
    onFilterChange && onFilterChange(nFilter);
    setDrawerOpen(false);
  };

  return (
    <div style={style}>
      {
        slim ?
          <IconButton id={"filter-button-" + storageKey} className={"filter-button-" + storageKey} color="default" size="small" onClick={() => setDrawerOpen(true)} disabled={loading}>
            <Badge color="primary" variant="dot" invisible={isDefaultFilter}>
              <FilterAltOutlinedIcon fontSize="small" />
            </Badge>
          </IconButton>
          :
          <Button variant="contained" color="secondary" size="large" onClick={() => setDrawerOpen(true)}>
            {btnLabel}
          </Button>
      }
      <Drawer anchor="right" open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div className={classes.root}>
          <Form
            onSubmit={onSubmitHandler}
            schema={schema(t)}
            initialValues={filter}
            render={({ values }) => {
              return (
                <div id={"filterForm-" + storageKey}>
                  <FormFields fields={fields(t, values)} />
                  <br />
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button variant="contained" color="secondary" fullWidth size="large" onClick={onResetHandler} disabled={loading}>
                        {t("common.FilterForm.button.reset")}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button variant="contained" color="primary" type="submit" fullWidth size="large" disabled={loading}>
                        {t("common.FilterForm.button.submit")}
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              );
            }}
          />
        </div>
      </Drawer>
    </div>
  );
}

export default withStyles(FilterForm, styles)

const ignoreFilter = {
  limit: undefined,
  offset: undefined,
  sortField: undefined,
  sortType: undefined,
  search: undefined,
  isDefaultFilter: undefined
}

const checkDefaultFilter = (currentFilter, defaultFilter) => {
  return compareObjects({ ...currentFilter, ...ignoreFilter }, { ...defaultFilter, ...ignoreFilter });
}