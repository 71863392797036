import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";

import {styles} from "./PriceHistoryDataGrid.styles";
import {listColumns} from "./PriceHistoryDataGrid.columns";
import {CustomDataGrid} from "../../../../common/displays/CustomDataGrid";
import {usePromoter} from "../../../../common/hooks";
import {useSelector} from "react-redux";
import {selectEntityGroups, selectLocations} from "../../productsPricesSlice";

function PriceHistoryDataGrid({ classes, data, filter, totalRows, onRowSelected, onFilterChange }) {
  const { t } = useTranslation();
  const promoter = usePromoter();
  const entityGroups = useSelector(selectEntityGroups);
  const locations = useSelector(selectLocations);

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
    onFilterChange({
      filter: nFilter,
      reset: true,
    });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: false,
      });
    }
  };
  return (
      <div className={classes.root}>
        <CustomDataGrid
            columns={listColumns(t, classes, promoter,entityGroups, locations)}
            rows={data || []}
            onFilter={onFilterHandler}
            onLoadMore={onLoadMoreHandler}
            onRowSelected={onRowSelected}
            total={totalRows}
        />
      </div>
  );
}


export default withStyles(PriceHistoryDataGrid, styles);
