
import React from "react";
import { useParams } from "react-router-dom";
import { withStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";

import { styles } from "./ResourceDataGrid.styles";
import { listColumns } from "./ResourceDataGrid.columns";
import ResourceDataGridActions from "./ResourceDataGrid.actions";
import { filterFields, filterSchema } from "./ResourceDataGrid.filter";

import { CustomDataGrid } from "../../../../common/displays/CustomDataGrid";
import FilterForm from "../../../../common/forms/custom/FilterForm/FilterForm";
import { usePromoter, useUser } from "../../../../common/hooks";
import { resourceListDefaultFilter } from "../../_common/resourcesFilters";

function ResourceDataGrid({
  classes,
  data,
  totalRows,
  filter,
  onFilterChange,
  loading,
  places,
  onEdit,
  onCopy,
  onDisable,
  onEnable,
  onRemove,
}) {
  const { t } = useTranslation();
  const user = useUser();
  const promoter = usePromoter();
  const columns = listColumns(t, user);
  const { processId } = useParams();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={columns}
        rows={(data || []).map((item) => ({
          ...item,
          // className: !item.active ? classes.inactive : undefined,
          arrow: showRowArrow(item, processId)
        }))}
        onRowSelected={(row) => console.log(row)}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        total={totalRows}
        rowKeyField="tempId"
        actionsComponent={<ResourceDataGridActions onEdit={onEdit} onCopy={onCopy} onDisable={onDisable} onEnable={onEnable} onRemove={onRemove} />}
        topActions={
          <FilterForm
            style={{ marginBottom: 8 }}
            loading={loading}
            storageKey="ResourceDataGrid"
            onStorageLoad={onFilterHandler}
            slim
            fields={filterFields({ promoter, places, disabled: loading })}
            schema={filterSchema()}
            onFilterChange={onFilterHandler}
            filter={filter}
            defaultFilter={resourceListDefaultFilter}
          />
        }
      />
    </div>
  );
}

export default withStyles(ResourceDataGrid, styles);

const showRowArrow = (item, processId) => {
  return processId && item && (item.processes || []).some(item => item.processId === processId);
}