import React from "react";
import {withStyles} from "tss-react/mui";
import {useTranslation} from "react-i18next";

import {styles} from "./ProductHistoryDataGrid.styles";
import {listColumns} from "./ProductHistoryDataGrid.columns";
import {CustomDataGrid} from "../../../../../common/displays/CustomDataGrid";
import StepsDataGrid from "./StepsDataGrid/StepsDataGrid";
import ProductHistoryDataGridActions from "./ProductHistoryDataGrid.actions";



function ProductHistoryDataGrid({ classes, data, filter, totalRows, onRowSelected, onFilterChange, productId }) {
  const { t } = useTranslation();

  const onFilterHandler = (nFilter) => {
    nFilter = {
      ...filter,
      ...nFilter,
      offset: 0,
    };
    onFilterChange &&
      onFilterChange({
        filter: nFilter,
        reset: true,
      });
  };

  const onLoadMoreHandler = () => {
    if (data.length < totalRows && data.length > 0) {
      let nFilter = {
        ...filter,
        offset: data.length,
      };

      onFilterChange &&
        onFilterChange({
          filter: nFilter,
          reset: false,
        });
    }
  };

  return (
    <div className={classes.root}>
      <CustomDataGrid
        columns={listColumns(t, classes, filter.tax)}
        rows={data || []}
        onFilter={onFilterHandler}
        onLoadMore={onLoadMoreHandler}
        onRowSelected={onRowSelected}
        total={totalRows}
        innerRow={(row) => {
          return row.history
              ? <StepsDataGrid
                  data={row.history}
                  productId={productId}
              />
              : null
        }}
        actionsComponent={ <ProductHistoryDataGridActions productId={productId} /> }

      />
    </div>
  );
}

export default withStyles(ProductHistoryDataGrid, styles);