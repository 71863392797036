import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {withStyles} from "tss-react/mui";
import {Grid, IconButton} from "@mui/material";

import {styles} from "./ProductHistory.styles";
import {productListHistory, selectProductHistoryListFilter} from "../../productsSlice";
import ProductHistoryModal from "./ProductHistoryModal/ProductHistoryModal";
import HistoryIcon from "@mui/icons-material/History";

function ProductHistory({ classes, productId, product }) {
  const dispatch = useDispatch();
  const filter = useSelector(selectProductHistoryListFilter);
  const [modalOpen, setModalOpen] = useState(false);

  const onHistoryClickHandler = () => {
    dispatch(productListHistory({...filter,businessId:productId}, true));
    setModalOpen(true);
  }

  return (
    <div>
      <Grid container className={classes.root} alignItems="center">
        <Grid item>
          {
                <IconButton onClick={onHistoryClickHandler}>
                    <HistoryIcon color="action" style={{ display: "block", cursor: "pointer" }} />
                </IconButton>
          }
        </Grid>
      </Grid>
      <ProductHistoryModal open={modalOpen} setOpen={setModalOpen} product={product} />
    </div>
  );
}

export default withStyles(ProductHistory, styles);