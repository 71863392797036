export const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: "100%",
  },
  drawer: {
    width: "25vw",
    padding: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      width: "40vw"
    },
    [theme.breakpoints.down('md')]: {
      width: "60vw"
    },
    [theme.breakpoints.down('sm')]: {
      width: "80vw"
    }
  },
});