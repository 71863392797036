import React from "react";
import {Button, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "../../../../../common/hooks";
import VisibilityIcon from "@mui/icons-material/Visibility";


export default function ProductHistoryDataGridActions({ row , productId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();


  return (

    <Grid container spacing={1} justifyContent="flex-end">
      {row?.processType !== "FlowCreateProduct" && (
          <Grid item>
            <Button
              onClick={() => navigate(`/products/validation/${productId}/${row?.processId}/view`)}
              color="primary"
              variant="outlined"
            >
              <Grid container spacing={1} alignItems="center" wrap="nowrap">
                <Grid item style={{ display: "flex" }}>
                  <VisibilityIcon />
                </Grid>
                <Grid item>{t("products.ProductValidationList.actions.view")}</Grid>
              </Grid>
            </Button>
          </Grid>
      )}
    </Grid>
  );
}
