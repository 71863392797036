import React, { useMemo } from "react";
import { withStyles } from "tss-react/mui";
// import { useTranslation } from "react-i18next";
import { Grid, Stack, Typography } from "@mui/material";

import { styles } from "./BatchCard.styles";
import { dateFormat } from "../../../../../common/utils";
import HatcheryProgress from "../../../_common/HatcheryProgress/HatcheryProgress";

function BatchCard({ classes, batch, resources }) {
  // const { t } = useTranslation();
  const { batchNumber, startDate, processedData } = batch;

  const progressValues = useMemo(() => {
    let values = [0, 0, 0, 0, 0, 0, 0];
    if (!resources || !processedData) {
      return values;
    }
    const res = processedData?.resources || [];
    const validResourceIds = Object.keys(res).filter(id => parseFloat(res[id]?.quantity) > 0);
    const filteredResources = validResourceIds.map(id => ({
      ...resources.find(item => item.id === id) || {},
      quantity: parseFloat(res[id]?.quantity)
    }))
    filteredResources.forEach(item => {
      if (parseFloat(item.attributes?.mesh) < 150) {
        values[0] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 300) {
        values[1] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 500) {
        values[2] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 1000) {
        values[3] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 1500) {
        values[4] += item.quantity;
      }
      else if (parseFloat(item.attributes?.mesh) < 2400) {
        values[5] += item.quantity;
      }
      else {
        values[6] += item.quantity;
      }
    })
    return values;
    // eslint-disable-next-line
  }, [processedData, resources]);

  return (
    <div className={classes.root}>
      <Stack spacing={0.5}>
        <HatcheryProgress height={30} values={progressValues} />
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.batchNumber} color="textSecondary">{batchNumber}</Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.date} color="textSecondary">{dateFormat(startDate)}</Typography>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
}

export default withStyles(BatchCard, styles);