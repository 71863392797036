import ActionSnip from "../../../../../common/displays/ActionSnip/ActionSnip";

export function listColumns(t, classes, taxType) {
  return [
    {
      field: "createdTime",
      headerName: t("products.ProductDetails.history.columnsCreatedTime"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return <ActionSnip username={v.createdBy} date={v.createdTime}/>;
      },
    }, {
      field: "processType",
      headerName: t("products.ProductDetails.history.columnProcessType"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return t('common.Enums.productProcessType.' + v.processType);
      },
    }, {
      field: "status",
      headerName: t("products.ProductDetails.history.columnsStatus"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
    },
    {
      field: "active",
      headerName: t("products.ProductDetails.history.columnActive"),
      align: "left",
      headerAlign: "left",
      disableSort: true,
      customValue: (v) => {
        return t('common.Enums.productActive.' + v.active);
      },
    }
  ];
}
