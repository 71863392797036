const products = {
  flowsMessages: {
    success: {
      PRODUCT_CREATE: "Product created successfully!",
      PRODUCT_CREATE_PENDING: "Product created successfully, waiting to be approved!",
      PRODUCT_UPDATE: "Product updated successfully!",
      PRODUCT_UPDATE_PENDING: "Product updated successfully, waiting for changes to be validated",
      PRODUCT_UPDATE_PRICE: "Product price updated successfully!",
      PRODUCT_UPDATE_PRICE_PENDING: "Product price is waiting for aproval!",
      PRODUCT_CREATE_PRICE: "Product price created successfully!",
      PRODUCT_CREATE_PRICE_PENDING: "Product price is waiting for aproval!",
      PRODUCT_DISABLE_PRICE: "Product price disabled successfully!",
      PRODUCT_DEFINE_HIERARCHY: "Product hierarchy defined successfully!",
      PRODUCT_CREATE_CAMPAIGN_GROUP: "Campaign group created successfully!",
      PRODUCT_UPDATE_CAMPAIGN_GROUP: "Campaign group updated successfully!",
      PRODUCT_DISABLE_CAMPAIGN_GROUP: "Campaign group disabled successfully!",
      PRODUCT_CREATE_CAMPAIGN: "Campaign created successfully!",
      PRODUCT_UPDATE_CAMPAIGN: "Campaign updated successfully!",
      PRODUCT_DISABLE_CAMPAIGN: "Campaign disabled successfully!",
      PRODUCT_CREATE_CATALOG: "Catalog created successfully!",
      PRODUCT_UPDATE_CATALOG: "Catalog updated successfully!",
      PRODUCT_PENDING_PRODUCT_ACTION_VALIDATING_acceptProduct: "Product accepted successfully!",
      PRODUCT_PENDING_PRODUCT_ACTION_ACCEPTED_acceptProduct: "Product accepted successfully!",
      PRODUCT_PENDING_PRODUCT_ACTION_ACCEPTED_validateProduct: "Product information accepted successfully!",
      PRODUCT_PENDING_PRODUCT_ACTION_REFUSED_acceptProduct: "Product rejected!",
      PRODUCT_PENDING_PRODUCT_ACTION_NOT_OK_validateProduct: "Product changes partial accepted!",
      PRODUCT_PENDING_PRODUCT_ACTION_NOT_VALIDATED_validateProduct: "Product validation rejected!",
      PRODUCT_PENDING_PRODUCT_ACTION_REFUSED_validateProduct: "Product rejected!",
      PRODUCT_PENDING_PRICE_ACTION_ACCEPTED: "Product price accepted successfully!",
      PRODUCT_PENDING_PRICE_ACTION_PROPOSAL: "Product price proposed successfully!",
      PRODUCT_PENDING_PRICE_ACTION_PENDING: "Product price proposed successfully!",
      PRODUCT_PENDING_PRICE_ACTION_REFUSED: "Product price refused successfully!",
      PRODUCT_DRAFT: "Product draft saved successfully!",
      PRODUCT_RECOVER: "Product recovered successfully!",
      PRODUCT_IMPORT_PRODUCT_IMAGES: "Images updated successfully!",
      PRODUCT_REQUEST_PURCHASE: "Product purchase created successfully!",
      PRODUCT_PENDING_PURCHASE_ACTION_PROPOSAL: "Product purchase changed successfully!",
      PRODUCT_PENDING_PURCHASE_ACTION_PENDING: "Product purchase changed successfully!",
      PRODUCT_PENDING_PURCHASE_ACTION_REFUSED: "Product purchase refused successfully!",
      PRODUCT_PENDING_PURCHASE_ACTION_ACCEPTED: "Product purchase accepted successfully!",
      PRODUCT_PENDING_PURCHASE_ACTION_EXECUTED: "Product purchase executed successfully!",
      PRODUCT_CHANGE_CONTACT_POINT: "Contact point update successfully!",
      PRODUCT_CREATE_TAX: "Product tax created successfully!"
    },
    error: {
      GENERIC: "Something went wrong",
      PROCESS_TASK_ALREADY_COMPLETED: "Task already completed!",
      WORKFLOW_VALIDATION_ERROR: "This product has required fields, you should update it before taking actions",
      GET_TASK_PRODUCT_ERROR: "Task no longer available!",
      PRODUCT_ALREADY_EXISTS: "Product already exists!",
      GET_PRODUCT_ERROR: "Product not found",
      INVALID_PRODUCT_ID: "Invalid product ID",
      WORKFLOW_PERMISSION_ERROR: "You have no permissions",
      TAX_NOT_ALLOWED_ERROR: "Tax not allowed",
      PRODUCT_NOT_ACCEPTED_ERROR: "This product is not accepted, purchase is not possible",
    },
  },
  CatalogList: {
    header: {
      title: "Product Catalogs",
      addBtn: "Add New",
      filterBtn: "Filter",
      rowsInfo: "catalogs"
    },
    filter: {
      activeLabel: "Active",
      optionAll: "All",
      optionActive: "Active",
      optionInactive: "Inactive",
      ownerLabel: "Owner",
    },
    columns: {
      name: "Name",
      ownerName: "Owner",
      numberBuyer: "Buyers",
      numberProduct: "Products",
      active: "Active",
    },
  },
  CatalogCreate: {
    header: {
      title: "Create New Catalog",
      editTitle: "Edit Catalog:",
      addBtn: "Save",
    },
    form: {
      name: "Name",
      ownerId: "Owner",
      products: "Products",
      buyers: "Buyers",
      requiredName: "Name is required",
      requiredOwner: "Owner is required",
      requiredAtLeastOneBuyer: "You need at least one buyer",
      requiredAtLeastOneProduct: "You need at least one product",
    },
  },
  CatalogDetails: {
    header: {
      editBtn: "Edit",
    },
    details: {
      products: "Products",
      buyers: "Buyers",
    },
  },
  ImportProduct: {
    header: {
      title: "Import",
      results: "Results",
      template: "Template",
    },
    template: {
      product: "Download Product Template",
      price: "Download Price Template",
    },
    enum: {
      product: "Product",
      price: "Price",
    },
    form: {
      saveBtn: "Save",
      importBtn: "Import",
      type: "Import Type",
      upload: "Upload Products File",
      uploadInput: "Upload",
      ownerId: "Entity",
      gln: "GLN",
      addressId: "Address",
      requiredFile: "File upload is required",
      requiredOwnerId: "Owner is required",
      requiredGln: "GLN is required",
      requiredAddressId: "Address is required",
      requiredAtLeastOneFile: "Upload at least one file",
    },
    column: {
      lineId: "Line Nr",
      success: "Status",
      statusTrue: "Success",
      statusFalse: "Fail",
      message: "Error",
    },
  },
  ProductList: {
    header: {
      title: "Products List",
      rowsInfo: "products",
      addBtn: "Add Product",
      importBtn: "Import",
      exportDocuments: "Export Documents",
      importImagesBtn: "Import Images",
      filterBtn: "Filter",
      filterAll: "All Families",
      productsLink: "Products",
      validationLink: "Validation"
    },
    columns: {
      description: "Description",
      gln: "GLN",
      ownerName: "Owner",
      brand: "Brand",
      available: "Available",
      retailPrice: "Retail Price",
      salePrice: "Price",
      priceByNetContent: "Unit Price",
      status: "Status",
      promoterInternalCode: "Internal Code"
    },
    details: {
      brand: "Brand",
      gtin: "GTIN",
      gln: "GLN",
      price: "Price",
      btnDetails: "Details",
      btnEdit: "Edit",
    },
    filter: {
      productFamily: "Product Family",
      showDrafts: "Drafts",
      available: "Available",
      owner: "Owner",
      contactPoint: "Contact Point",
      allContactPointsOption: "Clear",
      allAvailableOption: "Clear",
    },
  },
  ProductCreate: {
    header: {
      title: "Add New Product",
      editTitle: "Update Product",
      btnSave: "Save",
      btnSaveDraft: "Save as draft",
    },
    form: {
      ownerId: "Owner",
      gln: "GLN",
      gtin: "GTIN",
      addressId: "Location",
      description: "Description",
      brand: "Brand",
      gpcCategoryCode: "Category",
      familyCode: "Family",
      available: "Available",
      active: "Active",
      unitsTitle: "Units",
      isBaseUnit: "Base Unit",
      isBaseUnitNote: "Base Unit",
      isDispatchUnit: "Dispatch Unit",
      isDispatchUnitNote: "Dispatch Unit",
      isConsumerUnit: "Consumer Unit",
      isConsumerUnitNote: "Consumer Unit",
      unitDescriptor: "Unit Descriptor",
      transportUnit: "Transport Unit",
      quantityOfLayers: "Total layers",
      quantityOfItemsByLayer: "Items by layer",
      quantityOfItems: "Total of Items",
      measuresTitle: "Weigh and measures",
      hasVariableDimensions: "Variable Dimensions",
      height: "Height",
      width: "Width",
      depth: "Depth",
      netWeight: "Net Weight",
      grossWeight: "Gross Weight",
      netContent: "Net Content",
      to: "to",
      orderTitle: "Orders",
      minQuantity: "Min quantity",
      maxQuantity: "Max quantity",
      quantityMultiple: "Quantity multiple",
      managementTitle: "Management",
      hasBatchNumber: "Has BatchNumber",
      hasExpirationDate: "Has Expiration Date",
      hasVariableWeight: "Has Variable Weight",
      hasSerialNumber: "Has Serial number",
      storageTitle: "Storage",
      transportTemperature: "Temperature (Transport)",
      storageTemperature: "Temperature (Storage)",
      lifecycle: "Life Cycle (days)",
      requiredProductEntityId: "Owner is required",
      requiredGln: "GLN is required",
      specificDigits: "should have exactly {{digits}} digits",
      invalidGtin: "Invalid GTIN",
      requiredAvailable: "Available is required",
      requiredMinQuantity: "Min quantity is required",
      requiredMaxQuantity: "Max quantity is required",
      requiredQuantityMultiple: "Quantity multiple is required",
      requiredUnitTransport: "Transport Unit is required",
      requiredActive: "Active is required",
      requiredDescription: "Description is required",
      requiredUnitDescriptor: "Unit descriptor is required",
      documentsTitle: "Documents",
      requiredDSC: "Document Description is required",
      requiredNME: "Document Name is required",
      requiredEXD: "Document Expiration Date is required",
      requiredRCD: "Document Reception Date is required",
      requiredADD: "Document Added by is required",
      requiredDVS: "Document Version is required",
      requiredAPV: "Document Approved by is required",
      supplierInternalCode: "Internal Code",
      promoterInternalCode: "Promoter Internal Code",
      promoterDescription: "Promoter description",
      generalTitle: "General",
      hierarchyTitle: "Hierarchy",
      contactPoint: "Contact Point",
      sustainabilityTitle: "Sustainability",
      packagingType: "Packing Type",
      packagingMaterialComponentType: "Packaging Material Component Type",
      packagingMaterialType: "Packaging Material Type",
      otherPackagingMaterials: "Other Packaging Materials",
      packagingColor: "Packaging Color",
      opacity: "Opacity",
      recyclingDestination: "Recycling Destination",
      packagingWeight: "Packaging Weight",
      quantity: "Quantity",
      plasticDensity: "Plastic Density",
      requiredPackagingType: "Packing Type is required",
      requiredHeight: "Product height is required",
      requiredWidth: "Product width is required",
      requiredDepth: "Product depth is required",
      requiredGrossWeight: "Product gross weight is required",
      perishable: "Perishable",
      requiredContactPoint: "Contact Point is required",
      taxesTitle: "Taxes",
      vat: "Vat",
      otherTaxes: "Other Taxes",
      otherTaxesNote: "Add other taxes here",
      addOtherTax: "⊕︀ Add other tax",
      requiredVat: "Vat is required",
      requiredAddressId: "Address is required",
      transactionUnit: "Transaction Unit",
      conversionFactor: "Conversion Factor",
      requiredSupplierInternalCode: "Internal Code is required",
      requiredNetContent: "Net content is required",
      productTemplate: "Product template",
      productTemplateNote: "To create the product without template, set this field to 0",
      productTemplateNew: "Without Template",
      requiredProductTemplate: "A valid Product Template is required",
      media: "Images",
      requiredMedia: "At least one image should be uploaded",
      requiredDocuments: "This documents types are required",
      requiredPackagingMaterialComponentType: "Component type is required",
      requiredPackagingWeight: "Weight is required",
      requiredQuantity: "Quantity is required",
      invalidCode: "Invalid code",
      customFormsTitle: "Additional Information",
      formMandatory: "You need to fill at least one form",
      countryOfOrigin: "Country of origin",
      requiredCountryOfOrigin: "Country of origin is required",
      productClass: "Product Class",
      requiredProductClass: "Product Class is required",
      requiredLifecycle: "Lifecycle is required",
      copyOfPrefix: "Copy of",
      otherTaxValueRequired: "Value is required",
      otherTaxValueMax: "The max value is 100%",
      requiredHierarchy: "The hierarchy requires all units to be contemplated",
      requiredChildQuantity: "Child quantity is required"
    },
    imagesField: {
      delete: "Delete",
      default: "Default",
      setDefault: "Set as default",
    },
    documentsField: {
      documentTypeRequired: "Please select the document type",
      fileSize: "This file size is too big"
    },
    createForm: {
      gs1CheckBtn: "GS1 Check",
      btnNext: "Next",
      gtinInternalCodeRequired: "The product requires a GTIN or an InternalCode, at least one of them should be provided.",
      gs1Message: "You can check if the product exists on GS1",
      gs1NotFound: "Product not found on GS1",
      gs1InvalidUnit: "Product Unit not supported",
      gs1Timeout: "Product not found on GS1"
    }
  },
  CampaignList: {
    header: {
      title: "Campaigns",
      filters: "Filters",
      addBtn: "Add New",
      rowsInfo: "campaigns",
    },
    columns: {
      name: "Name",
      startDate: "Starts",
      endDate: "Ends",
      totalCampaigns: "Total campaigns",
      status: "Status",
      editBtn: "Edit",
      deleteBtn: "Delete",
      viewBtn: "View",
    },
    filter: {
      title: "FILTERS",
      active: "Active",
      startDate: "Start Date",
      endDate: "End Date",
      campaignType: "Campaign Types",
      entityGroup: "Groups",
      clearBtn: "Clear filters",
    },
    deleteConfirm: {
      title: "Are you sure?",
      description: "This action is not reversible",
      ok: "OK",
      cancel: "Cancel",
    },
  },
  ProductDetails: {
    header: {
      btnEdit: "Edit",
      btnCopy: "Copy",
      btnAccept: "Accept",
      btnReject: "Reject",
      rejectCancel: "Cancel",
      rejectComment: "Comment",
      rejectTitle: "Reject product validation",
      btnRecover: "Recover",
      btnPurchase: "Purchase",
      statusAccepted: "Product accepted!",
      statusRejected: "Product rejected!"
    },
    details: {
      gpcCategoryCode: "Category",
      familyCode: "Family",
      available: "Available",
      address: "Location",
      owner: "Owner",
      ownerTaxNumber: "Owner Tax Number",
      gln: "GLN",
      unitsTitle: "Units",
      isBaseUnit: "Base Unit",
      isConsumerUnit: "Consumer Unit",
      isDispatchUnit: "Dispatch Unit",
      unitDescriptor: "Unit descriptor",
      transportUnit: "Transport Unit",
      quantityOfLayers: "Number of layers per pallete",
      quantityOfItemsByLayer: "Items by palletelayers",
      quantityOfItems: "Total of Items per pallete",
      measuresTitle: "Weight and measures",
      height: "Height",
      width: "Width",
      depth: "Depth",
      netWeight: "Net Weight",
      grossWeight: "Gross Weight",
      netContent: "Net Content",
      to: "to",
      ordersTitle: "Orders",
      minQuantity: "Min quantity",
      maxQuantity: "Max quantity",
      quantityMultiple: "Quantity multiple",
      managementTitle: "Management",
      hasBatchNumber: "Has Batchnumber",
      hasExpirationDate: "Has Expiration Date",
      hasVariableWeight: "Has Variable Weight",
      hasSerialNumber: "Has Serial number",
      yes: "Yes",
      no: "No",
      storageTitle: "Storage",
      transportTemperature: "Temperature (Transport)",
      storageTemperature: "Temperature (Storage)",
      lifecycle: "Life Cycle (days)",
      active: "Active",
      inactive: "Inactive",
      supplierInternalCode: "Internal Code",
      contactPoint: "Contact Point",
      promoterInternalCode: "Promoter Internal Code",
      gtin: "GTIN",
      productTemplate: "Product Template",
      packagingMaterialComponentType: "Packaging Material Component Type",
      packagingMaterialType: "Packaging Material Type",
      otherPackagingMaterials: "Other Packaging Materials",
      packagingColor: "Packaging Color",
      opacity: "Opacity",
      recyclingDestination: "Recycling Destination",
      packagingWeight: "Packaging Weight",
      quantity: "Quantity",
      plasticDensity: "Plastic Density",
      perishable: "Perishable",
      customFormsTitle: "Additional Information",
      countryOfOrigin: "Country of origin",
      productClass: "Product Class",
      ownerPromoterInternalCode: "Owner Promoter Internal Code",
    },
    hierarchyDetails: {
      title: "Hierarchy",
      bntEdit: "Edit",
      description: "Description",
      gtin: "GTIN",
      unitDescriptor: "Unit",
      childQuantity: "Child quantity",
      childUnitDescriptor: "Child Unit",
    },
    priceDetails: {
      title: "Price",
      bntEdit: "Edit",
      retailPrice: "Retail Price",
      salePrice: "Sale Price",
      tax: "Tax",
      requiredAtLeastOneTax: "Tax is required",
    },
    priceColumns: {
      groupName: "Group Price",
      retailPrice: "Retail",
      salePrice: "Sale",
      deleteBtn: "Delete",
    },
    deleteConfirm: {
      title: "Are you sure?",
      description: "This action is not reversible",
      ok: "Ok",
      cancel: "Cancel",
    },
    modalAccept: {
      title: "Accept Product",
      btnSubmit: "Accept"
    },
    modalPurchase: {
      title: "Purchase Product"
    },
    comments: {
      commentsTooltip: "Comments",
      noCommentsTooltip: "No comments",
      modalTitle: "Comments",
    },
    history: {
      modalTitle: "History",
      columnsStatus: "Status",
      columnsCreatedTime: "Create Time",
      columnProcessType: "Process Type",
      columnActive: "Ongoing",
    }
  },
  ProductMediaDetails: {
    header: {
      title: "Media",
    },
  },
  ProductCommentDetails: {
    header: {
      title: "Comments",
    },
  },
  ProductDocumentsDetails: {
    header: {
      title: "Documents",
    },
  },
  CampaignCreate: {
    header: {
      title: "New campaign",
      btnSave: "Save",
    },
    form: {
      name: "Name",
      startDate: "Start date",
      endDate: "End date",
      groupEntity: "Associar Grupos",
      groupEntityInfo:
        "Esta opção permite associar condições especificas para cada grupo de entidades. Caso contrário será aplicada todas as entidades.",
      requiredName: "The name is required",
    },
  },
  CampaignConfiguration: {
    form: {
      bundleBuy: "Buy",
      bundleOffers: "Offers",
      promotionQuantity: "Quantity above",
      promotionPrice: "Price",
      merchandiseBuy: "Buy",
      merchandiseOffer: "Offer",
      campaignType: "New campaign",
      products: "Products",
      deleteBtn: "Delete",
      updateBtn: "Save",
      requiredProduct: "You need to select a product",
      requiredQuantity: "Quantity must be more than zero",
      addConfigurationBtn: "Add"
    },
    deleteConfirm: {
      title: "Are you sure?",
      description: "This action is not reversible",
      ok: "Ok",
      cancel: "Cancel",
    },
  },
  ProductHierarchy: {
    header: {
      btnSave: "Save",
      addNode: "+ Add Node",
    },
    form: {
      gtin: "GTIN",
      description: "Description",
      childGtin: "Child GTIN",
      unitDescriptor: "Unit",
      childQuantity: "Child quantity",
      childUnitDescriptor: "Child Unit",
      addBtn: "Add",
      updateBtn: "Update",
      deleteBtn: "Delete",
      quantityOfLayers: "Number of layers per pallete",
      quantityOfItemsByLayer: "Items by palletelayers",
      quantityOfItems: "Total of Items per pallete",
      measuresTitle: "Measures and weights",
      height: "Heigh",
      width: "Width",
      depth: "Depth",
      netWeight: "Net weight",
      grossWeight: "Gross weight",
      netContent: "Net content",
      internalCode: "Internal Code",
      createErrorGtin: "You need to set the GTIN and description to create the root node",
      createErrorInternalCode: "You need to set the internal Code and description to create the root node",
      removeNode: "Remove node",
      gs1Btn: "GS1 Check",
    },
  },
  ProductImportImages: {
    form: {
      title: "Import Images",
      submitBtn: "Import",
      owner: "Owner",
      requiredOwner: "Owner is required",
      gln: "GLN",
      requiredGln: "GLN is required",
      specificDigits: "should have exactly {{digits}} digits ",
      file: "Zip file",
      fileNotes:
        "The zip file should contain only images and the file name should be the product GTIN. E.g. 12123123123123.jpg",
    },
  },
  PricesList: {
    columns: {
      description: "Product",
      price: "Price",
    },
  },
  ProductValidationList: {
    header: {
      title: "Products waiting validation",
      rowsInfo: "products"
    },
    filter: {
      status: "Status",
      processTypes: "Process Types",
      processStatus: "Process Status",
      allStatusOption: "Clear",
      status_true: "Active",
      status_false: "Inactive",
    },
    columns: {
      description: "Product",
      date: "Created on",
      status: "Status",
      owner: "Owner",
      external: "GS1",
      processType: "Type",
      valueUpdate: "Update",
      valueCreate: "New",
    },
    actions: {
      validate: "Validate",
      confirm: "Accept",
      update: "Update",
      recover: "Recover",
      view: "View",
    },
  },
  ProductValidationForm: {
    header: {
      btnConfirm: "Confirm",
      btnReject: "Reject",
      showAllFields: "Show All Fields",
      btnNotOk: "Confirm",
    },
    columns: {
      field: "Field",
      value: "Value",
      newValue: "New value",
    },
    section: {
      baseInfo: "Base Info",
      units: "Units",
      weightMeasure: "Weight and measures",
      orders: "Orders",
      management: "Management",
      storage: "Storage",
      media: "Media",
      documents: "Documents",
      hierarchy: "Hierarchy",
      sustainability: "Sustainability",
      taxes: "Taxes",
      forms: "Aditional Information"
    },
    field: {
      //base Info
      gtin: "GTIN",
      gln: "GLN",
      ownerId: "Owner",
      brand: "Brand",
      description: "Description",
      addressId: "Address",
      supplierInternalCode: "Internal code",
      available: "Available",
      active: "Active",
      gpcCategoryCode: "Category",
      familyCode: "Family",
      format: "Format",
      contactPoint: "Contact Point",
      countryOfOrigin: "Country of origin",
      scheduleAvailable: "Available (scheduled)",
      scheduleAvailableDate: "Available scheduled date",
      replacementProduct: "Replacement Product",
      productClass: "Product Class",
      //units
      isBaseUnit: "Is Base Unit",
      isDispatchUnit: "Is Dispatch Unit",
      isConsumerUnit: "Is Consumer Unit",
      unitDescriptor: "Unit Descriptor",
      transportUnit: "Transport Unit",
      quantityOfLayers: "Number of layers per pallete",
      quantityOfItemsByLayer: "Items by palletelayers",
      quantityOfItems: "Total of Items per pallete",
      transactionUnit: "Transaction Unit",
      //weightMeasures
      hasVariableDimensions: "Has Variable Dimensions",
      height: "Height",
      width: "Width",
      depth: "Depth",
      netWeight: "Net Weight",
      grossWeight: "Gross Weight",
      netContent: "Net Content",
      conversionFactor: "Conversion Factor",
      measure_category: "Category",
      measure_unit: "Unit",
      measure_value: "Value",
      measure_valueMax: "Max Value",
      //
      minQuantity: "Min Quantity",
      maxQuantity: "Max Quantity",
      quantityMultiple: "Quantity Multiple",
      hasBatchNumber: "Has Batch Number",
      hasExpirationDate: "Has Expiration Date",
      hasVariableWeight: "Has Variable Weight",
      hasSerialNumber: "Has Serial Number",
      //storage
      transportTemperature: "Transport Temperature",
      storageTemperature: "Storage Temperature",
      lifecycle: "Life cycle",
      perishable: "Perishable",
      // Taxes
      vat: "Vat",
      other: "Other"
    },
    form: {
      comment: "Comment",
      requiredCommnent: "Comment required",
    },
  },
  HierarchyTree: {
    tree: {
      emptyHierarchy: "No hierarchy defined",
      addNode: "Create root node",
    },
  },
  ProductCustomForms: {
    modal: {
      btnSubmit: "Ok",
      btnClear: "Clear"
    },
    modalCopy: {
      title: "Copy from other product",
      btnSubmit: "Copy"
    }
  },
  ProductAvaibleState: {
    label: {
      scheduleAvailable: "Change scheduled to {{scheduleAvailable}}"
    },
    modal: {
      title: "Change product available state",
      btnCancel: "Cancel",
      btnSubmit: "Change",
    },
    form: {
      requiredScheduleAvailable: "New state is required",
      scheduleAvailable: "New state",
      scheduleAvailableDate: "Schedule a date",
      replacementProduct: "Replacement Product",
      scheduleAvailableDateMin: "The date should be after today"
    }
  },
  ProductTaxList: {
    header: {
      title: "Product Taxes",
      btnSubmit: "Submit",
      changesToSubmit: "You have pending changes to submit; they will be effective only after submission.",
      changesToSubmitValidation: "You have pending validations to submit; they will be effective only after submission.",
      clearChanges: "Clear changes",
      taxesLink: "Taxes",
      validationLink: "Validation",
      rowsInfo: "products"
    },
    columns: {
      productId: "Product",
      ownerName: "Owner",
      vat: "Vat",
      startDate: "Start Date",
      removed: "Removed",
      startDateWarn: "This change will only be effective after {{date}}",
      removeDateWarn: "This tax will be removed on {{date}}",
    },
    actions: {
      cancel: "Cancel",
      history: "History",
      remove: "Remove"
    }
  },
  ProductVatFormModal: {
    modal: {
      title: "Change product Vat"
    },
    form: {
      btnSubmit: "Confirm",
      vatRequired: "Vat is required",
      startDateRequired: "Start date is required",
      vat: "Vat",
      startDate: "Effective date"
    }
  },
  ProductTaxValidateCommentModal: {
    modal: {
      title: "Validation comment"
    },
    form: {
      commentRequired: "Comment is required",
      comment: "Comment",
      btnAccept: "Accept",
      btnRefuse: "Refuse"
    }
  },
  ProductOtherTaxFormModal: {
    modal: {
      title: "Add new tax to product",
      titleUpdate: "Update product tax"
    },
    form: {
      btnSubmit: "Save",
      code: "Code",
      codeRequired: "Code is required",
      amountValueRequired: "Value is required",
      maxPercentage: "Max is 100%",
      amountTypeRequired: "Type is required",
      startDateRequired: "Start Date is required",
      amount: "Value",
      startDate: "Start Date",
      startDateMin: "Past dates not allowed",
      sameAmount: "The value should be different"
    }
  },
  ContactPointChangeModal: {
    modal: {
      title: "Change Contact Point",
    },
    form: {
      btnSubmit: "Submit",
      contactPoint: "Contact Point",
      contactPointRequired: "Contact Point is required"
    }
  },
  TaxHistoryModal: {
    modal: {
      title: "Tax History",
    },
    columns: {
      actionDate: "Date",
      actionUser: "User",
      tax: "Tax",
      startDate: "Start Date",
      value: "Value",
      removed: "Removed",
      removed_true: "Yes",
      removed_false: "No",
      status: "Status",
    },
  }
};

export default products;
