export const styles = (theme) => ({
  root: {},
  drawer: {
    width: "25vw",
    padding: theme.spacing(4),
    [theme.breakpoints.down('lg')]: {
      width: "40vw"
    },
    [theme.breakpoints.down('md')]: {
      width: "60vw"
    },
    [theme.breakpoints.down('sm')]: {
      width: "80vw"
    }
  },
  nomargin: {
    margin: 0,
  },
  selectedAddress: {
    backgroundColor: "#ffffc6",
  },
});
