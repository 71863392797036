import { Stack } from "@mui/material"
import BlockCard from "../BlockCard/BlockCard"
import SlimCheckBoxField from "../SlimCheckBoxField/SlimCheckBoxField"

export const productsFormFields = ({ t, values, entityType }) => {
  return [
    {
      size: 3,
      field: <BlockCard title="product">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.product.get" label="get" />
          {values?.permissions?.products?.actions?.product?.get && entityType !== "P" && <SlimCheckBoxField ident id="permissions.products.filters.product.get.onlyOwn" label="onlyOwn" />}
          <SlimCheckBoxField id="permissions.products.actions.product.list" label="list" />
          {values?.permissions?.products?.actions?.product?.list && entityType !== "P" && <SlimCheckBoxField ident id="permissions.products.filters.product.list.onlyOwn" label="onlyOwn" />}
          <SlimCheckBoxField id="permissions.products.actions.product.listComments" label="listComments" />
          <SlimCheckBoxField id="permissions.products.actions.product.create" label="create" />
          <SlimCheckBoxField id="permissions.products.actions.product.update" label="update" />
          {values?.permissions?.products?.actions?.product?.update && entityType !== "P" && <SlimCheckBoxField ident id="permissions.products.filters.product.update.onlySelf" label="onlySelf" />}
          <SlimCheckBoxField id="permissions.products.actions.product.import" label="import" />
          <SlimCheckBoxField id="permissions.products.actions.product.copy" label="copy" />
          <SlimCheckBoxField id="permissions.products.actions.product.draft" label="draft" />
          <SlimCheckBoxField id="permissions.products.actions.product.exportDocuments" label="exportDocuments" />
          <SlimCheckBoxField id="permissions.products.actions.product.history" label="history" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="pendingProduct">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.pendingProduct.get" label="get" />
          <SlimCheckBoxField id="permissions.products.actions.pendingProduct.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.pendingProduct.action" label="action" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="catalog">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.catalog.get" label="get" />
          <SlimCheckBoxField id="permissions.products.actions.catalog.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.catalog.create" label="create" />
          <SlimCheckBoxField id="permissions.products.actions.catalog.update" label="update" />
          <SlimCheckBoxField id="permissions.products.actions.catalog.enable" label="enable" />
          <SlimCheckBoxField id="permissions.products.actions.catalog.disable" label="disable" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="campaign">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.campaign.get" label="get" />
          {values?.permissions?.products?.actions?.campaign?.get && entityType !== "P" && <SlimCheckBoxField ident id="permissions.products.filters.campaign.get.onlyOwn" label="onlyOwn" />}
          <SlimCheckBoxField id="permissions.products.actions.campaign.list" label="list" />
          {values?.permissions?.products?.actions?.campaign?.list && entityType !== "P" && <SlimCheckBoxField ident id="permissions.products.filters.campaign.list.onlyOwn" label="onlyOwn" />}
          <SlimCheckBoxField id="permissions.products.actions.campaign.create" label="create" />
          <SlimCheckBoxField id="permissions.products.actions.campaign.update" label="update" />
          {/* <SlimCheckBoxField id="permissions.products.actions.campaign.enable" label="enable" /> */}
          <SlimCheckBoxField id="permissions.products.actions.campaign.disable" label="disable" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="price">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.price.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.price.create" label="create" />
          <SlimCheckBoxField id="permissions.products.actions.price.update" label="update" />
          <SlimCheckBoxField id="permissions.products.actions.price.remove" label="remove" />
          <SlimCheckBoxField id="permissions.products.actions.price.import" label="import" />
          <SlimCheckBoxField id="permissions.products.actions.price.history" label="history" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="extraPrice">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.extraPrice.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.extraPrice.update" label="update" />
          <SlimCheckBoxField id="permissions.products.actions.extraPrice.history" label="history" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="pendingPrice">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.pendingPrice.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.pendingPrice.action" label="action" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="purchase">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.purchase.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.purchase.request" label="request" />
          <SlimCheckBoxField id="permissions.products.actions.purchase.action" label="action" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="dprom">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.dprom.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.dprom.create" label="create" />
          <SlimCheckBoxField id="permissions.products.actions.dprom.remove" label="remove" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="pendingDprom">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.pendingDprom.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.pendingDprom.action" label="action" />
        </Stack>
      </BlockCard>,
    },
    {
      size: 3,
      field: <BlockCard title="taxes">
        <Stack>
          <SlimCheckBoxField id="permissions.products.actions.taxes.list" label="list" />
          <SlimCheckBoxField id="permissions.products.actions.taxes.create" label="create" />
          <SlimCheckBoxField id="permissions.products.actions.taxes.action" label="action" />
          <SlimCheckBoxField id="permissions.products.actions.taxes.history" label="history" />
        </Stack>
      </BlockCard>,
    },
  ]
}