import Currency from "../../../../../common/displays/Currency/Currency";
import React from "react";
import moment from "moment";
import {LinearProgress, Stack, Typography} from "@mui/material";
import {dateFormat} from "../../../../../common/utils";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ActionSnip from "../../../../../common/displays/ActionSnip/ActionSnip";

export function listColumns(t, classes, promoter) {
    return [
        {
            field: "updatedBy",
            headerName: t("productsPrices.ExtraPriceHistory.columns.updatedBy"),
            align: "left",
            headerAlign: "left",
            customValue: (v) => {
                return <ActionSnip username={v.updatedBy} date={v.updatedTime}/>;
            },
        },
        {
            field: "date",
            headerName: t("productsPrices.ExtraPriceHistory.columns.date"),
            align: "left",
            headerAlign: "left",
            disableSort: true,
            customValue: (v) => {
                let progressPercent = 0;
                if (v.endDate && moment(v.startDate).isBefore(moment())) {
                    progressPercent = moment().diff(moment(v.startDate), "days") / moment(v.endDate).diff(moment(v.startDate), "days") * 100;
                    if (progressPercent > 100) {
                        progressPercent = 100;
                    }
                }
                return (
                    <Stack>
                        <Stack direction="row" alignItems="center" justifyContent="center" style={{ minHeight: 20 }}>
                            <Typography style={{ margin: 0 }}>{dateFormat(v.startDate)}</Typography>
                            {v.endDate && <ArrowRightIcon />}
                            {v.endDate && <Typography style={{ margin: 0 }}>{dateFormat(v.endDate)}</Typography>}
                        </Stack>
                        <LinearProgress variant="determinate" color={moment(v.startDate).isSameOrAfter(moment()) ? "inherit" : "primary"} value={progressPercent} />
                    </Stack>
                )
            },
        },{
        field: "extraPrice",
        headerName: t("productsPrices.ExtraPriceHistory.columns.extraPrice"),
        align: "center",
        headerAlign: "left",
        disableSort: true,
        customValue: (v) => {
            return <Currency value={v?.value} />;
        }
    }];
}
