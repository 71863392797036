import React from "react";
import { Paper, Stack, Typography } from "@mui/material";
import { styles } from "./PieChart.styles";
import { withStyles } from "tss-react/mui";
import { pieArcLabelClasses, PieChart as XPieChart } from '@mui/x-charts/PieChart';
import {
  cheerfulFiestaPalette,
} from '@mui/x-charts/colorPalettes';

function PieChart({ classes, label, labels, series }) {

  const total = (series || []).reduce((total, item) => {
    return total + parseFloat(item || 0)
  }, 0);

  return (
    <Paper className={classes.root} elevation={0}>
      <Stack spacing={1}>
        <Typography variant="h6" color="textSecondary"> {label} </Typography>
        <XPieChart
          className={classes.chart}
          colors={cheerfulFiestaPalette}
          series={[
            {
              data: series.map((item, index) => ({ id: index, value: parseFloat(item || 0), label: labels?.[index] })).sort((a, b) => b.value - a.value),
              innerRadius: 90,
              outerRadius: 140,
              valueFormatter: (item) => `${(parseFloat(item.value) / total * 100).toFixed(1)}% (${parseFloat(item.value).toFixed(0)})`,
              arcLabel: (item) => `${(parseFloat(item.value) / total * 100).toFixed(1)}%`,
              arcLabelMinAngle: 15,
            },
          ]}
          margin={{ top: 300, left: 10, right: 10 }}
          height={600}
          slotProps={{
            legend: {
              direction: 'column',
              position: { vertical: 'top', horizontal: 'left' },
              padding: 0,
              labelStyle: {
                fontSize: 11,
                letterSpacing: "-0.03em"
              },
            },
          }}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: 'white',
              fontWeight: "bold"
            },
          }}
        />
      </Stack>
    </Paper>
  );
}

export default withStyles(PieChart, styles)
