import * as Yup from "yup";
import { MultiSelectField, SelectField } from "../../../../common/forms";

export const filterSchema = () => (t) => {
  return Yup.object().shape({
    type: Yup.array(),
  });
};

export const filterFields = ({ promoter, places, disabled }) => (t) => {
  return [
    {
      size: 12,
      field: (
        <SelectField
          id="active"
          label={t("resources.ResourceList.filter.status")}
          disabled={disabled}
          options={[
            { value: null, text: t("resources.ResourceList.filter.allStatusOption") },
            ...getStatusOptions(t)
          ]}
        />
      ),
    },
    {
      size: 12,
      field: (
        <MultiSelectField id="type" label={t("resources.ResourceList.filter.type")} options={getResourceTypeOptions(t, promoter)} disabled={disabled} />
      ),
    },
    {
      size: 12,
      field: (
        <MultiSelectField id="placeId" label={t("resources.ResourceList.filter.placeId")} options={getPlacesOptions(t, places)} disabled={disabled} />
      ),
    },
  ];
};

const getStatusOptions = (t) => {
  return [true, false].map((value) => {
    return {
      value,
      text: t("resources.ResourceList.filter.status_" + value),
    };
  });
};

const getResourceTypeOptions = (t, promoter) => {
  let resourceTypesAllowed = promoter?.getConfig("general", "resourceTypes") || [];
  return resourceTypesAllowed.map((item) => {
    return {
      value: item,
      text: t("common.Enums.resourceTypes." + item),
    };
  });
};

const getPlacesOptions = (t, places) => {
  return places
    .filter(item => item.type === "A")
    .map(({ id, title }) => {
      return {
        value: id, text: title
      }
    });
};